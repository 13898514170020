import React, { useState, useEffect, useRef, Fragment } from 'react';
import classNames from 'classnames';
import './scroll.scss';

interface IProps {
  id: string;
  className: string;
  height: number;
  children: any;
  onScroll: (scrollTop: number) => void;
  onMouseDown: (e: any) => void;
  onMouseUp: (e: any) => void;
  onCopy: (e: any) => void;
  onCut: (e: any) => void;
  onPaste: (e: any) => void;
}

const Scroll = (props: IProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const ctxRef = useRef<HTMLDivElement>(null);
  const { id, className, height, onMouseDown, onMouseUp, onCopy, onCut, onPaste } = props;
  const [scrollHeight, setScrollHeight] = useState(0); // 外容器高度
  const [ctxHeight, setCtxHeight] = useState(0); // 文档容器高度
  const [scrollTop, setScrollTop] = useState(0); // 滚动条离上边距离
  const [barHeight, setBarHeight] = useState(0); // 滑块高度
  const [barTop, setBarTop] = useState(0); // 滑块距上边距离
  const [barActive, setBarActive] = useState(false); // 滑块选中状态
  const [ratio, setRatio] = useState(0); // 滑块与元素之间速度比率

  const isEnd = ctxHeight - (scrollHeight + scrollTop) <= 10; // 是否滑动到最下面
  const needBar = barHeight < scrollHeight; // 每超出不需要bar

  useEffect(() => {
    const scrollHeight = scrollRef.current?.offsetHeight || 0;
    const ctxHeight = ctxRef.current?.offsetHeight || 0;
    setScrollHeight(scrollHeight);
    setCtxHeight(ctxHeight);

    if (ctxHeight > 0) {
      setBarHeight(scrollHeight ** 2 / ctxHeight); // 设置滚动滑块高度
      setRatio(1 + (ctxHeight - scrollHeight) / scrollHeight); // 设置滑块与元素间速率比率
    }
  }, [props.children]);

  const onScroll = (e: any) => {
    const { scrollTop } = e.target;
    setBarTop(scrollTop / ratio);
    setScrollTop(scrollTop);
    props.onScroll(scrollTop);
  };

  const onBarMouseDown = (e: any) => {
    document.addEventListener('selectstart', stop); // 解决活动滑块误触选中问题
    setBarActive(true);
    const { clientY: startY } = e;
    const surplusDistance = scrollHeight - barHeight;

    window.onmousemove = (evt) => {
      const { clientY: movingY } = evt;
      const nextTop = barTop + (movingY - startY);
      // 在空间范围内
      if (nextTop >= 0 && nextTop < surplusDistance) {
        setBarTop(nextTop);
        if (innerRef.current) {
          innerRef.current.scrollTop = nextTop * ratio;
        }
      }
    };

    window.onmouseup = () => {
      setBarActive(false);
      window.onmousemove = null;
      window.onmouseup = null;
      document.removeEventListener('selectstart', stop);
    };
  };

  // 禁止默认事件
  const stop = (e: any) => e.preventDefault();

  return (
    <div
      id="rt-scroll"
      className={classNames('rt-scroll', { end: isEnd })}
      ref={scrollRef}
      style={{
        ...(height && { height }),
      }}
    >
      <div id="rt-scroll-inner" className="rt-scroll-inner" onScroll={onScroll} ref={innerRef}>
        <div
          id={id}
          className={classNames('rt-scroll-ctx', className)}
          ref={ctxRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onCopy={onCopy}
          onCut={onCut}
          onPaste={onPaste}
        >
          {props.children}
        </div>
      </div>
      {needBar && (
        <Fragment>
          {/* ------ 滚动滑块 ------*/}
          <div
            className={classNames('rt-scroll-bar', { active: barActive })}
            style={{
              height: barHeight - 3,
              top: barTop,
            }}
            onMouseDown={onBarMouseDown}
          />
          {/* ------ 底部阴影 ------*/}
          {!isEnd && <div className="rt-shadow-bottom" />}
        </Fragment>
      )}
    </div>
  );
};

Scroll.defaultProps = {
  className: '',
  height: 0,
  onScroll: () => {},
};

export default Scroll;
