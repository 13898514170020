import i18n from 'utils/i18n';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { Spin, message } from 'antd';
import { Dialog } from 'tdesign-react';
import RTCComponent, { RemoteStream, LocalStream } from 'components/common/BaseRTC';

import utils from 'utils';
import {
  apaasStartStream,
  apaasNewStream,
  apaasCloseStream,
  apaasCheckQueuedStatus,
  ApaasData,
  apaasChangeConfig,
  apaasTextCommand,
  apaasPreviewAction,
} from 'apis/interfaces/apaas';
import { getSystemDict } from 'apis';
import { UserInfo } from 'redux/types';
import { expressionDefaultTextMap } from './const';

import './index.scss';
const { t } = i18n;
export interface ApaasPreviewConfig {
  SpaceParam?: {
    BackgroundUrl?: string;
    ImagePosition: number;
    ImagePositionVertical: number;
    ImageZoom: number;
  };
  ImageParam?: {
    MainParam?: string;
    CustomParam?: string;
  };
  SpeechParam?: {
    Speed?: number;
    Speaker?: number;
  };
}
interface ApaasPreviewProps {
  virtualmanKey?: string;
  imageTemplateId?: number;
  config?: ApaasPreviewConfig;
  anchorCode: string;
  expressionCode?: string;
  actionCode?: string;
}

let opts = {
  Header: { virtualmanKey: '' },
  Type: 'preview',
  Appkey: '',
  AccessToken: '',
};

const ApaasPreview = (props: ApaasPreviewProps) => {
  const { virtualmanKey, imageTemplateId, config, anchorCode, expressionCode, actionCode } = props;
  const { uin } = useSelector((state: { userInfo: UserInfo }) => state.userInfo);

  // status：流状态——初始化中、排队中、离线、配置中、播流中、空白
  const [status, setStatus] = useState<'init' | 'queued' | 'offline' | 'configuring' | 'playing' | 'none'>('init');
  const [sessionId, setSessionId] = useState('');
  const [roomId, setRoomId] = useState(0);
  const [stream, setStream] = useState<RemoteStream | LocalStream | null>(null);
  const [queuedCount, setQueuedCount] = useState(0);

  const RTC = useRef<RTCComponent | null>(null);
  const playerRef = useRef<HTMLDivElement>(null);

  let activeTimer: ReturnType<typeof setTimeout> | null = null; // 页面活跃计时器，
  let playTimer: ReturnType<typeof setTimeout> | null = null; // 播流计时器
  let queueTimer: ReturnType<typeof setInterval> | null = null; // 排队轮询计时器

  useLayoutEffect(() => {
    if (!anchorCode) return;
    if (virtualmanKey) {
      opts = {
        Header: { virtualmanKey },
        Type: 'preview',
        Appkey: '',
        AccessToken: '',
      };
      initStream();
    } else {
      // 获取预览key
      getSystemDict({ dictTypes: ['appkey', 'accesstoken'] }).then(({ records }) => {
        const appkey = records.find((i) => i.dictType === 'appkey')?.systemDicts[0]?.dictValue || '';
        const accesstoken = records.find((i) => i.dictType === 'accesstoken')?.systemDicts[0]?.dictValue || '';
        opts = {
          Header: { virtualmanKey: appkey },
          Type: 'preview',
          Appkey: appkey,
          AccessToken: accesstoken,
        };
        initStream();
      });
    }
    return () => {
      if (activeTimer) clearTimeout(activeTimer);
      if (playTimer) clearTimeout(playTimer);
      if (queueTimer) clearInterval(queueTimer);
      closeStream();
    };
  }, [anchorCode]);

  // 形象配置变更
  useEffect(() => {
    if (!config && config !== '{}' && status !== 'playing') return;
    changeStreamConfig();
  }, [config]);

  // 表情变更
  useEffect(() => {
    if (!expressionCode && status !== 'playing') return;
    const text = !!expressionCode ? expressionDefaultTextMap[expressionCode] : '';
    apaasTextCommand(
      {
        SessionId: sessionId,
        Command: 'SEND_TEXT',
        Data: {
          Text: `<speak><prosody rate="100%"><insert-expression type=${expressionCode}/>${text}</prosody></speak>`,
        },
      },
      opts,
    );
  }, [expressionCode]);

  // 动作变更
  useEffect(() => {
    if (!actionCode && status !== 'playing') return;
    apaasPreviewAction({ ActionCode: actionCode }, opts);
  }, [actionCode]);

  // 新建流
  const initStream = async () => {
    const localSessionId = localStorage.getItem('Apaas_Session_Id'); // 获取页面之前缓存的流Id
    if (localSessionId) await closeStream(); // 关闭
    setStatus('init');
    const data: ApaasData = {
      Protocol: 'trtc',
      DriverType: 1,
      UserId: uin || '',
    };
    if (!!imageTemplateId) data.ExtraInfo = { ImageTempleteId: imageTemplateId };
    apaasNewStream(data, opts).then((res: any) => {
      const { SessionId, PlayStreamAddr, QueuedSessionsCount } = res;
      if (!!PlayStreamAddr) {
        // 建流成功
        handlePlayStreamAddr(SessionId, PlayStreamAddr);
      } else {
        // 进入排队
        setStatus('queued');
        setQueuedCount(QueuedSessionsCount);
        queueTimer = setInterval(() => {
          checkQueuedStatus();
        }, 1000);
      }
    });
  };

  // 关流
  const closeStream = async () => {
    const localSessionId = localStorage.getItem('Apaas_Session_Id'); // 获取页面之前缓存的流Id
    // if (!!stream) {
    //   await RTC.current?.handleUnSubscribe(stream as RemoteStream);
    //   stream.stop();
    // }
    if (!sessionId && !localSessionId) return;
    await RTC.current?.handleLeave();
    return apaasCloseStream({ SessionId: sessionId || localSessionId }, opts, true)
      .then(() => {
        localStorage.removeItem('Apaas_Session_Id');
        setSessionId('');
        console.log(t('关流成功'), sessionId);
      })
      .catch(() => console.error(t('关流失败'), sessionId));
  };

  // 播流
  const startStream = () => {
    apaasStartStream({ SessionId: sessionId }, opts)
      .then(() => {
        console.log(t('播流成功'), sessionId);
        setStatus('playing');
        changeStreamConfig();
      })
      .catch(() => console.error(t('播流失败'), sessionId));
  };

  // 修改配置
  const changeStreamConfig = () => {
    if (status === 'configuring') {
      message.error(t('形象渲染中，请勿连续操作'));
      return;
    }
    if (!config) return;
    resetActiveTimer();
    setStatus('configuring');
    apaasChangeConfig(config, opts);
  };

  // 订阅trtc流
  const addRTCStream = async (stream: RemoteStream | LocalStream) => {
    if (playerRef?.current && stream) {
      setStream(stream);
      RTC.current?.handleStreamEvents(stream);
      RTC.current?.playStream(playerRef.current, stream, { objectFit: 'contain', muted: true });
      startStream();
    }
  };

  // 监听流状态变化
  const onStreamPlay = (type: 'video' | 'audio', state: 'PLAYING' | 'PAUSED' | 'STOPPED') => {
    if (type === 'video' && state === 'PLAYING') {
      setStatus('playing');
    }
  };

  // 5分钟无请求断流
  const resetActiveTimer = () => {
    if (activeTimer) clearTimeout(activeTimer);
    activeTimer = setTimeout(() => {
      setStatus('offline');
      closeStream();
    }, 1000 * 60 * 5);
  };

  // 播放30分钟断流
  const resetPlayTimer = () => {
    if (playTimer) clearTimeout(playTimer);
    playTimer = setTimeout(() => {
      setStatus('offline');
      closeStream();
    }, 1000 * 60 * 30);
  };

  // 检测排队状态
  const checkQueuedStatus = () => {
    apaasCheckQueuedStatus({ SessionId: sessionId }, opts).then((res: any) => {
      const { State, QueuedSessionsCount, CreatedAt, PlayStreamAddr } = res;
      if (State === 'created' && !!PlayStreamAddr) {
        if (queueTimer) clearInterval(queueTimer);
        setStatus('init');
        handlePlayStreamAddr(sessionId, PlayStreamAddr);
      } else if (State === 'queued') {
        setQueuedCount(QueuedSessionsCount || 0);
      }
    });
  };

  // 处理流地址
  const handlePlayStreamAddr = async (SessionId: string, PlayStreamAddr: string) => {
    const [host, query] = PlayStreamAddr.split('?');
    const roomId = Number(host.replace('trtc://', ''));
    const { userSig } = utils.urlParse(query);
    localStorage.setItem('Apaas_Session_Id', SessionId || '');
    setSessionId(SessionId || '');
    setRoomId(roomId);
    await RTC.current?.setUserSig(userSig);
    await RTC.current?.handleJoin();
    // 新建离线计时
    resetActiveTimer();
    resetPlayTimer();
  };

  // 取消排队
  const cancelQueued = async () => {
    if (queueTimer) clearInterval(queueTimer);
    await closeStream();
    setQueuedCount(0);
    setStatus('offline');
  };

  return (
    <div className="apaas-preview-container">
      {!!uin && !!roomId && (
        <RTCComponent
          onRef={(ref) => {
            RTC.current = ref;
          }}
          userID={uin || ''}
          roomID={roomId}
          audio={false}
          appID="1400695865"
          video={false}
          mode="live"
          addStream={addRTCStream}
          onStreamPlay={onStreamPlay}
        />
      )}
      {/* 流播放元素 */}
      <div ref={playerRef} className="stream-player"></div>
      {/* 初始化遮罩层 */}
      <div className="loading-box">
        <Spin spinning={['init'].includes(status)}></Spin>
      </div>
      {/* 排队弹窗 */}
      <Dialog
        header={
          queuedCount === 1
            ? t('前面有1个人在排队', { queuedCount })
            : t('前面有${queuedCount}个人在排队', { queuedCount })
        }
        visible={status === 'queued'}
        theme="info"
        confirmBtn={t('放弃等待')}
        closeBtn={false}
        cancelBtn={false}
        closeOnEscKeydown={false}
        closeOnOverlayClick={false}
        placement="center"
        onConfirm={cancelQueued}
        className="apaas-queued-dialog"
      />
      {/* 重连弹窗 */}
      <Dialog
        header={t('检测到页面长时间未操作，已断开连接')}
        visible={status === 'offline'}
        theme="info"
        confirmBtn={t('重新连接')}
        cancelBtn={false}
        closeOnEscKeydown={false}
        closeOnOverlayClick={false}
        placement="center"
        onConfirm={initStream}
        onClose={() => setStatus('none')}
        className="apaas-offline-dialog"
      />
    </div>
  );
};

export default React.memo(ApaasPreview);
