import ReactDOM from 'react-dom';
import { connect, Provider, useSelector } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider, message } from 'antd';
import { Loading } from 'tdesign-react';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import Cookies from 'js-cookie';

import * as action from './redux/action_creators';
import store from './redux/store';
import { authInfo, getCloudLoginUrl } from 'utils/auth';

import {
  PlatformType,
  QiankunInnerProps,
  QiankunOuterProps,
  QiankunCommonProps,
  QiankunInnerFoundationProps,
  IframeConfig,
} from 'redux/types';
import { RequestHeader, RequestRxt } from 'apis/capi/types';

import App from './components/Layout/';
import { isMicroApp } from './utils/env';
import utils from './utils';
import privateUtils from 'utils/private';
import 'tdesign-react/es/style/index.css';
// import 'antd/dist/antd.css';
import './assets/css/antd-modified-var.css';
import './assets/css/antd-var.less';
import './assets/css/index.scss';

import { useEffect, useState } from 'react';
import { UPDATE_IFRAME_CONFIG } from 'redux/action_types';

// import './utils/i18n';
import i18n from './utils/i18n';
const { t } = i18n;

const WrapApp = connect((state) => state, action)(App);

type IProps = QiankunInnerProps | QiankunOuterProps | QiankunCommonProps | QiankunInnerFoundationProps;

// 超过限制时，最早的消息会被自动关闭
message.config({ maxCount: 3 });

const ProviderComponent = (props: { mainAppBasename: string }) => (
  <Provider store={store}>
    <PageComponent mainAppBasename={props.mainAppBasename} />
  </Provider>
);

const PageComponent = (props: { mainAppBasename: string }) => {
  const configData = useSelector((state: { iframeConfig: IframeConfig }) => state.iframeConfig);
  useEffect(() => {
    if (utils.getIframeQueryVariable('iframe') === 'true') {
      window.addEventListener('message', function (event) {
        if (event.data?.includes?.('iframeConfig')) {
          try {
            const jsonConfig = JSON.parse(event.data);
            store.dispatch({ type: UPDATE_IFRAME_CONFIG, iframeConfig: jsonConfig });
          } catch (e) {
            console.log(e);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    ConfigProvider.config(configData);
  }, [configData]);
  return (
    <HashRouter basename={isMicroApp ? props.mainAppBasename || '/ivh' : '/'}>
      <ConfigProvider prefixCls="idh" locale={i18n.language === 'cn' ? zhCN : enUS}>
        <WrapApp />
      </ConfigProvider>
    </HashRouter>
  );
};

function renderPage(unFormatProps: IProps) {
  // 外部组件参数为了服务端命名统一使用大驼峰
  const props = utils.jsonFormat(unFormatProps, 'camel');
  // console.log(22, 'renderPage', unFormatProps, props);

  const { container } = unFormatProps;
  const { mainAppBasename = '/ivh', platformCode, toLogin, onImageEditStatusChange } = props;

  let platformType: PlatformType = '';
  let reqHeader: RequestHeader = {};
  let reqExt: RequestRxt = {};
  if ((props as QiankunInnerProps).key) {
    platformType = 'inner';
    const { key } = props as QiankunInnerProps;
    reqHeader = { platformCode, key };
  } else if ((props as QiankunOuterProps).authKey) {
    platformType = 'outer';
    const { authKey, encryptType, loginToken } = props as QiankunOuterProps;
    reqHeader = { platformCode, authKey, encryptType };
    reqExt = { platformCode, loginToken };
  } else if (
    (props as QiankunInnerFoundationProps).productTypeFrom &&
    (props as QiankunInnerFoundationProps).virtualmanKey
  ) {
    platformType = 'inner-foundation';
    const { productTypeFrom, virtualmanKey } = props as QiankunInnerFoundationProps;
    reqHeader = { platformCode, virtualmanKey };
    reqExt = { productTypeFrom };
  } else {
    platformType = 'virtualman';
    reqHeader = { platformCode, virtualmanKey: '' };
  }

  // 上线时可删除 - 防止历史cookie污染
  Cookies.remove('LoginToken');
  Cookies.remove('PlatformCode');
  Cookies.set('bvh_env', 'tencent-cloud');

  store.dispatch(
    action.changeCommonParams({ platformType, platformCode, reqHeader, reqExt, toLogin, onImageEditStatusChange }),
  );

  if (
    (props as QiankunInnerProps).key ||
    (props as QiankunInnerFoundationProps).virtualmanKey ||
    (props as QiankunOuterProps).authKey
  ) {
    action.fetchProjectInfo(); // 第三方平台已有key（包含virtualmanKey的加密串），先获取项目信息
  }

  ReactDOM.render(
    <ProviderComponent mainAppBasename={mainAppBasename} />,
    container ? container.querySelector('#react-body') : document.querySelector('#react-body'),
  );
}

function render(props: IProps) {
  // console.log(11, 'render', props);
  const { container, loadAsyncProps } = props;

  const renderDom = container ? container.querySelector('#react-body') : document.querySelector('#react-body');

  if (loadAsyncProps) {
    const maxLoadCount = 4;
    let loadCount = 0;
    ReactDOM.render(
      <Loading
        delay={0}
        fullscreen={false}
        indicator
        inheritColor={false}
        loading
        preventScrollThrough
        showOverlay
        size="medium"
        style={{ width: '100%', height: '100%' }}
      />,
      renderDom,
    );

    const timer = setInterval(() => {
      if (loadCount < maxLoadCount) {
        loadCount += 1;
        const showErrorTip = loadCount === maxLoadCount;
        loadAsyncProps()
          .then((data: IProps) => {
            clearInterval(timer);
            console.log('loadAsyncProps', data);
            renderPage({ ...props, ...data });
          })
          .catch((msg: string) => {
            showErrorTip && message.error({ content: msg || i18n.t('参数异常'), duration: 10 });
          });
      } else {
        clearInterval(timer);
      }
    }, 300);
  } else {
    renderPage(props);
  }
}

// 本地开发用
// @ts-ignore -- TODO: Property '__POWERED_BY_QIANKUN__' does not exist on type 'Window & typeof globalThis'.
if (!isMicroApp) {
  const virtualmanParams: QiankunCommonProps = {
    platformCode: 'virtualman', // 数智人 - 正式、测试、开发环境
    toLogin: () => {
      console.log('hahaha login fail');
      window.location.href = getCloudLoginUrl({ project: 'virtualman' });
      // window.location.replace(privateUtils.getLoginUrl());
    },
    // loadAsyncProps: () =>
    //   new Promise((resolve, reject) => {
    //     setTimeout(() => resolve(virtualmanParams), 3000);
    //     setTimeout(() => reject('调用异常'), 500);
    //   }),
  };
  //  const voiceHelperParams: QiankunInnerProps = {
  //   platformCode: 'voice_helper', // 语音助手
  //   key: '6qQ81O0TtHgQKH8Q7XUHllVbMvVVrUxdxBJG9Xw5IJhLbLKxOmsG9i3htABCuVFMZ70qGQ8GtrJL0ug45IA1hzv2JuUGXHSIK/eEkJIc6yTIYkFML7IoSRD1n9f8xS7n4uykb4EAMhzSiFXLAk1TRA==',
  // };
  // const customerParams: QiankunInnerProps = {
  //   platformCode: 'text_customer', // 客服
  //   key: 'pv3y1gC5LfpwHJQe2s5+xPlgLN3QqF5AhEADaFoUfXyZg9D/sIZlTldiYa2kQb+fGPXOXlH6gn5P9s0rv/bTqdJX77TxSSQkwNs48dwcHW+4nbgn9cCwu1sE+PvRPnZk',
  // };
  // const customerFoundationParams: QiankunInnerFoundationProps = {
  //   platformCode: 'text_customer', // 客服
  //   productTypeFrom: 'icr',
  //   virtualmanKey: '6699a9a7896d43d680fadf01d5246da5',
  // };
  const testOuterParams: QiankunOuterProps = {
    platformCode: 'zantong', // 外部测试
    authKey: process.env.REACT_APP_ZANTONG_TEST_AUTHKEY || '', // 测试环境
    loginToken: 'loginToken',
    encryptType: 'SM4',
  };
  const greyOuterParams: QiankunOuterProps = {
    platformCode: 'zantong', // 外部灰度
    authKey: process.env.REACT_APP_ZANTONG_AUTHKEY || '',
    loginToken: 'loginToken',
    encryptType: 'SM4',
  };
  // 日常为 virtualmanParams，注意不要改动
  // render(virtualmanParams);
  // TODO - 临时测试
  if (authInfo?.uin && ['o700000352162', 'o700000352158'].includes(authInfo.uin)) {
    render(testOuterParams);
  } else if (authInfo?.uin && ['o100026813349', 'o100012945519'].includes(authInfo.uin)) {
    render(greyOuterParams);
  } else {
    render(virtualmanParams);
  }
}

function storeTest(props: any) {
  props.onGlobalStateChange(
    (value: any, prev: any) => console.log(t('助手'), `[onGlobalStateChange - ${props.name}]:`, value, prev),
    true,
  );
  props.setGlobalState({
    ignore: props.name,
    user: {
      name: props.name,
    },
  });
}

export async function bootstrap() {
  console.log(t('[助手] react app bootstraped'));
}

export async function mount(props: any) {
  console.log(t('[助手] props from main framework'), props);
  storeTest(props);
  render(props);
}

export async function unmount(props: any) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(
    container ? container.querySelector('#react-body') : document.querySelector('#react-body'),
  );
}
