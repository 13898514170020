import React from 'react';
import classNames from 'classnames';
import './index.scss';

type IProps = {
  pageClassName?: string;
  pageTitle?: string;
  children?: React.ReactNode;
};

class PageWrapper extends React.Component<IProps, {}> {
  public render() {
    const { pageClassName, pageTitle, children } = this.props;
    return (
      <div className="ivh-page-wrapper">
        <div className={classNames(['ivh-common-page', pageClassName ? `${pageClassName}-page` : ''])}>
          <div className="ivh-page-title">{pageTitle}</div>
          {children}
        </div>
      </div>
    );
  }
}
export default PageWrapper;
