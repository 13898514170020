import React, { useEffect, useRef, useState } from 'react';
import './action_preview_item.scss';
import lottie, { AnimationItem } from 'lottie-web';

interface IProps {
  imageSize?: number;
  preview?: boolean;
  name: string;
  previewUrl: string;
  dynamicUrl: string;
  onMouseDown?: any; // TODO (e: MouseEvent) => void 为啥不对;
  scrollClassName: string;
}
interface IEntry {
  isIntersecting: boolean;
}
interface IObserver {
  disconnect: () => any;
}

const ActionPreviewItem = (props: IProps) => {
  const { imageSize, preview, name, previewUrl, dynamicUrl, scrollClassName, ...others } = props;
  const lottieRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<AnimationItem>();
  const callback = (entries: IEntry[], observer: IObserver) => {
    if (!lottieRef.current || !dynamicUrl) return;
    if (entries[0].isIntersecting) {
      animation?.destroy?.();
      setAnimation(
        lottie.loadAnimation({
          container: lottieRef.current as HTMLDivElement, // the dom element that will contain the animation
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: dynamicUrl, // the path to the animation json
        }),
      );
      observer.disconnect();
    }
  };
  useEffect(() => {
    // if (!lottieRef.current || !document.querySelector(scrollClassName)) return;
    setTimeout(() => {
      if (!dynamicUrl) return;
      const options = {
        threshold: 0,
        root: document.querySelector(scrollClassName),
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(lottieRef.current as HTMLDivElement);
    });
  }, [dynamicUrl]);
  return (
    <div className="ivh-action-preview-item" {...others}>
      {dynamicUrl.substring(dynamicUrl.length - 4) === '.gif' ? (
        <img
          className="ivh-action-img"
          src={dynamicUrl}
          alt=""
          style={{ ...(imageSize && { width: imageSize, height: imageSize }) }}
          onMouseDown={(e) => e.preventDefault()}
        />
      ) : (
        <div
          style={{ ...(imageSize && { width: imageSize, height: imageSize }) }}
          onMouseDown={(e) => e.preventDefault()}
          className="ivh-action-img"
          ref={lottieRef}
        />
      )}
      <div className="ivh-action-name">{name}</div>
    </div>
  );
};

ActionPreviewItem.defaultProps = {
  preview: true,
  name: '',
  previewUrl: '',
  dynamicUrl: '',
};

export default ActionPreviewItem;
