import i18n from 'utils/i18n';
import React, { useState, useEffect, ReactNode } from 'react';
import { Radio, RadioChangeEvent } from 'antd';

import { SwitchZoomProps } from './types';
import { AnchorPreviewItem } from 'pages/image_configure/types';
import { ZoomTooltip } from 'components/common';
import './index.scss';
const { t } = i18n;
type IProps = {
  disabled: boolean;
  switchZoomProps: SwitchZoomProps;
  resolutionValue: string;
  anchorImage: AnchorPreviewItem;
  previewZoom?: number;
  label?: ReactNode;
  onSwicthZoom: Function;
  removeBackground?: boolean;
};

const zoomRatioOptions = [
  { label: t('0.5倍'), value: 0.5 },
  { label: t('0.75倍'), value: 0.75 },
  { label: t('1.0倍'), value: 1 },
  { label: t('1.25倍'), value: 1.25 },
  { label: t('1.5倍'), value: 1.5 },
];

const SwitchZoom = (props: IProps) => {
  const {
    disabled,
    switchZoomProps: { defaultZoom, imgWidth, imgHeight },
    resolutionValue,
    anchorImage: { imageZoom, imagePosition, imagePositionVertical, unrealEngineMetadata },
    previewZoom = 1,
    onSwicthZoom,
    label,
    removeBackground = false,
  } = props;
  const [zoomRatio, setZoomRatio] = useState(1);
  const [maxZoom, setMaxZoom] = useState(1.5);
  let [maxPositionVertical, minPositionVertical, maxPosition, minPosition] = [0.25, -0.5, 0.49, -0.49];

  useEffect(() => {
    if (Number.isFinite(imageZoom) && Number.isFinite(defaultZoom)) {
      const radio = imageZoom / defaultZoom;
      const radioOption = zoomRatioOptions.find(({ value }) => Math.abs(radio - value) < 0.01);
      if (!!radioOption) {
        setZoomRatio(radioOption.value);
      } else {
        setZoomRatio(0);
      }
    }
  }, [imageZoom, defaultZoom]);

  useEffect(() => {
    if (imgWidth && imgHeight && resolutionValue) {
      // 最大缩放系数=Min(屏幕同宽 / 形象宽度，屏幕高度 / 形象一半高度)
      const [resolutionX, resolutionY] = resolutionValue.split('x').map((i) => parseInt(i.trim(), 10));
      const maxZoom = Math.min(
        resolutionX / imgWidth / (unrealEngineMetadata ? Number(unrealEngineMetadata) : 1),
        (resolutionY / imgHeight / (unrealEngineMetadata ? Number(unrealEngineMetadata) : 1)) * 2,
      );
      // setMaxZoom(maxZoom);
    }
  }, [imgWidth, imgHeight, resolutionValue, unrealEngineMetadata]);

  // 调整缩放倍数
  const changezoomRatio = (e: RadioChangeEvent) => {
    const tempRatio = e.target.value;
    const [resolutionX, resolutionY] = resolutionValue.split('x').map((i) => parseInt(i.trim(), 10));
    const newZoom = Math.min(defaultZoom * tempRatio, maxZoom);
    const newImgWidth = imgWidth * newZoom * Number(unrealEngineMetadata);
    const newImgHeight = imgHeight * newZoom * Number(unrealEngineMetadata);
    // 最大positionVertical=0.25，最小positionVertical不能超出上边界&最多上移两个身位
    minPositionVertical = Math.max((newImgHeight - resolutionY) / 2 / newImgHeight, -0.5);
    maxPositionVertical = 0.25;
    // 最大position最右侧，最大position最左侧
    maxPosition = (resolutionX - newImgWidth + newImgWidth / 2 - resolutionX / 2) / resolutionX;
    minPosition = (newImgWidth / 2 - resolutionX / 2) / resolutionX;
    onSwicthZoom({
      imageZoom: newZoom,
      // imagePosition: Math.max(maxPosition, minPosition),
      imagePositionVertical: Math.max(Math.min(imagePositionVertical, maxPositionVertical), minPositionVertical),
    });
  };

  return (
    <div className="switch-zoom" style={{ zoom: previewZoom }}>
      {label && <div className="label">{label}</div>}
      <div className="switch-zoom-box">
        <Radio.Group
          name="switchZoomRadio"
          disabled={disabled || removeBackground}
          optionType="button"
          buttonStyle="solid"
          onChange={changezoomRatio}
          value={zoomRatio}
        >
          {zoomRatioOptions.map(({ label, value }) => (
            <ZoomTooltip
              key={value}
              title={
                // eslint-disable-next-line no-nested-ternary
                removeBackground
                  ? t('实景形象暂不支持此操作')
                  : value > 1
                  ? t('形象比例设置{{value}}倍（可能会导致形象模糊）', { value })
                  : t('形象比例设置${value}倍', { value })
              }
              getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentElement || document.body}
            >
              <Radio value={value}>{label}</Radio>
            </ZoomTooltip>
          ))}
        </Radio.Group>
      </div>
    </div>
  );
};

export default React.memo(SwitchZoom);
