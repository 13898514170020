import React, { ForwardedRef, forwardRef } from 'react';
import { RTNode } from '../../types';
import { Tooltip } from 'antd';
import classNames from 'classnames';

interface IProps {
  idx: number;
  data: RTNode;
  type: 'start' | 'end';
  invalid: boolean;
  isInAction?: boolean;
  enableDetail?: boolean;
  onClick: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick?: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const SubNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { idx, data, invalid, type, isInAction, onClick, onMouseUp, onNodeClick } = props;
  const { tag } = data;

  return type === 'end' ? (
    <div
      className={classNames('rt-node', 'rt-sub-end', { invalid, isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      ]
      <Tooltip placement="top" title={tag}>
        <span className="rt-tag rt-sub-tag" onClick={onNodeClick}>
          {tag}
        </span>
      </Tooltip>
    </div>
  ) : (
    <div
      className={classNames('rt-node', 'rt-sub-start', { invalid, isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      [
    </div>
  );
});

export default SubNode;
