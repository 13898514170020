import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import './swiper.scss';

interface IProps {
  width: number;
  className: string | object;
  activeKey: number;
  children: React.ReactNode[];
}

const Swiper = (props: IProps) => {
  const { width, className, activeKey, children } = props;
  const [activeIdx, setActiveIdx] = useState(0);
  const childLen = Array.isArray(children) ? children.length : Number(!!children);

  useEffect(() => {
    setActiveIdx(activeKey);
  }, [activeKey]);

  return (
    <div className={classNames('rt-swiper', className)}>
      <div className="content" style={{ width }}>
        <div className="content-inner" style={{ left: -(activeIdx * width) }}>
          {childLen > 1 &&
            children.map((item, idx) => (
              <div key={`item-${idx}`} className="rt-swiper-slide" style={{ width }}>
                {item}
              </div>
            ))}
          {childLen === 1 && (
            <div className="rt-swiper-slide" style={{ width }}>
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Swiper.defaultProps = {
  widt: 0,
  className: '',
  style: {},
  onChange: () => {},
};

export default Swiper;
