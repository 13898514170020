import i18n from 'utils/i18n';
import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from '../../../../common';
import { RTNode } from '../../types';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import Tip from '../Tip';
const { t } = i18n;
interface IProps {
  idx: number;
  data: RTNode;
  invalid: boolean;
  isInAction?: boolean;
  enableDetail: boolean;
  onClick: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const PauseNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { idx, invalid, isInAction, onClick, onMouseUp, onNodeClick, enableDetail, data } = props;
  const nodeValue = data.tag && data.tag.match(/\d+/g) ? `${Number(data.tag) / 1000}s` : data.tag;
  return invalid ? (
    <div
      className={classNames('rt-node', 'rt-pause-invalid', { isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      {!enableDetail ? (
        <Tooltip placement="bottom" title={t('停顿已失效')}>
          <span className="rt-tag-invalid rt-tag-simplify" onClick={onNodeClick}>
            <Icon name="pause" size={16} color="#fff" />
          </span>
        </Tooltip>
      ) : (
        <span className="rt-tag-invalid" onClick={onNodeClick}>
          <Icon className="rt-invalid-icon" name="exception" />
          {t('无效配置')}:{nodeValue}
        </span>
      )}
    </div>
  ) : (
    <div
      className={classNames('rt-node', 'rt-pause', { isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      {!enableDetail ? (
        <span className="rt-tag rt-tag-simplify" onClick={onNodeClick}>
          <Icon name="pause" size={16} color="#fff" />
        </span>
      ) : (
        <span className="rt-tag" onClick={onNodeClick}>
          {nodeValue}
        </span>
      )}
    </div>
  );
});

PauseNode.displayName = 'PauseNode';

export default PauseNode;
