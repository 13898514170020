import i18n from 'utils/i18n';
import React, { useState, useEffect, Fragment } from 'react';

import Icon from '../../../../common/Icon';
import Pop from '../Pop';
import Tip from '../Tip';
import Process from '../Process';
import Loading from '../Loading';
import Slider from '../Slider';
import classNames from 'classnames';
import './index.scss';
const { t } = i18n;
const AuditionPop = (props: any) => {
  const { playState, position, hasLocalSpeed, placement = 'top' } = props;
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState('standby'); // 状态 2种 standby loading
  const [localSpeed, setLocalSpeed] = useState('1.0');

  useEffect(() => {
    if (playState !== status) setStatus(playState);
    if (playState === 'playing') props.onAuditionHide();
  }, [playState]);

  useEffect(() => {
    if (props.visible === true && visible === false) {
      setStatus('standby');
      setVisible(true);
    }
    if (props.visible === false && visible === true) {
      setVisible(false);
      setLocalSpeed('1.0');
    }
  }, [props.visible]);

  // 指令 1个 init
  const onClick = (e: any) => {
    e.stopPropagation();
    props.onAuditionHandle('init');
  };

  const stop = (e: any) => e.stopPropagation();

  const onLocalSpeedChange = (speed: any) => {
    setLocalSpeed(speed);
    props.onLocalSpeedChange(speed);
  };

  return (
    <Pop
      visible={visible}
      position={{
        left: position.left,
        top: position.top,
      }}
    >
      <div
        id="audio-pop-container"
        className={classNames('rt-audition', status, {
          'has-local-speed': hasLocalSpeed,
        })}
      >
        {status === 'standby' && (
          <div className="rt-audition-button play" onClick={(e) => onClick(e)}>
            <Process
              className="rt-audition-process"
              size={hasLocalSpeed ? 18 : 22}
              stroke="rgba(255, 255, 255, 1)"
              center={<Icon name="global-audio-play" size={18} />}
            />
            {t('试听')}
          </div>
        )}
        {(status === 'loading' || status === 'playing') && (
          <div className="rt-audition-button loading" onMouseDown={stop}>
            <Loading className="rt-audition-loading" size={15} />
            {t('生成中')}
          </div>
        )}
        {hasLocalSpeed && (
          <Fragment>
            <div className="rt-line" />
            <Tip
              placement="bottomLeft"
              trigger={['click']}
              getPopupContainer={() => document.getElementById('audio-pop-container')}
              overlay={
                <Slider className="rt-local-speed-slider" value={localSpeed} onSlideChange={onLocalSpeedChange} />
              }
            >
              <div className="rt-local-speed">
                <Icon className="speed" name="global-audio-speed" size={22} color="#fff" />
                {t('部分语速调节')}
                <Icon className="down" name="arrow-down-bold" color="rgba(255, 255, 255, 0.7)" />
              </div>
            </Tip>
          </Fragment>
        )}
        <div className={classNames('rt-arrow', placement)}>
          <Icon name="pop-arrow-top" size={16} color="#0052d9" />
        </div>
      </div>
    </Pop>
  );
};

AuditionPop.defaultProps = {
  playState: 'standby',
  position: {},
  onAuditionHandle: () => {},
  onAuditionHide: () => {},
  onLocalSpeedChange: () => {},
};

export default AuditionPop;
