import i18n from 'utils/i18n';
import React, { ForwardedRef, forwardRef } from 'react';
import { RTNode } from '../../types';
import { Tooltip } from 'antd';
import classNames from 'classnames';
const { t } = i18n;
interface IProps {
  idx: number;
  data: RTNode;
  type: 'start' | 'end';
  invalid: boolean;
  isInAction?: boolean;
  enableDetail?: boolean;
  onClick: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick?: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const WordNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { idx, invalid, type, isInAction, onClick, onMouseUp, onNodeClick } = props;

  return type === 'end' ? (
    <div
      className={classNames('rt-node', 'rt-word-end', { invalid, isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      ]
      {invalid ? (
        <Tooltip placement="bottom" title={t('当前音色下不生效')}>
          <span className="rt-tag rt-word-tag rt-tag-invalid" onClick={onNodeClick}>
            {t('连续')}
          </span>
        </Tooltip>
      ) : (
        <span className="rt-tag rt-word-tag" onClick={onNodeClick}>
          {t('连续')}
        </span>
      )}
    </div>
  ) : (
    <div
      className={classNames('rt-node', 'rt-word-start', { invalid, isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      [
    </div>
  );
});

export default WordNode;
