import store from 'redux/store';

import { RoleListInfo, VideoResource } from './types';
import {
  ResourceFlatItem,
  VirtualmanItem,
  ResolutionItem,
  ResourceFlatCode,
  RoleItem,
} from '../../pages/image_configure/types';
import {
  CHANGE_RESOURCE_LIST,
  CHANGE_RESOURCE_ITEM,
  CHANGE_IMAGE_CONFIG,
  CHANGE_ROLE_LIST,
  CLEAR_IMAGE_CONFIG,
  AUDITION_LOADING,
  AUDITION_PAUSE,
  AUDITION_STOP,
  AUDITION_PLAYING_IDX_UPDATE,
  AUDITION_PLAY_QUEUE_UPDATE,
  AUDIO_PROCESS_UPDATE,
  AUDITION_PLAY,
  CHANGE_VIDEO_RESOURCE,
  CHANGE_VIDEO_EDITOR_DATA,
  CLEAR_VIDEO_EDITOR_DATA,
} from './action_types';
import {
  DescribeVirtualmanResourceRoleListReq,
  getVirtualmanResourceRoleList,
  getVirtualmanResourceClothesList,
  DescribeVirtualmanResourceClothesListReq,
  getBroadcastTTS,
  GetBroadcastTTSReq,
  getUserResource,
  GetUserResourceReq,
} from 'apis';
import { changeEditingResource } from 'redux/action_creators';
import { cloneDeep } from 'lodash';

// 清空数据
export const clearBvhData = () => {
  store.dispatch({ type: CLEAR_IMAGE_CONFIG });
  store.dispatch({
    type: CHANGE_VIDEO_RESOURCE,
    data: { defaultBg: undefined, backgroundList: [], logoList: [], headList: [], tailList: [] },
  });
  changeRoleList({ roleList: [], totalCount: 0, offset: 0 });
  changeResourceList([]);
  changeCurResource({
    key: '',
    timbreCode: '',
    virtualmanResourceId: 0,
    colorPreviewUrl: '',
    clothesList: [],
    poseList: [],
    resolutionList: [],
    timbreList: [],
    modelSource: '',
    templateCode: '',
    clothesTemplates: [],
    clothesMeta: '',
    resolutionCode: '',
    modelPrecision: '',
  });
};

// 更新imageConfig
export const changeImageConfig = (data: any) => {
  window.showLeaveConfirmMessage = true;
  store.dispatch({ type: CHANGE_IMAGE_CONFIG, config: { ...store.getState().imageConfig, ...data } });
};

// 更新角色列表
export const changeRoleList = (data: RoleListInfo) => {
  store.dispatch({ type: CHANGE_ROLE_LIST, ...data });
};

// 获取数智人角色列表
export const getVirtualmanRoleResourceList = (payload: DescribeVirtualmanResourceRoleListReq) => {
  return getVirtualmanResourceRoleList({
    ...payload,
    sourceType: 2,
    hasVirtualmanResource: true,
  })
    .then(({ virtualmanResourceList, totalCount = 0 }) => {
      const roleList: RoleItem[] = !!payload.offset
        ? [...store.getState().roleListInfo.roleList, ...virtualmanResourceList]
        : virtualmanResourceList;
      changeRoleList({ roleList, totalCount, offset: payload?.offset + payload?.limit || 0 });
      return roleList;
    })
    .catch(() => {
      changeRoleList({ roleList: [], totalCount: 0, offset: 0 });
      return [];
    });
};

// 更新角色资源列表
export const changeResourceList = (data: ResourceFlatItem[]) => {
  store.dispatch({ type: CHANGE_RESOURCE_LIST, resourceList: [...data] });
};

// 更新当前所选资源
export const changeCurResource = (data: ResourceFlatItem) => {
  window.showLeaveConfirmMessage = true;
  store.dispatch({ type: CHANGE_RESOURCE_ITEM, resource: { ...data } });
  // 兼容AnchorImage预览
  changeEditingResource(data);
};

// 获取角色对应服装资源
export const getClothesList = (payload: DescribeVirtualmanResourceClothesListReq) => {
  return getVirtualmanResourceClothesList({ ...payload })
    .then(({ virtualmanResourcesImage }) => {
      return flatData(virtualmanResourcesImage);
    })
    .catch(() => {
      changeResourceList([]);
      return store.getState().resourceItem;
    });
};

const flatData = (virtualmanResourcesImage: VirtualmanItem & { removeBackground: boolean }) => {
  const resourceFlatArr: ResourceFlatItem[] = [];
  [virtualmanResourcesImage].forEach(
    ({
      virtualmanCode,
      clothesResources: clothesList,
      modelSource,
      fuseImageType,
      assetsType,
      headerThumbnailUrl,
      virtualmanName,
      isSmallSample,
      removeBackground,
    }) => {
      clothesList.forEach(({ clothesCode, clothesMeta, poseResources: poseList, clothesName, modelPrecision }) => {
        poseList.forEach(
          ({ poseCode, supportType, resolutionResources: resolutionList, timbreResources: timbreList, poseName }) => {
            const newResolutionList = sortResolutionList(resolutionList);
            newResolutionList.forEach(
              ({
                resolutionCode,
                anchorMetadata,
                clothesTemplates,
                resolutionType,
                anchorCode,
                driverTypes,
                originZoom,
                sampleUrl,
                resolutionValue,
                validOfPeriod,
              }) => {
                clothesTemplates.forEach((clothesTemplate) => {
                  resourceFlatArr.push({
                    key: getResourceFlatItemKey({
                      virtualmanCode,
                      clothesCode,
                      poseCode,
                      resolutionCode,
                      templateCode: clothesTemplate.templateCode,
                    }),
                    supportType,
                    virtualmanCode,
                    clothesCode,
                    clothesMeta,
                    poseCode,
                    resolutionCode,
                    resolutionType,
                    resolutionValue,
                    virtualmanResourceId: clothesTemplate.virtualmanResourceId || 0,
                    anchorCode,
                    colorPreviewUrl: clothesTemplate.resourcePreviewList.find((i) => i.angle === 0)?.previewUrl || '',
                    clothesList,
                    poseList,
                    resolutionList: newResolutionList,
                    timbreCode: timbreList[0]?.timbreCode,
                    timbreList,
                    modelSource,
                    anchorMetadata,
                    templateCode: clothesTemplate.templateCode,
                    clothesTemplates,
                    fuseImageType,
                    assetsType,
                    // @ts-ignore
                    driverTypes,
                    headerThumbnailUrl,
                    virtualmanName,
                    poseName,
                    clothesName,
                    resourcePreviewList: clothesTemplate.resourcePreviewList,
                    isSmallSample,
                    originZoom,
                    sampleImageVideoUrl: sampleUrl,
                    removeBackground: !removeBackground,
                    validOfPeriod,
                    modelPrecision,
                  });
                });
              },
            );
          },
        );
      });
    },
  );

  // @ts-ignore 找到virtualmanResourceId对应的信息
  let virtualmanResourceId = store.getState().imageConfig?.virtualmanResourceId;
  // @ts-ignore
  const { virtualmanCode, clothesCode, poseCode, resolutionCode } = store.getState().imageConfig;
  // 兼容播报历史数据，找到对应virtualmanResourceId
  if (!virtualmanResourceId && virtualmanCode && clothesCode && poseCode && resolutionCode) {
    virtualmanResourceId = resourceFlatArr.find(
      (i) =>
        i.virtualmanCode === virtualmanCode &&
        i.clothesCode === clothesCode &&
        i.poseCode === poseCode &&
        i.resolutionCode === resolutionCode,
    )?.virtualmanResourceId;
  }
  // 切换形象优先匹配同分辨率
  const { resolutionCode: curResolutionCode } = store.getState().resourceItem;
  if (!!curResolutionCode) {
    virtualmanResourceId = resourceFlatArr.find((i) => i.resolutionCode === curResolutionCode)?.virtualmanResourceId;
  }

  const sortList = sortResourceList<ResourceFlatItem>(resourceFlatArr);

  const curResource = sortList.find((i) => i.virtualmanResourceId === virtualmanResourceId) || sortList[0];
  changeResourceList(resourceFlatArr);
  changeCurResource(curResource);
  return curResource;
};

export const sortResourceList = <T extends { resolutionType?: string; resolutionValue?: string }>(list: T[]): T[] => {
  return cloneDeep(list).sort((a, b) => {
    if (a.resolutionType === 'vertical' && b.resolutionType !== 'vertical') {
      return -1; // a排在b前面
    }
    if (a.resolutionType !== 'vertical' && b.resolutionType === 'vertical') {
      return 1; // b排在a前面
    }
    if (a.resolutionType === 'vertical' && b.resolutionType === 'vertical') {
      // 分辨率高的排在前面
      const sum = (s: string): number => {
        const list: number[] = s.split('x').map((i) => +i);
        return list.reduce((a, b) => a + b, 0);
      };
      return sum(b.resolutionValue as string) - sum(a.resolutionValue as string);
    }
    return 0; // 保持原始顺序
  });
};

// 对分辨率进行排序 - 横屏在前、竖屏在后
const sortResolutionList = (resolutionList: ResolutionItem[]): ResolutionItem[] => {
  const landscapeArr: ResolutionItem[] = [];
  const verticalArr: ResolutionItem[] = [];
  resolutionList.forEach((t: ResolutionItem) => {
    const [x, y] = t.resolutionValue.split('x');
    const nArr = x > y ? landscapeArr : verticalArr;
    const i = nArr.findIndex((n) => n.resolutionValue.split('x')[0] < x);
    i > -1 ? nArr.splice(i + 1, 0, t) : nArr.push(t);
  });
  return [...landscapeArr, ...verticalArr];
};

export const getResourceFlatItemKey = ({
  virtualmanCode,
  clothesCode,
  poseCode,
  resolutionCode,
  templateCode,
}: ResourceFlatCode) => {
  const {
    virtualmanCode: prevVirtualmanCode,
    clothesCode: prevClothesCode,
    poseCode: prevPoseCode,
    resolutionCode: prevResolutionCode,
    templateCode: preTemplateCode,
    // @ts-ignore
  } = store.getState().resourceItem;

  let result = [
    `_virtualmanCode_${virtualmanCode || prevVirtualmanCode}&`,
    `_clothesCode_${clothesCode || prevClothesCode}&`,
    `_poseCode_${poseCode || prevPoseCode}&`,
    `_resolutionCode_${resolutionCode || prevResolutionCode}&`,
    `_templateCode_${templateCode || preTemplateCode}`,
  ];
  if (templateCode) {
    result = result.slice(0, 5);
  } else if (resolutionCode) {
    result = result.slice(0, 4);
  } else if (poseCode) {
    result = result.slice(0, 3);
  } else if (clothesCode) {
    result = result.slice(0, 2);
  } else if (virtualmanCode) {
    result = result.slice(0, 1);
  } else {
    result = result.slice(0, 0);
  }
  return result.join('');
};

// 播放加载中
export const auditionLoading = (params: any) => {
  const { ssmlList, globalSpeed, makeType, timbre, timbreType, timbreUrl } = params;
  store.dispatch({
    type: AUDITION_LOADING,
    ssmlList,
    globalSpeed,
    makeType,
    timbre,
    timbreType,
    timbreUrl,
  });
};

// 开始播放
export const auditionPlay = () => {
  store.dispatch({
    type: AUDITION_PLAY,
  });
};

// 暂停
export const auditionPause = () => {
  store.dispatch({
    type: AUDITION_PAUSE,
  });
};

// 停止播放
export const auditionStop = (stopToastMsg?: any) => {
  store.dispatch({
    type: AUDITION_STOP,
    stopToastMsg,
  });
};

// 播放下一段
export const auditionPlayingIdxUpdate = (params: any) => {
  const { playingIdx } = params;
  store.dispatch({
    type: AUDITION_PLAYING_IDX_UPDATE,
    playingIdx,
  });
};

// 播放队列改变
export const auditionQueueListUpdate = (params: any) => {
  const { playQueue } = params;
  store.dispatch({
    type: AUDITION_PLAY_QUEUE_UPDATE,
    playQueue,
  });
};

// 播放时间比改变
export const audioProcessUpdate = (audioProcess: any) => {
  store.dispatch({
    type: AUDIO_PROCESS_UPDATE,
    audioProcess,
  });
};

// 更改视频制作资源
export const changeVideoResource = (data: VideoResource) => {
  store.dispatch({
    type: CHANGE_VIDEO_RESOURCE,
    data: { ...data },
  });
};

export const getUserResources = (payload: GetUserResourceReq) => {
  return getUserResource(payload).then(
    ({ userResource: { logo: logoList, background: backgroundList, head: headList, tail: tailList } }) => {
      changeVideoResource({ logoList, backgroundList, headList, tailList });
      return { logoList, backgroundList, headList, tailList };
    },
  );
};

// 更改视频编辑数据
export const changeVideoEditorData = (data: any) => {
  store.dispatch({
    type: CHANGE_VIDEO_EDITOR_DATA,
    data: { ...data },
  });
};

export const clearVideoEditorData = (data = {}) => {
  store.dispatch({
    type: CLEAR_VIDEO_EDITOR_DATA,
    data: { ...data },
  });
};
