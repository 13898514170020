export const CHANGE_PLATFORM_INFO = 'CHANGE_PLATFORM_INFO';
export type CHANGE_PLATFORM_INFO = typeof CHANGE_PLATFORM_INFO;

// 这里的const/type模式允许我们以容易访问和重构的方式使用TypeScript的字符串字面量类型。

// 当前查看项目信息
export const CHANGE_PROJECT_INFO = 'CHANGE_PROJECT_INFO';
export type CHANGE_PROJECT_INFO = typeof CHANGE_PROJECT_INFO;

// 当前编辑项目信息
export const CHANGE_EDITING_RESOURCE = 'CHANGE_EDITING_RESOURCE';
export type CHANGE_EDITING_RESOURCE = typeof CHANGE_EDITING_RESOURCE;

// 用户权限列表
export const CHANGE_USER_PERMISSION = 'CHANGE_USER_PERMISSION';
export type CHANGE_USER_PERMISSION = typeof CHANGE_USER_PERMISSION;

// 登陆用户信息
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export type CHANGE_USER_INFO = typeof CHANGE_USER_INFO;

// 项目iframe嵌入信息
export const UPDATE_IFRAME_CONFIG = 'UPDATE_IFRAME_CONFIG';
export type UPDATE_IFRAME_CONFIG = typeof UPDATE_IFRAME_CONFIG;

// CAM无权限信息
export const UPDATE_CAM_MSG = 'UPDATE_CAM_MSG';
export type UPDATE_CAM_MSG = typeof UPDATE_CAM_MSG;
