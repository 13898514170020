import React, { ForwardedRef, forwardRef } from 'react';
import { Tooltip } from 'antd';

import { Icon } from '../../../../common';
import { RTNode } from '../../types';
import classNames from 'classnames';
interface IProps {
  idx: number;
  data: RTNode;
  isInAction?: boolean;
  enableDetail: boolean;
  onClick: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const PolyphonicNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    idx,
    data: { text, tag },
    isInAction,
    onClick,
    onMouseUp,
    onNodeClick,
    enableDetail,
  } = props;

  return (
    <div
      className={classNames('rt-node', 'rt-polyphonic', { isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      {enableDetail ? (
        <>
          <span className="rt-polyphonic-text" onClick={onNodeClick}>
            {text}
          </span>
          {tag && (
            <span className="rt-tag" onClick={onNodeClick}>
              {tag}
            </span>
          )}
        </>
      ) : (
        <Tooltip placement="bottom" title={<div>{tag}</div>}>
          <span className="rt-polyphonic-text" onClick={onNodeClick}>
            {text}
          </span>
          {tag && (
            <span className="rt-polyphonic-text rt-tag-simplify" onClick={onNodeClick}>
              <Icon name="thumbtack" size={16} color="#00a870" />
            </span>
          )}
        </Tooltip>
      )}
    </div>
  );
});

PolyphonicNode.displayName = 'PolyphonicNode';

PolyphonicNode.defaultProps = {
  idx: 0,
  data: {
    type: 'polyphonic',
    text: '',
    tag: '',
  },
  onClick: () => {},
  onMouseUp: () => {},
  onNodeClick: () => {},
};

export default PolyphonicNode;
