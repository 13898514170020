import React, { JSXElementConstructor } from 'react';
import classNames from 'classnames';
import './index.scss';

type IProps = {
  className?: string;
  title?: string;
  subTitle?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};

class Card extends React.Component<IProps, {}> {
  public render() {
    const { className, title, subTitle, style, children } = this.props;
    return (
      <div className={classNames(['ivh-card', className])} style={style}>
        {title && <div className="ivh-card-title">{title}</div>}
        {subTitle && <div className="ivh-sub-card-title">{subTitle}</div>}
        <div className="ivh-card-content">{children}</div>
      </div>
    );
  }
}
export default Card;
