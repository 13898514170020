import i18n from 'utils/i18n';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import flvjs from 'flv.js';
import { useSelector } from 'react-redux';

import { Modal, message, Spin } from 'antd';

import { getNewSecretDetail, getSecretDetail } from 'apis/interfaces/api_detail';

import { UserInfo } from 'redux/types';

import encBase64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { getUUID } from 'apis/capi/instance';
import { apassUrlMap } from 'apis/api/const';
const { t } = i18n;
interface PreviewModalProps {
  previewTime: number | undefined;
  previewSsml: string;
  previewWidth: number;
  onClose: Function;
  enablePreviewBtn?: Function;
}

let loopTimer: number | null = null;
let stopTimer: number | null = null;

const PreviewModal = (props: PreviewModalProps) => {
  const { previewTime, previewSsml, previewWidth, enablePreviewBtn } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [appkey, setAppkey] = useState<string>();
  const [accesstoken, setAccesstoken] = useState<string>();
  const [sessionId, setSessionId] = useState<string>();
  const [playStreamAddr, setPlayStreamAddr] = useState<string>();
  const [requestUrl, setRequestUrl] = useState('');
  const userInfo = useSelector((state: { userInfo: UserInfo }) => state.userInfo);
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);
  const { virtualmanKey, archVersion } = useSelector(
    (state: {
      projectInfo: {
        virtualmanKey: string;
        archVersion?: number;
      };
    }) => state.projectInfo,
  );

  const videoRef: any = useRef(null);
  const flvRef: any = useRef(null);
  useEffect(() => {
    if (!previewTime) {
      setVisible(false);
    } else if (!accesstoken) {
      if (archVersion === 2) {
        getNewSecretDetail().then(({ customerSecrets }) => {
          if (customerSecrets.length) {
            const { accessKey, secretKey, requestUrl } = customerSecrets?.[0];
            const env = process.env.REACT_APP_ENVIRONMENT as keyof typeof apassUrlMap;
            setRequestUrl(requestUrl);
            setAppkey(accessKey);
            setAccesstoken(secretKey);
          }
        });
      } else {
        getSecretDetail().then(({ accessKey: appkey, secretKey: accesstoken, requestUrl }) => {
          setRequestUrl(requestUrl);
          setAppkey(appkey);
          setAccesstoken(accesstoken);
        });
      }
    } else {
      openStream();
    }
  }, [previewTime, accesstoken]);

  useEffect(() => {
    if (visible) {
      setIsVideoLoading(true);
      // if (!!playStreamAddr && !!sessionId) {
      //   playStream(playStreamAddr, sessionId);
      // }
    }
  }, [visible]);

  const getPreviewUrl = (url: string) => {
    const env = process.env.REACT_APP_ENVIRONMENT;
    // @ts-ignore
    // const host = apassUrlMap[env];
    const host = requestUrl;
    console.log('--------apass--------', 'env', env, 'host', host);
    const timestamp = parseInt((new Date().getTime() / 1000).toString(), 10);
    const signature = encodeURIComponent(
      hmacSHA256(`appkey=${appkey}&timestamp=${timestamp}`, accesstoken as string).toString(encBase64),
    );
    return `${host}${url}?appkey=${appkey}&timestamp=${timestamp}&signature=${signature}`;
  };

  const apassRequest = (url: string, Payload: Object, showMessage = true) =>
    new Promise((resolve, reject) => {
      console.log('--------apass--------', url);
      axios
        .post(
          getPreviewUrl(url),
          { Header: {}, Payload },
          {
            headers: {
              Language: i18n.language === 'en' ? 'en-US' : 'zh-CN',
            },
          },
        )
        .then((res) => {
          if (res.status === 200) {
            const {
              Header: { Code, Message },
              Payload,
            } = res.data;
            if (Code === 0) {
              resolve(Payload);
            } else {
              showMessage && message.info(Code === 401 ? t('数智人正在初始化，请稍后再试') : Message);
              reject();
            }
          } else {
            showMessage && message.info(t('系统错误，请稍后重试！'));
            reject();
          }
        });
    });

  const playStream = (url: string, sessionId: string) => {
    flvRef.current = flvjs.createPlayer(
      { type: 'flv', url },
      { enableStashBuffer: false, fixAudioTimestampGap: false, isLive: true },
    );
    flvRef.current.attachMediaElement(videoRef.current);

    let reloadCount = 0;
    const maxReloadCount = 10;
    const interval = 1000;
    flvRef.current.on(flvjs.Events.ERROR, (e: any) => {
      if (e === flvjs.ErrorTypes.NETWORK_ERROR) {
        setTimeout(() => {
          if (reloadCount < maxReloadCount) {
            console.log('--------apass video error--------');
            reloadCount += 1;
            flvRef.current.unload?.();
            flvRef.current.load?.();
          } else {
            handleClose();
            message.error(t('预览失败，请重试'));
          }
        }, interval);
      }
    });
    flvRef.current.on(flvjs.Events.METADATA_ARRIVED, (e: any) => {
      // 文本驱动
      flvRef.current.play();
      const urlPath =
        archVersion === 2
          ? '/v2/ivh/interactdriver/interactdriverservice/command'
          : '/v2/ivh/streammanager/streamservice/command';
      // 新建会话
      const params =
        archVersion === 2
          ? {
              ReqId: getUUID().replace(/-/g, ''),
              SessionId: sessionId,
              Command: 'SEND_TEXT',
              Data: { Text: previewSsml, ChatCommand: 'NotUseChat' },
            }
          : {
              SessionId: sessionId,
              Command: 'SEND_TEXT',
              Data: { Text: previewSsml },
            };
      setTimeout(() => {
        apassRequest(urlPath, params);
      }, 3000);
    });
    flvRef.current.load();
  };

  const startSession = (sessionId: string, url: string) => {
    // 开启会话
    const urlPath =
      archVersion === 2
        ? '/v2/ivh/sessionmanager/sessionmanagerservice/startsession'
        : '/v2/ivh/streammanager/streamservice/startsession';
    // 新建会话
    const params =
      archVersion === 2
        ? {
            ReqId: getUUID().replace(/-/g, ''),
            SessionId: sessionId,
          }
        : {
            SessionId: sessionId,
          };
    apassRequest(urlPath, params)
      .then(() => {
        setPlayStreamAddr(url);
        enablePreviewBtn?.(true);
        setIsVideoLoading(false);
        playStream(url, sessionId);
      })
      .catch(() => {
        closePreviewStream();
        enablePreviewBtn?.(true);
      });
  };

  // 预览
  const openStream = () => {
    enablePreviewBtn?.(false);
    // 新建会话
    const urlPath =
      archVersion === 2
        ? '/v2/ivh/sessionmanager/sessionmanagerservice/createsession'
        : '/v2/ivh/streammanager/streamservice/newsession';
    // 新建会话
    const params =
      archVersion === 2
        ? {
            ReqId: getUUID().replace(/-/g, ''),
            VirtualmanProjectId: virtualmanKey,
            UserId: `${userInfo?.uin || ''}${+new Date()}`,
            Protocol: 'flv',
            DriverType: 1,
            Preview: 1,
          }
        : {
            UserId: `${userInfo?.uin || ''}${+new Date()}`,
            Protocol: 'flv',
            Preview: 1,
            // Beta: 1,
            DriverType: '1',
            IgnoreRender: true,
          };
    apassRequest(urlPath, params)
      .then(({ SessionId: sessionId, PlayStreamAddr: playStreamAddr, SessionStatus: sessionStatus }: any) => {
        setSessionId(sessionId);
        setVisible(true);
        if (sessionStatus === 3) {
          getPlayStreamAddrStart(sessionId);
        } else if (sessionStatus === 1 && !!playStreamAddr) {
          startSession(sessionId, playStreamAddr);
        }
      })
      .catch(() => {
        closePreviewStream();
        enablePreviewBtn?.(true);
      });
  };

  // 开始轮询
  const getPlayStreamAddrStart = (sessionId: string) => {
    loopTimer = window.setInterval(() => {
      getPlayStreamAddr(sessionId);
    }, 1000);
    stopTimer = window.setTimeout(() => {
      message.info(t('建流超时，请稍后重试'));
      closePreviewStream();
      loopTimer && clearInterval(loopTimer);
    }, 5 * 60 * 1000);
  };
  // 获取流播放地址
  const getPlayStreamAddr = (sessionId: string) => {
    const urlPath =
      archVersion === 2
        ? '/v2/ivh/sessionmanager/sessionmanagerservice/statsession'
        : '/v2/ivh/streammanager/streamservice/statsession';
    // 新建会话
    const params =
      archVersion === 2
        ? {
            ReqId: getUUID().replace(/-/g, ''),
            SessionId: sessionId,
          }
        : {
            SessionId: sessionId,
          };
    apassRequest(urlPath, params)
      .then(({ PlayStreamAddr: playStreamAddr, SessionStatus: sessionStatus }: any) => {
        if (sessionStatus !== 1 && sessionStatus !== 3) {
          loopTimer && window.clearInterval(loopTimer);
          stopTimer && window.clearTimeout(stopTimer);
          setVisible(false);
          closePreviewStream();
          const msgMap: any = { 2: t('已关闭'), 4: t('建流失败'), 5: t('建流超时') };
          message.info(msgMap[sessionStatus]);
        } else if (sessionStatus === 1) {
          loopTimer && window.clearInterval(loopTimer);
          stopTimer && window.clearTimeout(stopTimer);
          // setPlayStreamAddr(playStreamAddr);
          startSession(sessionId, playStreamAddr);
        }
      })
      .catch((e) => {
        message.info(t('建流失败，请稍后重试'));
        setVisible(false);
        closePreviewStream();
        loopTimer && window.clearInterval(loopTimer);
        stopTimer && window.clearTimeout(stopTimer);
      })
      .finally(() => {
        enablePreviewBtn?.(true);
      });
  };

  const closePreviewStream = () => {
    // 关闭会话
    const urlPath =
      archVersion === 2
        ? '/v2/ivh/sessionmanager/sessionmanagerservice/closesession'
        : '/v2/ivh/streammanager/streamservice/closesession';
    // 新建会话
    const params =
      archVersion === 2
        ? {
            ReqId: getUUID().replace(/-/g, ''),
            SessionId: sessionId,
          }
        : {
            SessionId: sessionId,
          };
    sessionId && apassRequest(urlPath, params, false);
    flvRef.current && flvRef.current.destroy();
    flvRef.current = null;
  };

  const handleClose = () => {
    loopTimer && window.clearInterval(loopTimer);
    stopTimer && window.clearTimeout(stopTimer);
    videoRef.current?.pause();
    props.onClose();
    closePreviewStream();
  };

  return (
    <Modal
      className="preview-modal"
      title={t('预览')}
      width={previewWidth * 0.9}
      visible={visible}
      onCancel={handleClose}
      footer={null}
    >
      <Spin spinning={isVideoLoading} tip={t('加载中...')}>
        <video style={{ width: '100%' }} ref={videoRef} />
      </Spin>
    </Modal>
  );
};

export default React.memo(PreviewModal);
