import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import './button.scss';

interface IProps {
  className: string | object;
  icon: any;
  focus: boolean;
  disabled: boolean;
  style: React.CSSProperties;
  disabledTimeout: number;
  onClick: (e: any) => void;
  onMouseDown: (e: any) => void;
  children: any;
  tooltip?: string;
  onMouseOut?: (e: any) => void;
}

const Button = (props: IProps) => {
  const { className, focus, icon, style, disabledTimeout, tooltip } = props;
  const timer = useRef<any>();
  const debounceTimer = useRef<any>({ timer: null });

  const [disabled, setDisabled] = useState(props.disabled);

  useEffect(() => {
    clearTimeout(timer.current);
    if (disabledTimeout && props.disabled) {
      timer.current = setTimeout(() => setDisabled(true), disabledTimeout);
    } else {
      setDisabled(props.disabled);
    }
  }, [props.disabled]);

  const onClick = (e: any) => {
    if (disabled) return;
    const { current } = debounceTimer;
    if (!current.timer) {
      current.timer = setTimeout(() => {
        current.timer = null;
      }, 300);
      props.onClick(e);
    }
  };
  return (
    <Tooltip title={tooltip} overlayClassName="rt-button-tooltip">
      <button
        className={classNames(
          'rt-button',
          {
            disabled,
            focus,
          },
          className,
        )}
        style={style}
        onClick={(e) => onClick(e)}
        onMouseDown={props.onMouseDown}
        onMouseOut={props.onMouseOut}
        type="button"
      >
        {icon}&nbsp;
        <span className="btn-text">{props.children}</span>
      </button>
    </Tooltip>
  );
};

Button.defaultProps = {
  className: '',
  focus: false,
  style: {},
  disabled: false,
  disabledTimeout: 0, // 延时disable，用于处理点击按钮input失焦问题
  icon: null,
  onClick: () => {},
  onMouseDown: () => {},
};

export default Button;
