import i18n from 'utils/i18n';
import { virtualmanType } from 'types/index';
import { FilterXSS } from 'xss';
const { t } = i18n;
// 卡通形象typeCode
export const cartoonTypeCodes = ['cartoon_2d', 'cartoon_3d'];

// 嵌入客服页面cdn
export const jweixinUrl = '<script src=//res.wx.qq.com/open/js/jweixin-1.2.0.js referrerpolicy=origin></script>';
export const openWorkUrl =
  '<script src=//open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js referrerpolicy=origin></script>';

// 嵌入客服页面模块
export const customerModules = [
  {
    subTitle: t('推荐配置'),
    key: 'recommended_configuration',
    moduleChildrens: [{ path: 'recommended_configuration', name: t('推荐配置'), key: 'recommended_configuration' }],
  },
  {
    subTitle: t('单轮知识问答'),
    key: 'single_qas',
    moduleChildrens: [
      { path: 'greetings/1/all/all', name: t('寒暄库'), key: 'greetings' },
      { path: 'qa_pairs/all', name: t('问答知识库'), key: 'qa_pairs' },
    ],
  },
  {
    subTitle: t('多轮知识问答'),
    key: 'multiturn_qas',
    moduleChildrens: [
      { path: 'multiturn_qas/index/all', name: t('场景化问答'), key: 'multiturn_qas' },
      { path: 'interface', name: t('接口管理'), key: 'interface' },
      { path: 'dictionaries/usr', name: t('词典管理'), key: 'dictionaries' },
      { path: 'wordSlotAdm/false', name: t('词槽管理'), key: 'wordSlotAdm' },
      { path: 'variable_manage', name: t('变量管理'), key: 'variable_manage' },
    ],
  },
  {
    subTitle: t('对话发布'),
    key: 'qas_publish',
    moduleChildrens: [{ path: 'publish', name: t('文本对话发布上线'), subTitle: t('对话发布'), key: 'publish' }],
  },
];
// 用户权限PermissionId
export const permissionIdMap = {
  // 形象融合
  imageSetWithFuse: 'IVHModule.VirtualmanManagement.ImageSetWithFuse',
  // 3d空间结合场景
  threeDimensionalSpace: 'IVHSpaceServiceScene',
  // 角色服务场景
  anchorService: 'IVHRoleServiceScene',
  // 播报数智人平台
  bvh: 'BVH',
  // 播报数据统计
  bvhStats: 'BVHModule.Statistics.Statistics',
  // 播报真人视频制作
  bvh2dHumanVideo: 'BVHModule.Production.Realman2DVideoProduction',
  // 播报真人音频制作
  bvh2dHumanAudio: 'BVHModule.Production.Realman2DAudioProduction',
  // 播报3d视频制作
  bvh3dHumanVideo: 'BVHModule.Production.3DVideoProduction',
  // 播报3d音频制作
  bvh3dHumanAudio: 'BVHModule.Production.3DAudioProduction',
  // 播报纯手语视频制作
  bvhSignLangVideo: 'BVHModule.Production.SignLangVideoProduction',
  // 播报嵌入式视频制作
  bvhEmbSignLangVideo: 'BVHModule.Production.EmbSignLangVideoProduction',
  // 播报动画视频制作
  bvh2dCartoonVideo: 'BVHModule.Production.CartoonVideoProduction',
  // 人工坐席
  ivhCustomerManage: 'IVHSeatManager',
  // 人工坐席-音频驱动
  ivhCustomerManageAD: 'IVHSeatManager.AudioDriver',
  // 人工坐席-视频驱动
  ivhCustomerManageVD: 'IVHSeatManager.VideoDriver',
  // 客服大模型
  ivhCloudAIGPT: 'IVHRoleServiceScene.CloudAIGPT',
};

export const expressionVaildCode: string[] = [];

const isTextEnv = process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'test';

// h5 cos前缀
export const h5CosPrefix = isTextEnv
  ? 'https://cdn.xiaowei.qq.com/virtual-human/index.html?/ivh_sdk'
  : 'https://virtual-human-1251316161.cos.ap-beijing.myqcloud.com/ivh_sdk';

export const h5CosIntlPrefix =
  'https://virtualhuman-sg-website-prod-1251316161-1251316161.cos.ap-singapore.myqcloud.com/ivh_sdk';

// cos前缀
export const cosPrefix = 'https://virtual-human-1251316161.cos.ap-beijing.myqcloud.com/ivh_sdk';

/**
 * 数智人类型VITTUALMAN_TYPE_V2_枚举
 **/
// 2D精品
export const TYPE_V2_REALMAN: string[] = ['real_man_2d'];
// 2D小样本
export const TYPE_V2_SMALLSAMPLE: string[] = [
  'small_sample_4k_2d',
  'small_sample_general_2d',
  'small_sample_2d',
  'small_sample_photo_2d',
];
// 2D真人
export const TYPE_V2_2D = [...TYPE_V2_REALMAN, ...TYPE_V2_SMALLSAMPLE];
// 3D
export const TYPE_V2_3D: string[] = ['real_man_3d', 'half_realistic_3d', 'cartoon_3d'];
// 2D卡通
export const TYPE_V2_CARTOON: string[] = ['cartoon_2d'];

// 嵌入大模型页面模块
export const lkeModules = [
  {
    permissionKey: 'hasLkeAppPermission',
    path: 'lke/app/knowledge/app-config',
    name: t('大模型配置'),
    key: '/lke/app/knowledge/app-config',
  },
];
export const SMALLSAMPLE_SHORTEND: { [key: string]: string } = {
  small_sample_2d: t('专属'),
  small_sample_general_2d: t('通用'),
  small_sample_4k_2d: t('高精'),
  small_sample_photo_2d: t('照片'),
};

export const getVirtualmanTypeCode = (virtualmanTypeV2: string) => {
  if (virtualmanTypeV2 === 'small_sample_2d') return 'real_man_2d';
  if (TYPE_V2_3D.includes(virtualmanTypeV2)) return 'real_man_3d';
  return virtualmanTypeV2;
};

export const getNewSortVirtualmanType = (virtualmanTypeInfos: virtualmanType[]) => {
  const newVirtualmanTypeInfos: virtualmanType[] = [];
  [...TYPE_V2_REALMAN, ...TYPE_V2_SMALLSAMPLE, ...TYPE_V2_CARTOON, ...TYPE_V2_3D].forEach((s) => {
    const index = virtualmanTypeInfos.findIndex((v) => v.dictCode === s);
    index > -1 && newVirtualmanTypeInfos.push(virtualmanTypeInfos[index]) && virtualmanTypeInfos.splice(index, 1);
  });
  return newVirtualmanTypeInfos;
};

const xssHtmlOptions = {
  css: false,
  onTagAttr(tag: string, name: string, value: string) {
    const tagIgnore = ['div', 'span', 'strong', 'p', 'b'];
    if (tagIgnore.includes(tag)) {
      // do not filter its attributes
      return `${name}="${value}"`;
    }
    const tagAttrIgnore = ['class', 'style'];
    if (tagAttrIgnore.includes(name)) {
      return `${name}="${value}"`;
    }
    // 自定义属性省略
    const aTagAttrIgnore = ['rel', 'data-link-url'];
    if (tag === 'a' && aTagAttrIgnore.includes(name)) {
      return `${name}="${value}"`;
    }
    const videoTagAttrIgnore = ['rel', 'webkit-playsinline', 'x5-playsinline'];
    if (tag === 'video' && videoTagAttrIgnore.includes(name)) {
      return `${name}="${value}"`;
    }
    const audioTagAttrIgnore = ['controls', 'controlslist'];
    if (tag === 'audio' && audioTagAttrIgnore.includes(name)) {
      return `${name}="${value}"`;
    }
    const imgTagAttrIgnore = ['alt', 'data-value'];
    if (tag === 'img' && imgTagAttrIgnore.includes(name)) {
      return `${name}="${value}"`;
    }
  },
};

export const xssHandlerProcess = new FilterXSS(xssHtmlOptions);
