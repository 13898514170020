import i18n from 'utils/i18n';
import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from '../../../../common';
import { Tip, ActionPreview } from '../index';
import { RTNode } from '../../types';
import { Tooltip } from 'antd';
const { t } = i18n;
interface IProps {
  idx: number;
  data: RTNode;
  invalid: boolean;
  actionMap: {
    [type: string]: any;
  };
  enableDetail: boolean;
  onClick: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const ActionNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    idx,
    data: { value, tag },
    invalid,
    actionMap,
    onClick,
    onMouseUp,
    onNodeClick,
    enableDetail,
  } = props;

  const action = actionMap[value || ''];

  if (!actionMap || !action)
    return (
      <div className="rt-node rt-action-invalid" data-idx={idx} ref={ref} onClick={onClick} onMouseUp={onMouseUp}>
        <Tooltip placement="bottom" title={t('动作已失效')}>
          <span className="rt-tag-invalid rt-tag-simplify">
            <Icon className="tag-icon" name="action" size={12} color="#fff" />
          </span>
        </Tooltip>
      </div>
    );

  return invalid ? (
    <div className="rt-node rt-action-invalid" data-idx={idx} ref={ref} onClick={onClick} onMouseUp={onMouseUp}>
      <Tooltip placement="bottom" title={t('动作已失效')}>
        {enableDetail ? (
          <span className="rt-tag-invalid" onClick={onNodeClick}>
            <Icon className="rt-invalid-icon" name="exception" />
            {t('无效配置')}:{tag || action.actionName}
          </span>
        ) : (
          <span className="rt-tag-invalid rt-tag-simplify" onClick={onNodeClick}>
            <Icon className="tag-icon" name="action" size={12} color="#fff" />
          </span>
        )}
      </Tooltip>
    </div>
  ) : (
    <div className="rt-node rt-action" data-idx={idx} ref={ref} onClick={onClick} onMouseUp={onMouseUp}>
      <Tip
        className="action-tip"
        placement="bottomLeft"
        trigger={['hover']}
        overlay={<ActionPreview className="normal" data={action} dynamic border />}
      >
        {enableDetail ? (
          <span className="rt-tag" onClick={onNodeClick}>
            {tag || action.actionName}
          </span>
        ) : (
          <span className="rt-tag rt-tag-simplify" onClick={onNodeClick}>
            <Icon className="tag-icon" name="action" size={12} color="#fff" />
          </span>
        )}
      </Tip>
    </div>
  );
});

ActionNode.displayName = 'ActionNode';

ActionNode.defaultProps = {
  idx: 0,
  data: {
    type: 'action',
    tag: '',
    value: '',
  },
  actionMap: {},
  onClick: () => {},
  onMouseUp: () => {},
  onNodeClick: () => {},
};

export default ActionNode;
