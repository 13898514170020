import React, { useState, createRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Space, Tabs, Carousel, Collapse, Dropdown, Menu } from 'antd';
import { GiftOutlined, RightOutlined } from '@ant-design/icons';
import { TabItem, SummarizeItem, CaseItem } from './defaultData';
import { CarouselRef } from 'antd/lib/carousel';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { isPrivate } from 'utils/env';

type IProps = {
  isOwner: boolean;
};
const { TabPane } = Tabs;
const { Panel } = Collapse;

const Home: React.FC<IProps> = (props) => {
  const { t, i18n } = useTranslation();
  const imgDir = i18n.language === 'en' ? './assets/en/' : './assets/';
  const summarizeData: SummarizeItem[] = [
    {
      title: t('形象设定'),
      icon: require('./assets/home-icon-set.png'),
      btn: {
        label: t('我的数智人'),
        url: '/image_template',
      },
      tabs: [
        {
          label: t('多种形象'),
          url: require('./assets/image1_2x.jpeg'),
          selected: true,
        },
        {
          label: t('外形设计'),
          url: require('./assets/image2_2x.jpeg'),
        },
        {
          label: t('声音定制'),
          url: require(`${imgDir}image3_2x.jpeg`),
        },
        {
          label: t('AI驱动'),
          url: require(`${imgDir}image4_2x.jpeg`),
        },
      ],
    },
    {
      title: t('场景应用'),
      icon: require('./assets/home-icon-scene.png'),
      btn: {
        label: t('项目管理'),
        url: '/application',
      },
      tabs: [
        {
          label: t('会话互动'),
          url: require(`${imgDir}scene1_2x.jpeg`),
          selected: true,
        },
        {
          label: t('空间互动'),
          url: require('./assets/scene2_2x.jpeg'),
        },
        {
          label: t('音视频播报'),
          url: require('./assets/scene3_2x.jpeg'),
        },
      ],
    },
  ];
  const caseData: CaseItem[] = [
    {
      label: t('APP / Win端嵌入'),
      items: [
        {
          label: t('智能客服'),
          content: t(
            '提供基于数字人形象的智能客服，配合图文/卡片/点选按钮等组件提供更高效的客户服务，有效提升客户体验。',
          ),
          url: require('./assets/case1-1.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
        {
          label: t('线上导购'),
          content: t(
            '基于商品知识库及语音交互能力，数字人可根据客户需求进行商品讲解及推荐，扮演数字商品导购员提供全新的线上购物体验。',
          ),
          url: require('./assets/case1-2.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
        {
          label: t('智能面签'),
          content: t(
            '基于金融知识库，数字人可化身数字理财经理，为用户提供财富体检，理财推荐等服务，有效提升用户的服务覆盖及转化。',
          ),
          url: require('./assets/case1-3.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
        {
          label: t('智能陪练'),
          content: t('基于课程脚本，提供可与学员智能问答的数字人培训师，服务于培训、教学、演练等具体场景。'),
          url: require('./assets/case1-4.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
      ],
    },

    {
      label: t('小程序 / H5 / Web接入'),
      items: [
        {
          label: t('3D虚拟营业厅'),
          content: t(
            '通过快速访问数字人服务，基于语音会话、选择等多种交互形式，提供讲解、导购、助销触达等能力，有效提升营销科技感。',
          ),
          url: require('./assets/case2-1.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
        {
          label: t('虚拟展厅/导游'),
          content: t(
            '通过快速访问数字人服务，基于表情、动作、图文等多种展现形式，提供主动迎宾，展厅讲解、互动问答等能力，实现智能展厅的体验，有效提升展厅科技感。',
          ),
          url: require('./assets/case2-2.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
        {
          label: t('虚拟主播'),
          content: `AI${t(
            '虚拟主播可以短时间内生成大量内容视频，输入文字内容即可生成视频，大大降低了视频的制作成本、审核成本和修改成本，适用于突发事件的实时响应、短视频快速生成。',
          )}`,
          url: require('./assets/case2-3.jpeg'),
          serviceUrl: require(`${imgDir}service.png`),
        },
      ],
    },
    {
      label: t('云屏集成'),
      items: [
        {
          label: t('网点迎宾员'),
          content: t(
            '提供多种规格的数字人大屏，在线下营业厅等场景中提供数字大堂经理服务，可实现取号、叫号、咨询、业务办理等能力。',
          ),
          url: require(`${imgDir}case3-1.jpeg`),
          serviceUrl: require(`${imgDir}service.png`),
        },
        {
          label: t('数字前台/柜员'),
          content: t(
            '提供多种规格的数字人云屏，打通已有的业务系统，顾客可在数字前台直接完成业务办理，有效提升效率、降低员工成本。',
          ),
          url: require(`${imgDir}case3-2.jpeg`),
          serviceUrl: require(`${imgDir}service.png`),
        },
      ],
    },
  ];
  const [summarizeList, setSummarizeList] = useState(summarizeData);
  const carousel0 = createRef<CarouselRef>();
  const carousel1 = createRef<CarouselRef>();
  const curTabStates: any[] = [];
  const navigate = useNavigate();
  for (let i = 0; i < caseData.length; i++) {
    curTabStates.push(useState(caseData[i].items[0].serviceUrl));
  }
  const onMouseEnter = (i: number, j: number, s: SummarizeItem, t: TabItem) => {
    (i === 0 ? carousel0 : carousel1).current?.goTo(j);
    changeCarouselStatus(s, t);
  };
  // 轮播图切换防抖
  const debounceMouseEnter = debounce(
    (i: number, j: number, s: SummarizeItem, t: TabItem) => onMouseEnter(i, j, s, t),
    500,
  );
  const changeCarouselStatus = (s: SummarizeItem, t: TabItem) => {
    const newTabs = s.tabs.map((item: TabItem) => ({ ...item, selected: item.label === t.label }));
    setSummarizeList(
      summarizeList.map((summarize) => ({
        ...summarize,
        ...(summarize.title === s.title ? { tabs: newTabs } : null),
      })),
    );
  };
  const onCollapseChange = (key: string | string[]) => {
    if (typeof key === 'string') {
      const [i, j] = key.split('_');
      curTabStates[+i][1](caseData[+i].items[+j].serviceUrl);
    }
  };
  const goto = (url?: string) => {
    if (isPrivate && url === '/image_template') return;
    url && navigate(url);
  };
  return (
    <div className="home-bg">
      <div className="home-bg-box">
        <div className="home-container">
          <div className="home-header">
            <h4>{t('数智人平台')}</h4>
            <p className="sub-title-1">{t('沟通有温度 服务新高度')}</p>
            <p className="sub-title-2">{t('一站式数字人定制、应用场景编排、应用效果分析服务')}</p>
            {!isPrivate && props.isOwner && (
              <p
                className="sub-title-3"
                onClick={() => {
                  navigate('/asserts_management');
                }}
              >
                {t('免费体验音视频播报服务')}
                <span>|</span>
                <GiftOutlined />
                {t('立即领取')}
                <RightOutlined />
              </p>
            )}
          </div>
          <div className="home-summarize">
            <Space size={32}>
              {summarizeList.map((s, i) => (
                <div key={`s-${i}`} className="home-summarize-item" onClick={() => goto(s?.btn?.url)}>
                  <h2>
                    <img src={s.icon} width={60} />
                    {s.title}
                    {s.btns ? (
                      <Dropdown
                        getPopupContainer={() => document.querySelector('.home-bg') as HTMLElement}
                        placement="bottom"
                        overlay={
                          <Menu>
                            {s.btns.map((b) => (
                              <Menu.Item key={b.url} onClick={() => goto(b.url)}>
                                {b.label}
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                      >
                        <span className="arrows-right"></span>
                      </Dropdown>
                    ) : (
                      <span
                        style={{ display: isPrivate && s?.btn?.url === '/image_template' ? 'none' : 'block' }}
                        className="arrows-right"
                      ></span>
                    )}
                  </h2>
                  <div className="home-summarize-item-carousel">
                    <Carousel ref={i === 0 ? carousel0 : carousel1}>
                      {s.tabs.map((t, j) => (
                        <div key={`s-${i}-t-${j}`}>
                          <img src={t.url} />
                        </div>
                      ))}
                    </Carousel>
                    <div
                      className="home-summarize-item-types"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <ul>
                        {s.tabs.map((t, j) => (
                          <li
                            key={`s-${i}-l-${j}`}
                            className={t.selected ? 'active' : ''}
                            onMouseEnter={() => {
                              debounceMouseEnter(i, j, s, t);
                            }}
                          >
                            {t.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </Space>
          </div>
          <div className="home-cases">
            <h3>{t('解决方案推荐')}</h3>
            <Tabs centered>
              {caseData.map((c, i) => (
                <TabPane tab={c.label} key={`c-${i}`}>
                  <div className="home-case-content">
                    <Collapse defaultActiveKey={[`${i}_0`]} accordion onChange={onCollapseChange}>
                      {c.items.map((t, j) => (
                        <Panel
                          header={
                            <div
                              className="home-collapse-panel"
                              onMouseEnter={(e: any) => {
                                e.target.click();
                              }}
                              onClick={(e: any) => {
                                if (
                                  e.target.parentElement.parentElement.className.includes('idh-collapse-item-active')
                                ) {
                                  e.stopPropagation();
                                }
                              }}
                            >
                              {t.label}
                            </div>
                          }
                          key={`${i}_${j}`}
                        >
                          <p>{t.content}</p>
                          <img src={t.url} alt="" width={460} height={228} />
                        </Panel>
                      ))}
                    </Collapse>
                    <div>
                      <img src={curTabStates[i][0]} alt="" width={905} height={652} />
                    </div>
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(({ userInfo: { uin, ownerUin } }: any) => ({
  uin,
  ownerUin,
}))((props: any) => {
  const { uin, ownerUin } = props;
  return <Home isOwner={!!uin && ownerUin === uin} />;
});
