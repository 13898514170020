import i18n from 'utils/i18n';
const callback = (res: { ret: number; ticket: string; randstr: string; errorCode?: number; errorMessage?: string }) => {
  /* res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
     res（客户端出现异常错误 仍返回可用票据） = {ret: 0, ticket: "String", randstr: "String",
                                           errorCode: Number, errorMessage: "String"}
     res（用户主动关闭验证码）= {ret: 2}
  */
  if (res.ticket) {
    console.log('ticket', res.ticket);
    console.log('randstr', res.randstr);
    // ticket长度预留空间请不要小于1024字节
    // 上传票据 可根据errorCode和errorMessage做特殊处理或统计
  }
};

export type CaptchaRes = {
  ret: number; // 验证结果，0-验证成功; 2-用户主动关闭验证码
  ticket: string;
  randstr: string;
  errorCode?: number;
  errorMessage?: string;
};

// 验证码js加载错误处理
const loadErrorCallback = (callback: Function) => {
  const appid = process.env.REACT_APP_CAPTCHA_APP_ID;
  // 生成票据或自行做其它处理
  const ticket = `terror_1001_${appid}_${Math.floor(new Date().getTime() / 1000)}`;
  callback({
    ret: 0,
    randstr: `@${Math.random().toString(36).substr(2)}`,
    ticket,
    errorCode: 1001,
    errorMessage: 'jsload_error',
  });
};

export const loadCaptcha = (callback: Function) => {
  try {
    // console.log('captcha', process.env.REACT_APP_CAPTCHA_APP_ID);
    // 生成一个验证码对象
    // @ts-ignore
    const captcha = new TencentCaptcha(
      process.env.REACT_APP_CAPTCHA_APP_ID, // 验证码appid
      callback, // 验证码回调函数
      {
        // 验证码options配置参数
        ready: () => {},
        needFeedBack: true,
        userLanguage: i18n.language,
      },
    );
    // 显示验证码
    captcha.show();
  } catch (error) {
    loadErrorCallback(callback);
  }
};
