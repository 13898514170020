import React, { useEffect, useState, useMemo } from 'react';

import { Space, Pagination } from 'antd';
import { ActionPreview, Swiper, Tabs } from '../index';

import { ActionDetail, ActionEnum, ActionPositionMap, DisabledActionMap } from '../../types';

import './action.scss';

interface IProps {
  active: string;
  disabledActionMap: DisabledActionMap;
  actionEnum: ActionEnum[];
  actionPositionMap: ActionPositionMap;
  mode: string;
  onSelect: (actionItem: ActionDetail) => void;
}
// Tabs -> Swiper -> 分页
const ActionPane = (props: IProps) => {
  const { active, disabledActionMap, actionEnum, actionPositionMap, mode } = props;

  const [tabIdx, setTabIdx] = useState<number>(0);
  const [swiperIdx, setSwiperIdx] = useState<number>(0);

  useEffect(() => {
    const [tabIdx = 0, swiperIdx = 0] = active ? actionPositionMap[active] : [0, 0];
    setTabIdx(tabIdx);
    setSwiperIdx(swiperIdx);
  }, [active]);

  const availableCount = useMemo(() => {
    const countList: any[] = [];
    actionEnum.forEach(({ actionList }, idx) => {
      let num = 0;
      actionList.forEach((paneList) => {
        paneList.forEach(({ actionId }) => {
          if (!disabledActionMap[actionId]) num += 1;
        });
      });
      countList[idx] = num;
    });
    return countList;
  }, [disabledActionMap]);

  const onSelect = (actionItem: ActionDetail) => props.onSelect(actionItem);

  const tabChange = (idx: number) => {
    setTabIdx(idx);
    setSwiperIdx(0);
  };

  const pageChange = (idx: number) => {
    setSwiperIdx(idx - 1);
  };

  return (
    <div className="rt-action-pane" onClick={(e) => e.stopPropagation()}>
      <Tabs activeKey={tabIdx} onChange={tabChange}>
        {actionEnum.map(({ actionList, groupName }, idx) => (
          <Tabs.TabPane tab={groupName} key={idx} info={`(${availableCount[idx]})`}>
            <Swiper className="preview-swiper" activeKey={swiperIdx} width={376}>
              {actionList.map((paneList, k) => (
                <Space key={k} size={20} wrap>
                  {paneList.map((action, i) => {
                    const { actionId } = action;
                    return (
                      <ActionPreview
                        key={i}
                        active={active === actionId}
                        dynamic={true}
                        hide={k !== swiperIdx}
                        disabled={disabledActionMap[actionId]}
                        data={action}
                        mode={mode}
                        onClick={() => onSelect(action)}
                      />
                    );
                  })}
                </Space>
              ))}
            </Swiper>
            <div className="action-pagination">
              <Pagination current={swiperIdx + 1} pageSize={1} total={actionList.length} onChange={pageChange} />
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

ActionPane.defaultProps = {
  active: '',
  actionPositionMap: null,
  disabledActionMap: {},
  mode: 'action',
  onSelect: () => {},
};

export default ActionPane;
