import React from 'react';
import { Link } from 'react-router-dom';
import './index.scss';
import { LeftOutlined } from '@ant-design/icons';
import LeftLink from '../LeftLink';
interface PageTitleProps {
  title: string;
  gotoUrl?: string;
  isGoBack?: boolean;
  subTitle?: string | React.ReactNode;
  marginTop?: number;
  marginBottom?: number;
  data?: object;
}

const PageTitle = (props: PageTitleProps) => {
  const marginStyle = {
    marginTop: props.marginTop || 0,
    marginBottom: props.marginBottom || 0,
  };
  const { title, subTitle, ...rest } = props;
  return (
    <div style={marginStyle} className="ivh-page-title">
      <div className="page-title">
        <LeftLink {...rest} />
        {title}
      </div>
      {subTitle && <div className="sub-title">{subTitle}</div>}
    </div>
  );
};

export default React.memo(PageTitle);
