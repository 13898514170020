import i18n from 'utils/i18n';
import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from '../../../../common';
import { Tip, ActionPreview } from '../index';
import { RTNode } from '../../types';
import { Tooltip } from 'antd';
import classNames from 'classnames';
const { t } = i18n;
interface IProps {
  idx: number;
  data: RTNode;
  type: 'start' | 'end';
  invalid: boolean;
  isInAction?: boolean;
  enableDetail: boolean;
  actionMap: {
    [type: string]: any;
  };
  onClick: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const ExpressionNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    idx,
    data: { value },
    invalid,
    actionMap,
    type,
    isInAction,
    onClick,
    onMouseUp,
    onNodeClick,
    enableDetail,
  } = props;

  const action = actionMap[value || ''];

  if (!actionMap || !action) return null;

  return type === 'start' ? (
    <div
      className={classNames('rt-node', 'rt-expression-start', { invalid, isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      {invalid ? (
        <Tooltip placement="bottom" title={t('当前选中的文本过短，表情展示效果不佳')}>
          <span className="rt-tag rt-tag-invalid" onClick={onNodeClick}>
            {action.actionName}
          </span>
        </Tooltip>
      ) : (
        <Tip
          className="action-tip"
          placement="bottomLeft"
          trigger={['hover']}
          overlay={<ActionPreview border mode="expression" data={action} className="normal" />}
        >
          <span className="rt-tag" onClick={onNodeClick}>
            {action.actionName}
          </span>
        </Tip>
      )}
      [
    </div>
  ) : (
    <div
      className={classNames('rt-node', 'rt-expression-end', { invalid, isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      ]
    </div>
  );
});

ExpressionNode.defaultProps = {
  idx: 0,
  data: {
    type: 'action',
    tag: '',
    value: '',
  },
  actionMap: {},
  onClick: () => {},
  onMouseUp: () => {},
  onNodeClick: () => {},
};

export default ExpressionNode;
