import React from 'react';
import classNames from 'classnames';
import { LoadingIcon } from 'tdesign-icons-react';

import './index.scss';

const Loading = (props: any) => {
  const { size, className } = props;

  return <LoadingIcon size={size} className={className} />;
};

Loading.defaultProps = {
  size: 16,
  className: '',
};

export default Loading;
