// import { MAKE_TYPE, MakeInfoState } from './types';

// 播报数智人redux
// Todo接入数据，可在state中mock数据调试
import {
  CHANGE_RESOURCE_LIST,
  CHANGE_RESOURCE_ITEM,
  CHANGE_IMAGE_CONFIG,
  CHANGE_ROLE_LIST,
  CLEAR_IMAGE_CONFIG,
  AUDITION_INIT,
  AUDITION_LOADING,
  AUDITION_PLAY,
  AUDITION_PAUSE,
  AUDITION_STOP,
  AUDITION_PLAYING_IDX_UPDATE,
  AUDITION_PLAY_QUEUE_UPDATE,
  AUDIO_PROCESS_UPDATE,
  CHANGE_VIDEO_RESOURCE,
  CHANGE_VIDEO_EDITOR_DATA,
  CLEAR_VIDEO_EDITOR_DATA,
} from './action_types';
import { ResourceFlatItem } from '../../pages/image_configure/types';
import {
  ImageConfig,
  RoleListInfo,
  DriverType,
  VideoResource,
  AuditionState,
  PlayState,
  VideoEditorDataStateType,
} from './types';

// 当前所选角色资源平铺
const resourceListState: ResourceFlatItem[] = [];

export const resourceList = (state = resourceListState, action: any) => {
  switch (action.type) {
    case CHANGE_RESOURCE_LIST: {
      return [...action.resourceList];
    }
    default:
      return state;
  }
};

// 当前所选数智人形象资源
const resourceItemState: ResourceFlatItem = {
  key: '',
  timbreCode: '',
  virtualmanResourceId: 0,
  colorPreviewUrl: '',
  clothesList: [],
  poseList: [],
  resolutionList: [],
  timbreList: [],
  modelSource: '',
  templateCode: '',
  clothesTemplates: [],
};

export const resourceItem = (state = resourceItemState, action: any) => {
  switch (action.type) {
    case CHANGE_RESOURCE_ITEM: {
      return { ...state, ...action.resource };
    }
    default:
      return state;
  }
};

// 音视频制作配置
const imageConfigState: ImageConfig = {
  ssml: '',
  originSsml: '',
  driverType: DriverType.TEXT,
  timbreCode: '',
  speedCode: '',
  imageTemplateId: 0,
  virtualmanTypeCode: '',
  virtualmanTypeV2: '',
  audioDriverUrl: '',
  anchorImage: {
    url: '',
    imagePosition: 0,
    imagePositionVertical: 0,
    imageZoom: 1,
    anchorMetadata: '',
    unrealEngineMetadata: '',
    sampleImageVideoUrl: '',
  },
  background: undefined,
  logos: [],
  angle: 0,
  imagePositionType: 'center',
  isPullFlowPreview: 0,
  showSubtitles: true,
  head: undefined,
  tail: undefined,
  speed: '1.0',
  volume: 0,
  emotionCategory: '',
  timbreLanguage: '',
  emotionIntensity: 50,
  platformSource: 0,
};

export const imageConfig = (state = imageConfigState, action: any) => {
  switch (action.type) {
    case CHANGE_IMAGE_CONFIG: {
      const computedData: Record<string, any> = {};
      if (action.config.ssml && !state.originSsml) {
        computedData.originSsml = action.config.ssml;
      }
      return { ...state, ...action.config, ...computedData };
    }
    case CLEAR_IMAGE_CONFIG: {
      return {
        ssml: '',
        originSsml: '',
        driverType: 'text',
        timbreCode: '',
        speedCode: '',
        imageTemplateId: 0,
        virtualmanTypeCode: '',
        virtualmanTypeV2: '',
        anchorImage: {
          url: '',
          imagePosition: 0,
          imagePositionVertical: 0,
          imageZoom: 1,
          anchorMetadata: '',
          unrealEngineMetadata: '',
          sampleImageVideoUrl: '',
        },
        background: undefined,
        logos: [],
        angle: 0,
        imagePositionType: 'center',
        isPullFlowPreview: 0,
        audioDriverUrl: '',
        showSubtitles: true,
        head: undefined,
        tail: undefined,
        speed: '1.0',
        volume: 0,
        emotionCategory: '',
        timbreLanguage: '',
        emotionIntensity: 0,
        platformSource: 0,
      };
    }
    default:
      return state;
  }
};

// 数智人角色列表
const roleListInfoState: RoleListInfo = {
  roleList: [],
  totalCount: 0,
  offset: 0,
};

export const roleListInfo = (state = roleListInfoState, action: any) => {
  switch (action.type) {
    case CHANGE_ROLE_LIST: {
      return {
        roleList: [...action.roleList],
        totalCount: action.totalCount || 0,
        offset: action.offset || 0,
      };
    }
    default:
      return state;
  }
};

const auditionState: AuditionState = {
  playState: PlayState.STOP, // 播放状态  standby(待机) loading(加载中) playing(播放中) pause(暂停) stop(停止)
  playQueue: [], // 播放列表
  playingIdx: -1, // 播放指针
  total: 0, // 总段数
  audioProcess: 0, // 播放进度
  stopToastMsg: '', // 停止弹窗消息

  globalSpeed: 1,
  ssmlList: [],
  timbre: '', // 音色
  timbreType: '', // 音色类型
  makeType: '', // 制作类型
  timbreUrl: '', // 试听音频url
};

export const auditionInfo = (state = auditionState, action: any) => {
  switch (action.type) {
    case AUDITION_INIT:
      return {
        ...state,
        playState: 'stop',
        playQueue: [],
        playingIdx: -1,
        total: 0,
        audioProcess: 0,
      };
    case AUDITION_LOADING: {
      const { ssmlList, globalSpeed, makeType = '', timbre, timbreType = '', timbreUrl = '' } = action;
      return {
        ...state,
        playState: 'loading',
        ssmlList,
        globalSpeed,
        makeType,
        timbre,
        timbreType,
        total: ssmlList.length,
        stopToastMsg: '',
        timbreUrl,
      };
    }
    case AUDITION_PLAY:
      return {
        ...state,
        playState: 'playing',
      };
    case AUDITION_PAUSE:
      return {
        ...state,
        playState: 'pause',
      };
    case AUDITION_STOP:
      return {
        ...state,
        playState: 'stop',
        playQueue: [],
        playingIdx: -1,
        total: 0,
        audioProcess: 0,
        ssmlList: [],
        timbre: '',
        timbreType: '',
        stopToastMsg: action.stopToastMsg || '',
        timbreUrl: '',
      };
    case AUDITION_PLAYING_IDX_UPDATE:
      return {
        ...state,
        playingIdx: action.playingIdx,
      };
    case AUDITION_PLAY_QUEUE_UPDATE:
      return {
        ...state,
        playQueue: action.playQueue,
      };
    case AUDIO_PROCESS_UPDATE:
      return {
        ...state,
        audioProcess: action.audioProcess,
      };
    default:
      return state;
  }
};

// 平台背景资源&用户资源
const videoResourceState: VideoResource = {
  defaultBg: undefined,
  backgroundList: [],
  logoList: [],
  headList: [],
  tailList: [],
};

export const videoResource = (state = videoResourceState, action: any) => {
  switch (action.type) {
    case CHANGE_VIDEO_RESOURCE: {
      return { ...state, ...action.data };
    }
    default:
      return state;
  }
};

const videoEditorDataState: VideoEditorDataStateType = {
  editorVisible: false,
  firstAndLastFrameResetSelected: false,
  videoTemplateType: 'Default',
  videoStartIndex: 0,
  videoEndIndex: 0,
  frames: 25, // 预览视频素材的帧数
  audioDuration: 0,
  audioUrl: '',
};
// 音视频制作编辑视频的数据
export const videoEditorData = (state = videoEditorDataState, action: any) => {
  switch (action.type) {
    case CHANGE_VIDEO_EDITOR_DATA: {
      return { ...state, ...action.data };
    }
    case CLEAR_VIDEO_EDITOR_DATA: {
      return { ...videoEditorDataState, ...action.data };
    }
    default:
      return state;
  }
};
