/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

import { Popup } from 'tdesign-react';
import { Spin } from 'antd';

import './action_proview_image.scss';

interface IProps {
  src: string;
  preview: boolean;
  children?: React.ReactNode;
  isLottie: boolean;
}

let animation: AnimationItem;

const ActionPreviewImage = (props: IProps) => {
  const { src, preview, children, isLottie } = props;
  const lottieRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    setDataReady(false);
  }, [src]);

  const onVisibleChange = (visible: boolean) => {
    if (!isLottie) return;
    if (!visible) {
      animation?.stop();
    } else if (dataReady && lottieRef.current?.children?.length) {
      // 拖动bug lottieRef.current?.children无
      animation.play();
    } else {
      initLottie(src);
    }
  };

  const initLottie = (path: string) => {
    console.log('animation data init');
    setDataReady(false);
    animation?.destroy?.();

    animation = lottie.loadAnimation({
      container: lottieRef?.current as HTMLElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path,
    });
    animation.addEventListener('data_ready', () => {
      setDataReady(true);
    });
    animation.addEventListener('data_failed', () => {
      console.log('animation data has data_failed');
      initLottie(path);
    });
    animation.addEventListener('error', (e) => {
      console.log('animation data has error', e);
      initLottie(path);
    });
  };

  const onMouseEnter = () => setVisible(true);

  const onMouseLeave = () => setVisible(false);

  return (
    <Popup
      showArrow
      placement="top"
      visible={visible && preview}
      overlayStyle={{ padding: 8 }}
      onVisibleChange={(e) => onVisibleChange(e)}
      content={
        <div className="ivh-action-preview">
          {isLottie ? (
            <Spin spinning={!dataReady}>
              <div className="ivh-action-preview-img" ref={lottieRef} />
            </Spin>
          ) : (
            <img className="ivh-action-preview-img" src={src} alt="" />
          )}
        </div>
      }
    >
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </div>
    </Popup>
  );
};

ActionPreviewImage.defaultProps = {
  src: '',
  preview: true,
};

export default ActionPreviewImage;
