const ConstEnum = {
  expressionMinTextLength: 1,
  expressionRecommendTextLength: 5,
  minUtf8TTSLength: 4,
};
// 泰米尔语元音数组
const tamilVowels = ['அ', 'ஆ', 'இ', 'ஈ', 'உ', 'ஊ', 'எ', 'ஏ', 'ஐ', 'ஒ', 'ஓ', 'ஔ'];
// 泰米尔语辅音数组
const tamilConsonants = [
  'க',
  'ங',
  'ச',
  'ஞ',
  'ட',
  'ண',
  'த',
  'ந',
  'ப',
  'ம',
  'ய',
  'ர',
  'ல',
  'வ',
  'ழ',
  'ற',
  'க்ஷ',
  'ஜ',
  'ன',
  // 补充
  'ன',
  'ள',
];
// 泰米尔语元音复合数组
const tamilFH = ['', 'ா', 'ி', 'ீ', 'ு', 'ூ', 'ெ', 'ே', 'ை', 'ொ', 'ோ', 'ௌ'];
// 复合音=元音+辅音 辅音不能单独存在
const tamilCombinations = [
  'க',
  'கா',
  'கி',
  'கீ',
  'கு',
  'கூ',
  'கெ',
  'கே',
  'கை',
  'கொ',
  'கோ',
  'கௌ',
  'ச',
  'சா',
  'சி',
  'சீ',
  'சு',
  'சூ',
  'செ',
  'சே',
  'சை',
  'சொ',
  'சோ',
  'சௌ',
  'ங',
  'ஙா',
  'ஙி',
  'ஙீ',
  'ஙு',
  'ஙூ',
  'ஙெ',
  'ஙே',
  'ஙை',
  'ஙொ',
  'ஙோ',
  'ஙௌ',
  'ஞ',
  'ஞா',
  'ஞி',
  'ஞீ',
  'ஞு',
  'ஞூ',
  'ஞெ',
  'ஞே',
  'ஞை',
  'ஞொ',
  'ஞோ',
  'ஞௌ',
  'ட',
  'டா',
  'டி',
  'டீ',
  'டு',
  'டூ',
  'டெ',
  'டே',
  'டை',
  'டொ',
  'டோ',
  'டௌ',
  'ண',
  'ணா',
  'ணி',
  'ணீ',
  'ணு',
  'ணூ',
  'ணெ',
  'ணே',
  'ணை',
  'ணொ',
  'ணோ',
  'ணௌ',
  'த',
  'தா',
  'தி',
  'தீ',
  'து',
  'தூ',
  'தெ',
  'தே',
  'தை',
  'தொ',
  'தோ',
  'தௌ',
  'ந',
  'நா',
  'நி',
  'நீ',
  'நு',
  'நூ',
  'நெ',
  'நே',
  'நை',
  'நொ',
  'நோ',
  'நௌ',
  'ப',
  'பா',
  'பி',
  'பீ',
  'பு',
  'பூ',
  'பெ',
  'பே',
  'பை',
  'பொ',
  'போ',
  'பௌ',
  'ம',
  'மா',
  'மி',
  'மீ',
  'மு',
  'மூ',
  'மெ',
  'மே',
  'மை',
  'மொ',
  'மோ',
  'மௌ',
  'ய',
  'யா',
  'யி',
  'யீ',
  'யு',
  'யூ',
  'யெ',
  'யே',
  'யை',
  'யொ',
  'யோ',
  'யௌ',
  'ர',
  'ரா',
  'ரி',
  'ரீ',
  'ரு',
  'ரூ',
  'ரெ',
  'ரே',
  'ரை',
  'ரொ',
  'ரோ',
  'ரௌ',
  'ல',
  'லா',
  'லி',
  'லீ',
  'லு',
  'லூ',
  'லெ',
  'லே',
  'லை',
  'லொ',
  'லோ',
  'லௌ',
  'வ',
  'வா',
  'வி',
  'வீ',
  'வு',
  'வூ',
  'வெ',
  'வே',
  'வை',
  'வொ',
  'வோ',
  'வௌ',
  'ழ',
  'ழா',
  'ழி',
  'ழீ',
  'ழு',
  'ழூ',
  'ழெ',
  'ழே',
  'ழை',
  'ழொ',
  'ழோ',
  'ழௌ',
  'ற',
  'றா',
  'றி',
  'றீ',
  'று',
  'றூ',
  'றெ',
  'றே',
  'றை',
  'றொ',
  'றோ',
  'றௌ',
  'க்ஷ',
  'க்ஷா',
  'க்ஷி',
  'க்ஷீ',
  'க்ஷு',
  'க்ஷூ',
  'க்ஷெ',
  'க்ஷே',
  'க்ஷை',
  'க்ஷொ',
  'க்ஷோ',
  'க்ஷௌ',
  'ஜ',
  'ஜா',
  'ஜி',
  'ஜீ',
  'ஜு',
  'ஜூ',
  'ஜெ',
  'ஜே',
  'ஜை',
  'ஜொ',
  'ஜோ',
  'ஜௌ',
  // 补充ன
  'ன',
  'னா',
  'னி',
  'னீ',
  'னு',
  'னூ',
  'னெ',
  'னே',
  'னை',
  'னொ',
  'னோ',
  'னௌ',
  // 补充ள
  'ளா',
  'ளி',
  'ளீ',
  'ளு',
  'ளூ',
  'ளெ',
  'ளே',
  'ளை',
  'ளொ',
  'ளோ',
  'ளௌ',
];
// 泰米尔语分词
export const splitTamilText = (text: string) => {
  const arr = [];
  let i = 0;
  while (i < text.length) {
    const currentChar = text[i];
    const d2 = currentChar + (text[i + 1] || '');
    const d3 = d2 + (text[i + 2] || '');

    if (tamilCombinations.includes(d3)) {
      arr.push(d3);
      i += 3; // 跳过三个字符
    } else if (tamilCombinations.includes(d2)) {
      arr.push(d2);
      i += 2; // 跳过两个字符
    } else {
      // 音节处理
      if (/[\u0BCD]{1}/g.test(currentChar)) {
        if (arr.length > 0) {
          arr[arr.length - 1] += currentChar; // 合并字符
        } else {
          arr.push(currentChar); // 防止 arr 为空时的错误
        }
        i += 1;
      } else {
        arr.push(currentChar);
        i += 1; // 只处理一个字符
      }
    }
  }
  return arr;
};
export default ConstEnum;
