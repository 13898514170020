import React from 'react';
import Tooltip from 'rc-tooltip';
import './tip.scss';

type ActionType = string;

interface IProps {
  placement: string;
  trigger?: ActionType | ActionType[];
  visible?: boolean;
  align?: any;
  overlay: React.ReactNode;
  className?: string;
  children: React.ReactElement;
  onVisibleChange?: (visible: boolean) => void;
  getPopupContainer?: () => HTMLElement | null;
}

const Tip = (props: IProps) => {
  const { placement, trigger, visible, overlay, className, align, onVisibleChange, ...extraProps } = props;
  return (
    <Tooltip
      placement={placement}
      trigger={trigger}
      overlay={overlay}
      overlayClassName={className}
      destroyTooltipOnHide
      transitionName="rc-tooltip-zoom"
      {...(visible !== null && {
        visible,
      })}
      align={align}
      arrowContent={null}
      onVisibleChange={onVisibleChange}
      {...extraProps}
    >
      {props.children}
    </Tooltip>
  );
};

Tip.defaultProps = {
  placement: 'bottom',
  trigger: ['hover'],
  visible: null,
  overlay: null,
  align: null,
  arrowColor: '#3f3f3f',
};

export default Tip;
