import i18n from 'utils/i18n';
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import clipboardCopy from 'copy-to-clipboard';
import lodashOmit from 'lodash/omit';
import { SearchOutlined } from '@ant-design/icons';
import { Space, Modal, Button, message, Tooltip, Switch, Spin, Select } from 'antd';
import { Dialog, Dropdown } from 'tdesign-react';
import { ErrorCircleFilledIcon, ChevronRightIcon, ChevronLeftIcon } from 'tdesign-icons-react';
import {
  Button as RichTextButton,
  OptionsBar,
  PauseNode,
  PolyphonicNode,
  ActionNode,
  Tip,
  ActionPane,
  ActionBar,
  SelectList,
  PreviewModal,
  ExpressionNode,
  AuditionPop,
  LocalSpeedNode,
  WordNode,
  SubInputModal,
  SubNode,
} from './component';
import { Row } from './component/SelectList';
import { Icon } from '../';
import './rich-text.scss';
import {
  ActionEnum,
  ActionMap,
  RTNode,
  TextLengthNode,
  CommonBar,
  SelectionInfo,
  InsertRange,
  InsertInfo,
  NumberEnum,
  PolyphonicEnum,
  NodeDom,
  UploadType,
  IProps,
  IState,
} from './types';

import utils from './utils';
import { ziyanSourceList } from '../../../pages/sentence_list/const';
import commonUtils from 'utils';
import ConstEnum, { splitTamilText } from './utils/const';
import {
  inputNode,
  textNode,
  pauseNode,
  polyphonicNode,
  numberNode,
  actionNode,
  wrapNode,
  expressionStartNode,
  expressionEndNode,
  localSpeedStart,
  localSpeedEnd,
  pauseEnum,
  numberDefaultEnum,
  dateEnum,
  timeEnum,
  numberMap,
  NODE_TEXT,
  NODE_PINYIN,
  NODE_PAUSE,
  NODE_NUMBER,
  NODE_ACTION,
  NODE_WRAP,
  NODE_EXPRESSION_START,
  NODE_EXPRESSION_END,
  LOCAL_SPEED_START,
  LOCAL_SPEED_END,
  NODE_WORD_START,
  NODE_WORD_END,
  NODE_SUB_START,
  NODE_SUB_END,
  textCountNodeTypes,
  textToNodeList,
  nodeListCheck,
  onTagFilter,
  selectPrevent,
  isActiveInput,
  ssmlToNodeList,
  nodeListToSsml,
  wordStartNode,
  wordEndNode,
  subStartNode,
  subEndNode,
  PARE_START_NODE_MAP,
  PARE_END_NODE_MAP,
  deleteEndByStart,
  deleteStartByEnd,
  filterSinglePareNode,
} from './utils/node';

import { getAnalyzedSsml } from 'apis/interfaces/sentence';
import icClear from './assets/ic_clear@2x.png';
import icClearDisabled from './assets/ic_clear_disabled@2x.png';
import icCopy from './assets/ic_copy_test@2x.png';
import icCopyDisabled from './assets/ic_copy_disabled@2x.png';
import { connect, useSelector } from 'react-redux';
import {
  auditionLoading,
  auditionPlay,
  auditionPause,
  auditionStop,
  changeImageConfig,
} from 'redux/bvh/action_creators';
import { AuditionState, ImageConfig } from 'redux/bvh/types';
import { xssHandlerProcess } from 'utils/const';
import speakIcon from './assets/speak.png';
import languageIcon from './assets/language.png';
const { t } = i18n;
const { expressionMinTextLength, expressionRecommendTextLength, minUtf8TTSLength } = ConstEnum;
const { confirm } = Modal;

class RichText extends Component<IProps, IState> {
  private static defaultProps = {
    headerStyle: {},
    status: 'init',
    maxSize: 150, // 最大字数
    valueVersion: '', // 值版本
    placeholder: '',
    config: {},
    listenEvent: true,
    getPolyphonic: () => {},
    onChange: () => {},
    onRef: () => {},
    enableDetail: false,
    settingVisible: true,
    switchControl: false,
    importText: false,
    globalSpeed: '1.0', // 全局语速
    enableAudition: false,
    anchorCode: '',
    enableSmartAction: true,
    textInputDisable: false,
    editSectionIndex: null,
    extraButtons: () => <></>,
    isMicrosoftTts: false,
  };

  private inputRef: HTMLSpanElement | null = null; // 光标ref
  private fileRef: HTMLInputElement | null = null; // 上传文件input
  private uploadType: UploadType = ''; // 上传文件input
  private isMount: Boolean = false;
  private headerRef: any = React.createRef();

  private nodeDomList: NodeDom[] = []; // 元素真实dom列表

  public constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      nodeList: [],
      polyphonicEnum: [],
      numberEnum: [],

      // bar相关
      pauseHide: false,
      pauseFocus: false,
      pauseBar: {
        show: false,
        value: '',
        idx: -1,
      },
      actionHide: false,
      actionFocus: false,
      actionBar: {
        show: false,
        idx: -1,
      },
      polyphonicBar: {
        show: false,
        value: '',
        idx: -1,
      },
      numberBar: {
        show: false,
        idx: -1,
        type: '',
      },
      speedHide: false,
      speedFocus: false,
      globalSpeed: '1.0', // 全局语速
      expressionHide: false, // 表情选择
      languageHide: false, // 语种选择
      languageSelectVisible: false,
      expressionFocus: false, // 表情标签选中
      expressionBar: {
        show: false,
        idx: -1,
      },
      wordBar: { show: false, idx: -1 },
      subBar: { show: false, idx: -1 },

      // 光标输入框相关
      inputIdx: -1, // 光标位置
      blurIdx: -1, // 光标失焦的位置,只保留200ms
      insertIdx: -1, // 待插入位置
      composition: false, // 输入法开启状态
      size: 0, // 字数
      isInputError: false, // 输入光标处错误状态
      inputErrorMsg: '', // 输入光标处错误信息

      // 选区相关
      selection: {
        startIdx: 0, // 起始idx
        endIdx: 0, // 终点idx
        selectionText: '', // 选中的文字
        startElement: null, // 起始元素
        endElement: null, // 重点元素
      },
      ctxMouseDown: false, // 文档鼠标按下检测
      inContext: false, // 是否在context中
      listenEvent: false,

      // 动作相关
      actionGroup: [],
      actionEnum: [],
      actionMap: {},
      actionPositionMap: {},
      disabledActionMap: {},
      maxCountMap: {},
      actionRules: {},
      noneTagEnum: [], // 不展示tag的enum
      nodeListInAction: [],
      smartActionLoading: false,

      // 弹窗相关
      partModal: {
        show: false,
        type: '',
      },
      preventModal: {
        show: false,
        type: '',
      },
      importModal: {
        show: false,
      },
      insertConfirmModal: {
        show: false,
      },
      subInputModal: {
        show: false,
        subStartIdx: 0,
        subEndIdx: 0,
        value: '',
      },

      // 插入相关
      insertInfo: {
        insertIdx: -1,
        insertRange: {
          startIdx: -1,
          endIdx: -1,
        },
        insertList: [],
      },

      previewTime: undefined,
      previewSsml: '',
      // 表情相关
      selectionInExpression: false, // 所选文本是否已绑定表情
      expressionEnum: [],
      expressionMap: {},
      expressionPositionMap: {},
      expressionStartIdx: -1,
      expressionEndIdx: -1,
      disabledExpressionMap: {},

      // 试听气泡
      auditionPop: {
        show: false,
        left: 0,
        top: 0,
        localSpeed: 0,
        placement: 'top',
      },
      mouseUpInfo: { x: 0, y: 0 },
      /* ------ 播放相关 ------*/
      sentenceSection: [],
      localSpeedBar: {},
      inputContextMenuVisible: false,
      // 预览
      previewLoading: false,
      // header滑动按钮
      headerScrollbtnVisible: false,
      headerScrollVal: 0,
      polyphonicChecked: false,
      currentEmotional: { code: '', name: '' },
      currentLanguage: { code: '', name: '' },
    };
  }

  public componentDidMount() {
    this.isMount = true;
    this.props.onRef(this);
    // 点击鼠标，记录是否在编辑器内
    document.addEventListener('mousedown', this.isInContext.bind(this));
    // 移出富文本，取消选区
    document.addEventListener('mouseup', this.moveOutCancel.bind(this));

    document.addEventListener('keydown', this.onDocumentKeyDown);
    document.querySelector('.rt-context')?.addEventListener('keydown', this.onCtxKeyDown);
    document.addEventListener('scroll', this.onCtxScroll);
    // 选中监听
    document.addEventListener('selectstart', selectPrevent);
    this.updateHeaderRefScoll();
    const { actionGroup, expressionGroups } = this.props;
    if (actionGroup.length > 0) {
      const actionMap: ActionMap = {}; // 动作Map {ACTION1: {...}}
      const actionPositionMap: { [actionId: string]: [number, number] } = {}; // 动作分页Map {ACTION1: [0, 0]}
      const actionEnum: ActionEnum[] = [];
      const actionRules: { [key: string]: number } = {};
      const maxCountMap: { [key: string]: number } = {};
      if (actionGroup?.length > 0) {
        actionGroup.forEach((group: any, tabIdx: any) => {
          const actionList: any[][] = [];
          group.actionList.forEach((action: any, idx: number) => {
            const { actionId } = action;
            actionMap[actionId] = action;
            const swiperIdx = Math.floor(idx / 6);
            actionPositionMap[actionId] = [tabIdx, swiperIdx];
            if (!actionList[swiperIdx]) actionList[swiperIdx] = [];
            actionList[swiperIdx].push(action);
          });
          actionEnum.push({
            ...group,
            actionList,
          });
        });
        // 切换主播删除动作
        // if (!valueVersionChange) nodeList = onTagFilter(nodeList, ['action']); ******
      }
      this.setState({
        actionEnum,
        actionMap,
        actionPositionMap,
        actionRules,
        maxCountMap,
      });
    }
    if (expressionGroups.length > 0) {
      const expressionMap: ActionMap = {};
      const expressionPositionMap: { [actionId: string]: [number, number] } = {}; // 动作分页Map {ACTION1: [0, 0]}
      const expressionEnum: ActionEnum[] = [];

      expressionGroups.forEach((group: any, tabIdx: any) => {
        const actionList: any[][] = [];
        group.actionList.forEach((action: any, idx: number) => {
          const { actionId } = action;
          expressionMap[actionId] = action;
          const swiperIdx = Math.floor(idx / 6);
          expressionPositionMap[actionId] = [tabIdx, swiperIdx];
          if (!actionList[swiperIdx]) actionList[swiperIdx] = [];
          actionList[swiperIdx].push(action);
        });
        expressionEnum.push({
          ...group,
          actionList,
        });
      });

      this.setStateSync({
        expressionEnum,
        expressionMap,
        expressionPositionMap,
      });
    }
  }

  public componentWillUnmount() {
    this.isMount = false;
    document.removeEventListener('mousedown', this.isInContext.bind(this));
    document.removeEventListener('mouseup', this.moveOutCancel.bind(this));
    document.removeEventListener('keydown', this.onDocumentKeyDown);
    document.querySelector('.rt-context')?.removeEventListener('keydown', this.onCtxKeyDown);
    document.removeEventListener('scroll', this.onCtxScroll);
    document.removeEventListener('selectstart', selectPrevent);
    // this.setState = () => {};
  }

  // 在setState操作前进行检查
  setState<K extends keyof IState>(state: Pick<IState, K> | IState, callback?: () => void) {
    if (this.isMount) {
      super.setState(state, callback);
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const {
      value,
      listenEvent,
      status,
      valueVersion,
      actionGroup,
      actionInsertRules,
      config,
      modelSource,
      expressionGroups,
      auditionInfo,
      maxSize,
      curSections,
      imageConfig: { emotionCategory, timbreLanguage, emotionalStyles, supportLanguages },
    } = this.props;
    const valueVersionChange = prevProps.valueVersion !== valueVersion;
    let { nodeList } = this.state;
    let { globalSpeed } = this.props;

    // 初始化，将ssml转为nodeList
    if ((prevProps.status === 'loading' && status === 'success') || valueVersionChange) {
      // const defaultSpeed = makeType === '2d-cartoon' ? '1.1' : '1.0';
      const defaultSpeed = '1.0';
      const ssmlNodeListInfo = ssmlToNodeList(value, defaultSpeed, curSections);
      // eslint-disable-next-line prefer-destructuring
      nodeList = ssmlNodeListInfo[0];
      // eslint-disable-next-line prefer-destructuring
      globalSpeed = ssmlNodeListInfo[2];
      const size = ssmlNodeListInfo[1];
      if (!valueVersionChange) nodeList = nodeListCheck(nodeList);
      this.setStateSync({ nodeList, size, globalSpeed }).then(() => {
        this.props.onChange(
          value ? nodeListToSsml(nodeList, true, globalSpeed || '1.0') : value,
          globalSpeed,
          false,
          true,
        );
        if (this.props.isMicrosoftTts) {
          this.setState({ polyphonicChecked: false });
        }
        // this.triggerSectionChange();
      });
    }

    // 超长文本截断
    const { size } = this.state;
    if (size > maxSize && globalSpeed) {
      nodeList = this.listSplice(nodeList, maxSize);
      utils
        .setStateSync(this, {
          nodeList,
          size: maxSize,
        })
        .then(() => {
          this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed || '1.0'), globalSpeed);
          this.triggerSectionChange();
        });
    }

    const condition = JSON.stringify(prevProps.actionGroup) !== JSON.stringify(actionGroup);
    // 表情初始化
    const isExpressionChanged = JSON.stringify(prevProps.expressionGroups) !== JSON.stringify(expressionGroups);
    /*
      TODO 这里之前需要判断status === 'success'，但是添加之后video-make中动作初始化失败。
      因为actionRules变化时，status === 'loading',现将其删除，不知道会出什么bug。
      在video-make中，status === loading 时actionRules改变。
      在template中，status === success时，actionRules改变，并且在切换模板时，actionRules改变
    */
    // 动作初始化
    if (condition) {
      const { globalSpeed } = this.state;
      const actionMap: ActionMap = {}; // 动作Map {ACTION1: {...}}
      const actionPositionMap: { [actionId: string]: [number, number] } = {}; // 动作分页Map {ACTION1: [0, 0]}
      const actionEnum: ActionEnum[] = [];
      const actionRules: { [key: string]: number } = {};
      const maxCountMap: { [key: string]: number } = {};
      if (actionGroup?.length > 0) {
        actionGroup.forEach((group: any, tabIdx: any) => {
          const actionList: any[][] = [];
          group.actionList.forEach((action: any, idx: number) => {
            const { actionId } = action;
            actionMap[actionId] = action;
            const swiperIdx = Math.floor(idx / 6);
            actionPositionMap[actionId] = [tabIdx, swiperIdx];
            if (!actionList[swiperIdx]) actionList[swiperIdx] = [];
            actionList[swiperIdx].push(action);
          });
          actionEnum.push({
            ...group,
            actionList,
          });
        });
        if ((modelSource && !ziyanSourceList.includes(modelSource)) || config?.actionTts === 'none') {
          // 规则数据更改
          (actionInsertRules as { [key: number]: { [k: string]: string } }[]).forEach((rule) => {
            const duration = Object.keys(rule)[0];
            const speedMap: { [k: string]: string } = Object.values(rule)[0];
            Object.keys(speedMap).forEach((speed) => {
              const count = parseInt(speedMap[speed], 10);
              actionRules[`${duration}-${speed}`] = count;
              maxCountMap[speed] = Math.max(count, maxCountMap[speed] || 0);
            });
          });
        }

        // 切换主播删除动作
        // if (!valueVersionChange) nodeList = onTagFilter(nodeList, ['action']); ******
      }
      this.setStateSync({
        nodeList,
        actionEnum,
        actionMap,
        actionPositionMap,
        actionRules,
        maxCountMap,
        actionGroup,
      }).then(() => {
        const nodeListInAction = this.getNodeListInAction(nodeList);
        // console.log('nodeListInAction', nodeListInAction);
        this.setState({ nodeListInAction });
        this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
        this.triggerSectionChange();
      });
    }

    if (isExpressionChanged) {
      const { globalSpeed } = this.state;
      const expressionMap: ActionMap = {};
      const expressionPositionMap: { [actionId: string]: [number, number] } = {}; // 动作分页Map {ACTION1: [0, 0]}
      const expressionEnum: ActionEnum[] = [];

      expressionGroups.forEach((group: any, tabIdx: any) => {
        const actionList: any[][] = [];
        group.actionList.forEach((action: any, idx: number) => {
          const { actionId } = action;
          expressionMap[actionId] = action;
          const swiperIdx = Math.floor(idx / 6);
          expressionPositionMap[actionId] = [tabIdx, swiperIdx];
          if (!actionList[swiperIdx]) actionList[swiperIdx] = [];
          actionList[swiperIdx].push(action);
        });
        expressionEnum.push({
          ...group,
          actionList,
        });
      });

      this.setStateSync({
        nodeList,
        expressionEnum,
        expressionMap,
        expressionPositionMap,
      }).then(() => {
        this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
        this.triggerSectionChange();
        this.updateHeaderRefScoll();
      });
    }

    if (JSON.stringify(prevProps.config) !== JSON.stringify(config) && status === 'success') {
      let { nodeList } = this.state;
      const { globalSpeed } = this.state;
      const noneTagEnum: string[] = [];
      Object.keys(config).forEach((key) => {
        if (config[key] === 'none') noneTagEnum.push(key);
      });
      if (!valueVersionChange) {
        nodeList = onTagFilter(nodeList, noneTagEnum);
      }
      this.setStateSync({
        nodeList,
        noneTagEnum,
        config,
      }).then(() => {
        this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
        this.triggerSectionChange();
      });
    }

    // 监听选中问题
    if (prevProps.listenEvent !== listenEvent) {
      if (listenEvent) addEventListener('selectstart', selectPrevent);
      else removeEventListener('selectstart', selectPrevent);
      this.setState({ listenEvent });
    }
    if (valueVersionChange && auditionInfo.playState === 'playing') {
      message.warn(t('试听已停止'));
      auditionStop('');
    }

    if (
      prevProps.value !== value ||
      JSON.stringify(prevProps.actionInsertRules) !== JSON.stringify(actionInsertRules)
    ) {
      // 文本变化或tts分句结果变化后刷新动作状态
      const nodeListInAction = this.getNodeListInAction(nodeList);
      // console.log('nodeListInAction', nodeListInAction);
      this.setState({ nodeListInAction });
    }

    if (prevProps.imageConfig.emotionCategory !== emotionCategory || !this.state.currentEmotional.code) {
      const currentEmotional = emotionalStyles?.find((item) => item.code === emotionCategory);
      emotionalStyles?.length &&
        this.setState(currentEmotional ? { currentEmotional } : { currentEmotional: emotionalStyles[0] });
    }

    if (prevProps.imageConfig.timbreLanguage !== timbreLanguage || !this.state.currentLanguage.code) {
      const currentLanguage = supportLanguages?.find((item) => item.code === timbreLanguage);
      supportLanguages?.length &&
        this.setState(currentLanguage ? { currentLanguage } : { currentLanguage: supportLanguages[0] });
    }
  }

  private polyphonicCheckChange(checked: boolean) {
    this.setState({
      polyphonicChecked: checked,
    });
    const nodeList = [...this.state.nodeList];
    const { globalSpeed } = this.state;
    if (checked) {
      this.globalPolyphonicCheck();
    } else {
      for (let i = nodeList.length - 1; i >= 0; i--) {
        const { type, text = '' } = nodeList[i];
        if (type === 'polyphonic') {
          nodeList.splice(i, 1, textNode(text));
        }
      }
      this.setState({
        nodeList,
      });
      this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
      this.triggerSectionChange();
    }
  }

  public render() {
    const {
      headerStyle,
      maxSize,
      status,
      placeholder,
      config,
      actionGroup = [],
      resolutionValue = '',
      modelSource = '',
      speedList,
      showPreview = false,
      expressionGroups,
      actionInsertRules = [],
      enableDetail,
      settingVisible,
      switchControl,
      importText,
      auditionInfo: { playState, playingIdx },
      enableSmartAction,
      extraButtons,
      editSectionIndex,
      isMicrosoftTts,
      imageConfig: { emotionalStyles, supportLanguages, timbreLanguage },
      isBroadcast = false,
    } = this.props;
    const {
      nodeList,
      inputIdx,
      blurIdx,
      insertIdx,
      composition,
      size,
      selection: { selectionText },

      pauseHide,
      pauseFocus,
      actionHide,
      actionFocus,
      speedHide,
      speedFocus,
      globalSpeed,

      polyphonicEnum,
      numberEnum,

      pauseBar,
      polyphonicBar,
      numberBar,
      actionBar,

      partModal,
      preventModal,
      subInputModal,

      disabledActionMap,
      actionEnum,
      actionMap,
      actionPositionMap,
      previewTime,
      previewSsml,
      expressionFocus,
      expressionHide,
      languageHide,
      languageSelectVisible,
      selectionInExpression,
      expressionEnum,
      expressionPositionMap,
      expressionMap,
      expressionBar,
      disabledExpressionMap,
      isInputError,
      inputErrorMsg,
      importModal,
      insertConfirmModal,
      auditionPop,
      localSpeedBar,
      sentenceSection,
      nodeListInAction,
      previewLoading,
      smartActionLoading,
      headerScrollbtnVisible,
      headerScrollVal,
      wordBar,
      subBar,
      polyphonicChecked,
    } = this.state;

    const focus = inputIdx > -1;
    // 试听内容按播放highlight
    // const [startIdx, endIdx] = sentenceSection[playingIdx] || [-1, -1];
    // 试听内容全部highlight
    const startIdx = (sentenceSection[playingIdx] && sentenceSection?.[0]?.[0]) || -1;
    const endIdx = (sentenceSection[playingIdx] && sentenceSection?.[sentenceSection?.length - 1]?.[1]) || -1;
    const nodeListLen = nodeList.length;
    const buttonDisabled = status !== 'success';
    const noText = nodeListLen === 0 || (nodeListLen === 1 && focus);
    const { action, pause, polyphonic, speed, word, sub } = config;
    // console.error('inputIdx: ', inputIdx, 'blurIdx: ', blurIdx, 'insertIdx: ', insertIdx, 'selection: ', selection);

    const [previewResolutionX, previewResolutionY] = resolutionValue?.split('x') || [];
    const limitWidth = (800 * +previewResolutionX) / +previewResolutionY; // height最多算800，防止视频高度过大
    const previewWidth = Math.min(+previewResolutionX, limitWidth);

    // const nodeListInAction = this.getNodeListInAction(nodeList);
    return (
      <div id="rich-text" className="rich-text" onContextMenu={this.onContextMenu.bind(this)}>
        {/* ------ 头部按钮 ------*/}
        <div style={headerStyle} className="rt-header">
          <div ref={this.headerRef} id="rtHeaderBtnConyainer" className="rt-header-btn-container">
            <Space size={10} style={{ transform: `translateX(${headerScrollVal}px`, transition: 'all 0.3s' }}>
              {/* ------ 停顿 ------ */}
              {pause !== 'none' && (
                <Fragment>
                  <Tip
                    placement="bottomLeft"
                    trigger="click"
                    {...(pauseHide && { visible: false })}
                    onVisibleChange={this.onVisibleChange.bind(this, 'pause')}
                    overlay={<SelectList data={pauseEnum} onSelect={this.addPause.bind(this)} />}
                  >
                    <RichTextButton
                      icon={<Icon name="pause" size={16} />}
                      focus={pauseFocus}
                      disabled={(inputIdx < 0 && blurIdx < 0 && !pauseFocus) || isMicrosoftTts}
                      tooltip={t('编辑文本播报的停顿时长')}
                      onClick={this.pauseHandle.bind(this)}
                    >
                      {t('插入停顿')}
                    </RichTextButton>
                  </Tip>
                </Fragment>
              )}
              {/* 表情 */}
              {!!expressionGroups.length && (
                <Fragment>
                  <Tip
                    placement="bottom"
                    trigger="click"
                    {...(expressionHide && { visible: false })}
                    onVisibleChange={this.onVisibleChange.bind(this, 'expression')}
                    overlay={
                      <ActionPane
                        actionEnum={expressionEnum}
                        actionPositionMap={expressionPositionMap}
                        mode="expression"
                        onSelect={this.addExpression.bind(this)}
                      />
                    }
                  >
                    <RichTextButton
                      icon={<Icon name="expression" size={11} />}
                      focus={expressionFocus}
                      tooltip={t('为保证表情持续效果，建议至少选中连续5个字插入表情')}
                      onClick={this.expressionPaneHandle.bind(this)}
                    >
                      {t('插入表情')}
                    </RichTextButton>
                  </Tip>
                </Fragment>
              )}
              {/* ------ 动作 ------ */}
              {action !== 'none' && (
                <Fragment>
                  <Tip
                    placement="bottom"
                    trigger="click"
                    {...(actionHide && { visible: false })}
                    onVisibleChange={this.onVisibleChange.bind(this, 'action')}
                    overlay={
                      <ActionPane
                        actionEnum={actionEnum}
                        disabledActionMap={disabledActionMap}
                        actionPositionMap={actionPositionMap}
                        onSelect={this.addAction.bind(this)}
                      />
                    }
                  >
                    <RichTextButton
                      icon={<Icon name="action" size={12} />}
                      focus={actionFocus}
                      disabled={
                        (inputIdx < 0 && blurIdx < 0 && !actionFocus) ||
                        actionGroup.length === 0 ||
                        !actionInsertRules.length
                      }
                      tooltip={t('光标点击文本可插入动作')}
                      onMouseOut={() => this.setState({ isInputError: false })}
                      onClick={this.actionPaneHandle.bind(this)}
                    >
                      {t('插入动作')}
                    </RichTextButton>
                  </Tip>
                  {this.props.virtualmanTypeCode && this.props.virtualmanTypeCode.indexOf('3d') > -1 && (
                    <RichTextButton
                      icon={<Icon name={smartActionLoading ? 'audio-loading' : 'action'} size={12} />}
                      disabled={buttonDisabled || noText || actionGroup.length === 0}
                      tooltip={t('根据文本内容自动插入匹配的动作')}
                      onClick={() => this.analyzeSsml()}
                    >
                      {t('智能动作')}
                    </RichTextButton>
                  )}
                </Fragment>
              )}
              {/* ------ 语速 ------ */}
              {speed !== 'none' && !this.props.textInputDisable && (
                <Fragment>
                  <Tip
                    placement="bottom"
                    trigger="click"
                    {...(speedHide ? { visible: false } : {})}
                    onVisibleChange={this.onVisibleChange.bind(this, 'speed')}
                    overlay={
                      <div className="speed-list">
                        <div className="title">
                          {config?.type === 'bvh' ? (
                            <>
                              <p>
                                {t(
                                  '1.本处可调整全局语速，设置了部分语速的文本以部分语速为准，未设置部分语速的文本以全局语速为准',
                                )}
                              </p>
                              <p>{t('2. 选中一段文本后，点击部分语速调节按钮可对指定文本进行单独语速设定')}</p>
                            </>
                          ) : (
                            t('仅改变该回复语语速，不影响数智人整体语速')
                          )}
                        </div>
                        <Space size={10} wrap>
                          {speedList?.map((item) => (
                            <div
                              key={item.dictCode}
                              className={classNames('speed-item', { active: globalSpeed === item.dictValue })}
                              onClick={this.onSpeedChange.bind(this, item.dictValue)}
                            >
                              {item.dictName}
                            </div>
                          ))}
                        </Space>
                      </div>
                    }
                  >
                    <RichTextButton
                      icon={<Icon name="speed" size={16} />}
                      focus={speedFocus}
                      disabled={buttonDisabled || noText || isMicrosoftTts}
                      tooltip={t('设置全文播报语速')}
                    >
                      {globalSpeed === '1.0' ? t('语速设置') : `${t('语速')}${globalSpeed}x`}
                    </RichTextButton>
                  </Tip>
                </Fragment>
              )}
              {/* 连读 */}
              {word !== 'none' && (
                <Fragment>
                  <RichTextButton
                    icon={<Icon name="word" size={16} />}
                    disabled={buttonDisabled || isMicrosoftTts}
                    tooltip={t('支持独立词汇的连读')}
                    onClick={this.addWord.bind(this)}
                  >
                    {t('连续')}
                  </RichTextButton>
                </Fragment>
              )}
              {/* ------ 多音字 ------ */}
              {polyphonic !== 'none' &&
                (switchControl ? (
                  <div className="switch-btn-control">
                    <RichTextButton
                      icon={<Icon name="pinyin" size={16} />}
                      className="swicth-control-button"
                      disabled={buttonDisabled || noText || isMicrosoftTts}
                      tooltip={t('点击可显示全文可编辑多音字')}
                    >
                      {t('多音字检测')}
                    </RichTextButton>
                    <Switch
                      disabled={buttonDisabled || noText || isMicrosoftTts}
                      onChange={this.polyphonicCheckChange.bind(this)}
                      checked={polyphonicChecked}
                      size="small"
                      checkedChildren={t('开')}
                      unCheckedChildren={t('关')}
                    />
                  </div>
                ) : (
                  <RichTextButton
                    disabled={buttonDisabled || noText || isMicrosoftTts}
                    icon={<Icon name="pinyin" size={16} />}
                    tooltip={t('点击可显示全文可编辑多音字')}
                    onClick={() => this.globalPolyphonicCheck()}
                  >
                    {t('多音字检测')}
                  </RichTextButton>
                ))}
              {/* 替换文本 字幕实际显示发音文本问题，解决后才能放开 */}
              {sub !== 'none' && (
                <Fragment>
                  <RichTextButton
                    icon={<Icon name="sub" size={16} />}
                    disabled={buttonDisabled || isMicrosoftTts}
                    tooltip={t('可以自定义选中文本的读音')}
                    onClick={this.showSubModal.bind(this, '')}
                  >
                    {t('替换文本')}
                  </RichTextButton>
                </Fragment>
              )}
              {emotionalStyles && emotionalStyles.length > 0 && isBroadcast && (
                <Dropdown
                  minColumnWidth={100}
                  direction="right"
                  hideAfterItemClick
                  options={emotionalStyles.map((item) => ({
                    content: item.name,
                    value: item.code,
                    active: this.state.currentEmotional.code === item.code,
                  }))}
                  placement="bottom-left"
                  trigger="hover"
                  onClick={(dropdownItem: any) => {
                    this.setState({
                      currentEmotional: {
                        code: dropdownItem.value,
                        name: dropdownItem.content,
                      },
                    });
                    changeImageConfig({ emotionCategory: dropdownItem.value });
                  }}
                >
                  <Fragment>
                    <RichTextButton icon={<img src={speakIcon} />}>
                      {t('{{l}}', {
                        l: this.state.currentEmotional.name ? this.state.currentEmotional.name : t('情感'),
                      })}
                    </RichTextButton>
                  </Fragment>
                </Dropdown>
              )}
              {supportLanguages && supportLanguages.length > 0 && isBroadcast && (
                <Tip
                  placement="bottom"
                  trigger="click"
                  visible={languageHide}
                  onVisibleChange={() => {
                    this.setState({
                      languageHide: !this.state.languageHide,
                      languageSelectVisible: false,
                    });
                  }}
                  overlay={
                    <div style={{ width: 140 }}>
                      <Select
                        placeholder={t('请选择')}
                        style={{ width: 140 }}
                        open={languageSelectVisible}
                        showSearch
                        suffixIcon={<SearchOutlined />}
                        options={supportLanguages.map((item) => ({
                          label: item.name,
                          value: item.code,
                        }))}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        virtual={false}
                        value={timbreLanguage}
                        onChange={(value) => {
                          this.setState({
                            languageHide: false,
                            languageSelectVisible: false,
                          });
                          changeImageConfig({ timbreLanguage: value });
                        }}
                      />
                    </div>
                  }
                >
                  <Fragment>
                    <RichTextButton
                      onClick={() => {
                        this.setState({
                          languageHide: !this.state.languageHide,
                        });
                        setTimeout(() => {
                          this.setState({
                            languageSelectVisible: true,
                          });
                          const activeItem = document.querySelector('.idh-select-item-option-selected');
                          if (activeItem) {
                            activeItem.scrollIntoView();
                          }
                        });
                      }}
                      icon={<img src={speakIcon} />}
                    >
                      {t('语言')}
                    </RichTextButton>
                  </Fragment>
                </Tip>
              )}
              {importText && (
                <RichTextButton
                  icon={<Icon name="text-import" size={12} />}
                  disabled={buttonDisabled}
                  onClick={this.importModalHandle.bind(this, true, blurIdx)}
                >
                  {t('导入文本')}
                </RichTextButton>
              )}
              {extraButtons && extraButtons(noText)}
            </Space>
          </div>
          {!!showPreview && (
            <RichTextButton className="rt-preview" onClick={this.preview} disabled={size === 0 || previewLoading}>
              {t('预览')}
            </RichTextButton>
          )}
          {!!headerScrollbtnVisible && !headerScrollVal && (
            <div className="rt-header-scroll-btn right" onClick={this.onScrollHeader.bind(this, 'right')}>
              <ChevronRightIcon />
            </div>
          )}
          {!!headerScrollbtnVisible && headerScrollVal < 0 && (
            <div className="rt-header-scroll-btn left" onClick={this.onScrollHeader.bind(this, 'left')}>
              <ChevronLeftIcon />
            </div>
          )}
        </div>
        {/* ------ 滚动 ------*/}
        <div className="rt-content">
          <div
            className="rt-context"
            id="rt-context"
            onScroll={this.onCtxScroll.bind(this)}
            onMouseDown={this.onCtxMouseDown.bind(this)}
            onMouseUp={this.onCtxMouseUp.bind(this)}
            onCopy={this.onCtxCopy.bind(this)}
            onCut={this.onCtxCut.bind(this)}
            onPaste={this.onCtxPaste.bind(this)}
          >
            {nodeList.map((rtNode, idx) => {
              const { key, type, tag = '', text = '', value = '' } = rtNode;
              const isInAction = !!nodeListInAction.find(([start, end]) => {
                if (inputIdx !== -1 && idx > inputIdx) {
                  return idx >= start + 1 && idx <= end + 1;
                }
                return idx >= start && idx <= end;
              });
              const active = idx >= startIdx && idx <= endIdx;
              switch (type) {
                case 'text':
                  return (
                    <b
                      key={key}
                      className={classNames('rt-node', 'rt-text', {
                        isInAction,
                        active,
                        'local-speed': !!rtNode.localSpeed,
                        'is-editing':
                          editSectionIndex !== null &&
                          editSectionIndex !== undefined &&
                          rtNode.sectionIndex === editSectionIndex,
                      })}
                      data-idx={idx}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      dangerouslySetInnerHTML={{
                        __html: [' ', ' '].includes(text) ? '&nbsp;' : xssHandlerProcess.process(text),
                      }}
                    />
                  );
                case 'wrap':
                  return (
                    <br
                      key={key}
                      className="rt-node rt-wrap"
                      data-idx={idx}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                    />
                  );
                case 'pause': {
                  const pauseInvalid = this.pauseIsInvalid(nodeList, idx, true);
                  return (
                    <PauseNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={pauseInvalid}
                      isInAction={isInAction}
                      enableDetail={!!enableDetail}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.pauseNodeClick.bind(this, idx)}
                    />
                  );
                }
                case 'polyphonic':
                  return (
                    <PolyphonicNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      enableDetail={!!enableDetail || config?.type === 'ivh'}
                      isInAction={isInAction}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.polyphonicNodeClick.bind(this, idx, false)}
                    />
                  );
                case 'number':
                  return (
                    <PolyphonicNode
                      enableDetail={!!enableDetail || config?.type === 'ivh'}
                      key={key}
                      idx={idx}
                      isInAction={isInAction}
                      data={{ ...rtNode, tag: numberMap[tag] }}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.numberNodeClick.bind(this, { idx, tag })}
                    />
                  );
                case 'action': {
                  const actionInvalid = this.actionIsInvalid(nodeList, idx);
                  // todo待修复
                  // let isExist = true;
                  // const key = nodeList[idx].value;
                  // if (actionMap && key) {
                  //   isExist = !!actionMap[value];
                  // } else {
                  //   isExist = false;
                  // }
                  // if (!isExist) {
                  //   nodeList.splice(idx, 1);
                  //   this.setState({ nodeList });
                  //   this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
                  // }
                  return (
                    <ActionNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={actionInvalid}
                      enableDetail={!!enableDetail}
                      actionMap={actionMap || {}}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.actionNodeClick.bind(this, { idx, value })}
                    />
                  );
                }
                case 'input':
                  return (
                    <Tip
                      key="inputContextMenu"
                      placement="bottomLeft"
                      trigger="contextMenu"
                      visible={this.state.inputContextMenuVisible}
                      onVisibleChange={(val) => this.setState({ inputContextMenuVisible: val })}
                      overlay={
                        <div className="input-context-menu" onMouseDown={this.onInputMenuPaste.bind(this)}>
                          {t('粘贴')}
                        </div>
                      }
                    >
                      <Tooltip
                        key="input"
                        title={inputErrorMsg}
                        visible={isInputError}
                        placement="bottom"
                        color="#E34D59"
                      >
                        <b className={classNames('rt-node rt-input', { composition, isInputError })}>
                          <span
                            id="rt-input"
                            className="input"
                            contentEditable
                            suppressContentEditableWarning
                            ref={(node) => (this.inputRef = node)}
                            onInput={this.onInputChange.bind(this)}
                            onBlur={this.onInputBlur.bind(this)}
                            onPaste={this.onInputPaste.bind(this)}
                            onKeyDown={this.onCursorKeyDown.bind(this)}
                            onCompositionStart={this.onInputCompositionStart.bind(this)}
                            onCompositionEnd={this.onInputCompositionEnd.bind(this)}
                            dangerouslySetInnerHTML={{ __html: '' }}
                          />
                        </b>
                      </Tooltip>
                    </Tip>
                  );
                case NODE_EXPRESSION_START: {
                  const expressionInvild = this.isExpressionInvalid(idx, NODE_EXPRESSION_START);
                  return (
                    <ExpressionNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={expressionInvild}
                      isInAction={isInAction}
                      enableDetail={!!enableDetail}
                      actionMap={expressionMap || {}}
                      type="start"
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.expressionNodeClick.bind(this, { idx, value })}
                    />
                  );
                }
                case NODE_EXPRESSION_END: {
                  const expressionInvild = this.isExpressionInvalid(idx, NODE_EXPRESSION_END);
                  return (
                    <ExpressionNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      enableDetail={!!enableDetail}
                      invalid={expressionInvild}
                      isInAction={isInAction}
                      actionMap={expressionMap || {}}
                      type="end"
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.expressionNodeClick.bind(this, { idx, value })}
                    />
                  );
                }
                case 'localSpeedStart':
                  return (
                    <LocalSpeedNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      type="start"
                      enableDetail={!!enableDetail}
                      isInAction={isInAction}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                    />
                  );
                case 'localSpeedEnd':
                  return (
                    <LocalSpeedNode
                      key={key}
                      idx={idx}
                      enableDetail={!!enableDetail}
                      data={{
                        ...rtNode,
                        value: ((rtNode as any).value / 100).toString(),
                        tag: ((rtNode as any).tag / 100).toString(),
                      }}
                      type="end"
                      isInAction={isInAction}
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.localSpeedNodeClick.bind(this, idx)}
                    />
                  );
                case NODE_WORD_START:
                  return (
                    <WordNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={word === 'disabled'}
                      isInAction={isInAction}
                      type="start"
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                    />
                  );
                case NODE_WORD_END:
                  return (
                    <WordNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={word === 'disabled'}
                      isInAction={isInAction}
                      type="end"
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.wordNodeClick.bind(this, idx)}
                    />
                  );
                case NODE_SUB_START:
                  return (
                    <SubNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={false}
                      isInAction={isInAction}
                      type="start"
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                    />
                  );
                case NODE_SUB_END:
                  return (
                    <SubNode
                      key={key}
                      idx={idx}
                      data={rtNode}
                      invalid={false}
                      isInAction={isInAction}
                      type="end"
                      ref={(node) => (this.nodeDomList[idx] = node)}
                      onClick={this.cursorInsert.bind(this, idx)}
                      onMouseDown={this.onSelectStart.bind(this)}
                      onMouseUp={this.onSelectEnd.bind(this)}
                      onNodeClick={this.subNodeClick.bind(this, { idx, value })}
                    />
                  );
                default:
                  return null;
              }
            })}

            {/* ------ placeholder ------*/}
            {(nodeListLen === 0 || (nodeListLen === 1 && focus)) && (
              <span
                className="rt-placeholder"
                onMouseDown={(e) => e.preventDefault()}
                onClick={this.focusCursor.bind(this)}
              >
                {placeholder}
              </span>
            )}
          </div>

          <div className="rt-setting-row">
            {settingVisible && (
              <div className="rt-button-group">
                <RichTextButton
                  className="rt-copy-btn"
                  disabled={buttonDisabled || noText}
                  icon={<img src={buttonDisabled || noText ? icCopyDisabled : icCopy} />}
                  onClick={() => this.copyText()}
                >
                  {t('一键复制')}
                </RichTextButton>
                <RichTextButton
                  className="rt-clear-btn"
                  disabled={buttonDisabled || noText}
                  icon={<img src={buttonDisabled || noText ? icClearDisabled : icClear} />}
                  onClick={() => this.reloadText()}
                >
                  {t('重置配置')}
                </RichTextButton>
              </div>
            )}
            <div className="rt-word-count">
              {size}/{maxSize}
            </div>
          </div>
        </div>
        {/* ------ 停顿选项 ------*/}
        <OptionsBar data={pauseBar}>
          <SelectList
            data={[
              ...pauseEnum,
              {
                label: t('移除停顿'),
                value: t('移除停顿'),
                danger: true,
              },
            ]}
            active={pauseBar.value}
            onSelect={this.setPause.bind(this)}
          />
        </OptionsBar>
        {/* ------ 多音字选项 ------*/}
        {polyphonic !== 'none' && (
          <Fragment>
            <OptionsBar data={polyphonicBar}>
              <SelectList data={polyphonicEnum} active={polyphonicBar.value} onSelect={this.setPolyphonic.bind(this)} />
            </OptionsBar>
            {/* ------ 数字选项 ------*/}
            <OptionsBar data={numberBar}>
              <SelectList
                data={numberEnum}
                active={numberBar.value}
                onSelect={this.setNumber.bind(this, numberBar.type as string)}
              />
            </OptionsBar>
          </Fragment>
        )}
        {/* ------ 局部语速选项 ------ */}
        <OptionsBar theme="blue" data={localSpeedBar}>
          <OptionsBar.Item danger={true} onClick={this.onLocalSpeedDel.bind(this)}>
            {t('移除')}
          </OptionsBar.Item>
        </OptionsBar>
        {/* ------ 动作选项 ------*/}
        <ActionBar
          data={actionBar}
          actionEnum={actionEnum}
          actionPositionMap={actionPositionMap}
          disabledActionMap={disabledActionMap}
          onSelect={this.setAction.bind(this)}
          onDelete={this.delAction.bind(this)}
        />
        {/* ------ 表情选项 ------*/}
        <ActionBar
          data={expressionBar}
          actionEnum={expressionEnum}
          actionPositionMap={expressionPositionMap}
          disabledActionMap={disabledExpressionMap}
          mode="expression"
          onSelect={this.setExpression.bind(this)}
          onDelete={this.delExpression.bind(this)}
        />
        {/* ------ 播放 ------*/}
        <AuditionPop
          visible={auditionPop.show}
          position={{
            left: auditionPop.left,
            top: auditionPop.top,
          }}
          hasLocalSpeed={auditionPop.localSpeed}
          placement={auditionPop.placement}
          playState={playState}
          onAuditionHandle={this.onAuditionHandle.bind(this)}
          onAuditionHide={this.onAuditionHide.bind(this)}
          onLocalSpeedChange={this.onLocalSpeedAdd.bind(this)}
        />
        {/* ------ 连续选项 ------ */}
        <OptionsBar theme="blue" data={wordBar}>
          <OptionsBar.Item danger={true} onClick={this.onWordDel.bind(this)}>
            {t('移除')}
          </OptionsBar.Item>
        </OptionsBar>
        {/* ------ 替换文本选项 ------ */}
        <OptionsBar data={subBar}>
          <SelectList
            style={{ minWidth: '46px', maxWidth: '70px', padding: '0' }}
            data={[
              {
                label: t('编辑'),
                value: 'edit',
              },
              {
                label: t('移除'),
                value: 'delete',
                danger: true,
              },
            ]}
            active={pauseBar.value}
            onSelect={this.setSub.bind(this)}
          />
          {/* <OptionsBar.Item onClick={this.showSubModal.bind(this, subBar.value)}>{t('编辑')}</OptionsBar.Item>
          <OptionsBar.Item onClick={this.onSubDel.bind(this)}>{t('移除')}</OptionsBar.Item> */}
        </OptionsBar>
        {/* ------ Modal 导入文件 ------*/}
        <Dialog
          visible={importModal.show}
          header={t('导入文件')}
          closeOnOverlayClick={false}
          closeOnEscKeydown={false}
          placement="center"
          footer={false}
          className="import-text-modal"
          // @ts-ignore
          onClose={this.importModalHandle.bind(this, false)}
        >
          <div className="rt-import-box">
            <div className="rt-import-item" onClick={this.onUpload.bind(this, 'cover')}>
              <Icon name="text-cover" size={22} />
              <div className="text-black">{t('覆盖原文本')}</div>
              <div>{t('替换已有文本')}</div>
            </div>
            <div className="rt-import-item" onClick={this.onInsert.bind(this)}>
              <Icon name="text-insert" size={20} />
              <div className="text-black">{t('插入新文本')}</div>
              <div>{t('在光标处插入新文本')}</div>
            </div>
          </div>
          <input
            type="file"
            ref={(node) => (this.fileRef = node)}
            style={{
              display: 'none',
            }}
            accept=".docx,.txt"
            onChange={this.onFileChange.bind(this)}
          />
        </Dialog>
        {/* ------ Modal 不允许导入文本 ------*/}
        <Modal
          visible={preventModal.show}
          className="rt-modal"
          width={480}
          title={
            <div className="modal-header">
              <ErrorCircleFilledIcon style={{ color: 'red' }} size={20} />
              {t('不允许${preventModal.type}文本', { type: preventModal.type })}
            </div>
          }
          onCancel={this.modalHandle.bind(this, 'preventModal', false, '')}
          footer={
            <div className="modal-footer">
              <Button
                style={{ width: 88 }}
                type="primary"
                onClick={this.modalHandle.bind(this, 'preventModal', false, '')}
              >
                {t('确认')}
              </Button>
            </div>
          }
        >
          <div className="text-box">
            <div>{t('您输入的文本已到达上限，无法继续{preventModal.type}，', { type: preventModal.type })}</div>
            <div>{t('请您重新编辑文本')}</div>
          </div>
        </Modal>
        {/* ------ Modal 部分文本 ------*/}
        <Modal
          visible={partModal.show}
          className="rt-modal"
          title={
            <div className="modal-header">
              <ErrorCircleFilledIcon style={{ color: '#E34D59' }} size={20} />
              {t('无法${partModal.type}全部文本', { type: partModal.type })}
            </div>
          }
          width={480}
          okText={t('确认${partModal.type}', { type: partModal.type })}
          cancelText={t('取消')}
          onOk={this.insertPartConfirm.bind(this)}
          onCancel={this.partModalHide.bind(this)}
        >
          <div className="text-box">
            <div>{t('由于字数限制，您的文本将不能全部{partModal.type}，', { type: partModal.type })}</div>
            <div> {t('请问您是否还要继续{partModal.type}？', { type: partModal.type })}</div>
          </div>
        </Modal>
        {/* ------ Modal 导入确认 ------*/}
        <Modal
          visible={insertConfirmModal.show}
          title={t('导入文本确认')}
          okText={t('确认导入')}
          onOk={this.insertConfirm.bind(this, true)}
          onCancel={this.insertConfirm.bind(this, false)}
        >
          <div className="text-box">
            <div className="text-center">{t('您的文本将导入到您目前光标所在位置后侧，')}</div>
            <div className="text-center">{t('请问您是否确认导入？')}</div>
          </div>
        </Modal>
        {/* ------ Modal 预览 ------*/}
        <PreviewModal
          previewTime={previewTime}
          previewSsml={previewSsml}
          previewWidth={previewWidth}
          enablePreviewBtn={(status: boolean) => {
            this.setState({ previewLoading: !status });
          }}
          onClose={() => this.previewModalClose()}
        />
        {/* 替换文本modal */}
        <SubInputModal
          subInputModalVisible={subInputModal.show}
          subInputVal={subInputModal.value}
          onClose={() => this.setState({ subInputModal: { show: false, subStartIdx: -1, subEndIdx: -1, value: '' } })}
          onConfirm={this.confirmSubInput.bind(this)}
        />
      </div>
    );
  }

  /* ------ 通用方法 ------*/
  // ctx滚动
  private onCtxScroll = () => {
    // TODO 需添加节流
    this.onPopHide(false);
  };

  private setStateSync(data: any) {
    // 错误用法,和直接setState无区别
    // @ts-ignore
    return new Promise((resolve) => this.setState(data, resolve));
  }

  // 保持焦点
  private holdFocus() {
    const { blurIdx, nodeList } = this.state;
    nodeList.splice(blurIdx, 0, inputNode);
    this.setStateSync({ inputNode, insertIdx: blurIdx }).then(() => {
      this.inputRef?.focus();
    });
  }

  /* ------ 头部按钮 ------ */
  // 获取动作置灰的map
  private getDisabledActionMap(index: number, which: 'head' | 'node') {
    const {
      modelSource,
      config: { actionTts },
    } = this.props;
    const { globalSpeed, actionMap, actionRules, blurIdx } = this.state;
    const disabledActionMap: { [key: string]: boolean } = {};
    if (modelSource && ziyanSourceList.includes(modelSource) && actionTts !== 'none') {
      // 自研形象精细化预测
      const { beforeTtsTime, afterTtsTime } = this.getTtsTimeBetweenAction(index);
      Object.keys(actionMap).forEach((actionId) => {
        const action = actionMap[actionId];
        const { durBefore, durAfter } = action;
        if (durBefore > beforeTtsTime || durAfter > afterTtsTime) disabledActionMap[actionId] = true;
      });
    } else {
      // pcg_ai形象mock动作插入规则
      let beforeIdx;
      let afterIdx;
      if (which === 'head') {
        beforeIdx = blurIdx > -1 ? index - 1 : index;
        afterIdx = index;
      } else {
        beforeIdx = index - 1;
        afterIdx = index + 1;
      }
      const { beforeAction, befortTextLen } = this.judgeBeforeAction(beforeIdx);
      const { afterAction, afterTextLen } = this.judgeAfterAction(afterIdx);

      const beforeDurAfter = actionMap[beforeAction?.value || '']?.durAfter;
      const afterDurBefore = actionMap[afterAction?.value || '']?.durBefore;

      Object.keys(actionMap).forEach((actionId) => {
        const action = actionMap[actionId];
        const { durBefore, durAfter } = action;
        if (beforeDurAfter) {
          const beforeTime = Math.max(Math.ceil((beforeDurAfter + durBefore) / 1000), 0);
          if (actionRules[`${beforeTime}-${globalSpeed}`] > befortTextLen) {
            disabledActionMap[actionId] = true;
          }
        }
        if (afterDurBefore) {
          const afterTime = Math.max(Math.ceil((afterDurBefore + durAfter) / 1000), 0);
          if (actionRules[`${afterTime}-${globalSpeed}`] > afterTextLen) {
            disabledActionMap[actionId] = true;
          }
        }
      });
    }
    return disabledActionMap;
  }

  // 获取当前index距前后动作之间tts时长
  private getTtsTimeBetweenAction(index: number, durBefore?: number, durAfter?: number) {
    const { globalSpeed, nodeList, actionMap } = this.state;
    const { actionInsertRules } = this.props;
    let beforeActionDurAfter = 0;
    let afterActionDurBefore = 0;
    // 动作插入位置之前
    let beforeNodeList = nodeList.filter((node, nodeIndex) => nodeIndex < index);
    // 纯文本插入位置
    const textIndex = utils.transformXml2Text(nodeListToSsml(beforeNodeList, false, globalSpeed)).length;
    // 动作前文本耗时beforeTtsTime=文本播放时长 - 前一个动作DurAfter（ms）
    const beforeActionIndex = beforeNodeList.findLastIndex(
      (node, beforeNodeIndex) => node.type === 'action' && !this.actionIsInvalid(nodeList, beforeNodeIndex),
    );
    // 前一个动作DurAfter
    if (beforeActionIndex > -1) {
      const beforeActionId = beforeNodeList[beforeActionIndex].value;
      beforeNodeList = beforeNodeList.filter((node, nodeIndex) => nodeIndex > beforeActionIndex);
      if (beforeActionId) {
        const beforeAction = actionMap[beforeActionId];
        beforeActionDurAfter = beforeAction?.durAfter;
      }
    } else {
      // 第一个动作不校验durBefore
      beforeActionDurAfter = -Infinity;
    }
    const beforeSsml = nodeListToSsml(beforeNodeList, false, globalSpeed);
    const beforeText = utils.transformXml2Text(beforeSsml);
    const beforeTextIndex = textIndex - beforeText.length;
    const beforeTtsStart =
      actionInsertRules.find((word) => word.posStart === beforeTextIndex)?.timeStart ||
      actionInsertRules.find((word) => word.posStart <= beforeTextIndex && word.posEnd > beforeTextIndex)?.timeEnd ||
      0;
    const beforeTtsEnd =
      actionInsertRules.find((word) => word.posEnd === textIndex)?.timeEnd ||
      actionInsertRules.find((word) => word.posEnd >= textIndex && word.posStart < textIndex)?.timeStart ||
      0;
    const beforeTtsTime = (beforeTtsEnd - beforeTtsStart) / 10000 - beforeActionDurAfter;
    // 获取动作durBefore所占text长度
    const beforeTextInActionIndex = !!beforeText.length
      ? [...actionInsertRules].reverse().find((word) => (beforeTtsEnd - word.timeStart) / 10000 >= (durBefore || 0))
          ?.posStart || 0
      : 0;
    const beforeTextInActionLength = textIndex - beforeTextInActionIndex;

    // 动作插入位置之后文本耗时afterTtsTime = 文本播放时长 - 后一个动作DurBefore（ms）
    let afterNodeList = nodeList.filter((node, nodeIndex) => nodeIndex > index);
    // 后一个动作DurAfter
    const afterActionIndex = afterNodeList.findIndex((node) => node.type === 'action');
    if (afterActionIndex > -1) {
      const afterActionId = afterNodeList[afterActionIndex].value;
      afterNodeList = afterNodeList.filter((node, nodeIndex) => nodeIndex < afterActionIndex);
      if (afterActionId) {
        const afterAction = actionMap[afterActionId];
        afterActionDurBefore = afterAction?.durBefore;
      }
    } else {
      // 最后一个动作不校验durAfter
      afterActionDurBefore = -Infinity;
    }
    const afterSsml = nodeListToSsml(afterNodeList, false, globalSpeed);
    const afterText = utils.transformXml2Text(afterSsml);
    const afterTextIndex = textIndex + afterText.length;
    const afterTtsStart =
      actionInsertRules.find((word) => word.posStart === textIndex)?.timeStart ||
      actionInsertRules.find((word) => word.posStart <= textIndex && word.posEnd > textIndex)?.timeEnd ||
      0;
    const afterTtsEnd =
      afterTextIndex > textIndex
        ? actionInsertRules.find((word) => word.posEnd === afterTextIndex)?.timeEnd ||
          actionInsertRules.find((word) => word.posEnd >= afterTextIndex && word.posStart < afterTextIndex)?.timeStart
        : 0;
    const afterTtsTime = ((afterTtsEnd || 0) - afterTtsStart) / 10000 - afterActionDurBefore;

    // 获取动作durAfter所占text长度
    const afterTextInActionIndex = !!afterText.length
      ? actionInsertRules.find((word) => (word.timeEnd - afterTtsStart) / 10000 >= (durAfter || 0))?.posEnd ||
        afterTextIndex
      : afterTextIndex;
    const afterTextInActionLength = afterTextInActionIndex - textIndex;
    // console.log({ afterTextInActionIndex, textIndex, afterTextInActionLength, beforeTtsEnd, beforeTextInActionIndex });
    // console.log({
    //   index,
    //   textIndex,
    //   beforeText,
    //   beforeTextIndex,
    //   beforeTtsStart,
    //   beforeTtsEnd,
    //   afterText,
    //   afterTextIndex,
    //   afterTtsStart,
    //   afterTtsEnd,
    //   beforeTtsTime,
    //   afterTtsTime,
    //   beforeActionDurAfter,
    //   afterActionDurBefore,
    // });
    return { beforeTtsTime, afterTtsTime, beforeTextInActionLength, afterTextInActionLength };
  }

  private judgeBeforeAction(paramBeforeIdx: number) {
    let beforeIdx = paramBeforeIdx;
    let beforeAction = null;
    const { nodeList, globalSpeed, maxCountMap } = this.state;
    let befortTextLen = 0;
    const maxCount = maxCountMap[globalSpeed];
    while (beforeIdx >= 0 && befortTextLen < maxCount) {
      const beforeNode = nodeList[beforeIdx];
      if (beforeNode?.type === 'action') {
        beforeAction = beforeNode;
        break;
      } else {
        beforeIdx -= 1;
        befortTextLen += (beforeNode.text || '').length;
      }
    }
    return {
      beforeAction,
      befortTextLen,
    };
  }

  private judgeAfterAction(paramAfterIdx: number) {
    let afterIdx = paramAfterIdx;
    const { nodeList, globalSpeed, maxCountMap } = this.state;
    let afterAction = null;
    const nodeListLen = nodeList.length;
    const maxCount = maxCountMap[globalSpeed];
    let afterTextLen = 0;
    while (afterIdx < nodeListLen && afterTextLen < maxCount) {
      const afterNode = nodeList[afterIdx];
      if (afterNode.type === 'action') {
        afterAction = afterNode;
        break;
      } else {
        afterIdx += 1;
        afterTextLen += afterNode.text?.length || 0; // TODO holdFocus时是不是有计数问题
      }
    }
    return {
      afterAction,
      afterTextLen,
    };
  }
  // 多音字检测
  private async globalPolyphonicCheck() {
    const { nodeList, globalSpeed } = this.state;
    const polyphonicMap: { [key: string]: number[] } = {};
    let testText = '';
    if (nodeList.length) {
      const behindSubStartIndex = nodeList.findIndex((n) => n.type === NODE_SUB_START);
      const behindSubEndIndex = nodeList.findIndex((n) => n.type === NODE_SUB_END);
      if (behindSubEndIndex > behindSubStartIndex) {
        message.warning(t('【多音字】不能放在【替换文本】内'));
        return;
      }
      const behindWordStartIndex = nodeList.findIndex((n) => n.type === NODE_WORD_START);
      const behindWordEndIndex = nodeList.findIndex((n) => n.type === NODE_WORD_END);
      if (behindWordEndIndex > behindWordStartIndex) {
        message.warning(t('【多音字】不能放在【连续】内'));
        return;
      }
    }
    nodeList.forEach(({ text, type }, idx) => {
      if (type === 'text' && text) {
        polyphonicMap[text] = [...(polyphonicMap[text] ? polyphonicMap[text] : []), idx];
        testText += text;
      }
    });
    // 从父元素获取拼音map
    const pinyin = await this.props.getPolyphonic(testText);
    Object.keys(pinyin).forEach((word) => {
      const { pinyinsDefault, pinyinsNum, recommendDefault, recommendNum } = pinyin[word];
      polyphonicMap[word].forEach((nodeListIdx, idx) => {
        const tag = recommendDefault?.[idx] || pinyinsDefault[0];
        const value = recommendNum?.[idx] || pinyinsNum[0];
        // 过滤替换标签中的多音字
        if (tag && value && !nodeList[nodeListIdx].inSub) {
          nodeList[nodeListIdx] = polyphonicNode(tag, word, value);
        }
      });
    });
    this.setState({ nodeList });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    this.onAuditionHandle('stop');
  }

  private triggerSectionChange(
    startAdjust: number | undefined = 0,
    nodeIdx: number | undefined = undefined,
    sectionIdx: number[] = [],
  ) {
    const { nodeList, globalSpeed, inputIdx, selection } = this.state;
    const { editSectionIndex } = this.props;

    console.log('triggerSectionChange', startAdjust, this.state, this.props, selection);

    if (sectionIdx?.length > 0) {
      setTimeout(() => {
        sectionIdx.forEach((idx) => {
          const { nodeList } = this.state;
          const startIndex = nodeList.findIndex((i) => i.sectionIndex === idx) - startAdjust;
          const endIndex = nodeList.findLastIndex((i) => i.sectionIndex === idx);
          const tempNodeList: RTNode[] = [];
          nodeList.forEach((el, i) => {
            if (i >= startIndex && i <= endIndex) {
              tempNodeList.push(el);
            }
          });
          let ssml = nodeListToSsml(tempNodeList, false, globalSpeed || '1.0');
          console.log('cur', idx, startIndex, endIndex, ssml);
          // <speak><prosody rate="100%">
          // </prosody></speak>
          ssml = utils.removeInvalidTags(ssml);
          console.log('cur', idx, startIndex, endIndex, ssml);
          this.props.onSectionChange?.(ssml, idx, globalSpeed);
        });
      }, 300);
    } else {
      let updateIndex = editSectionIndex;

      if ((selection.startIdx > 0 || selection.endIdx > 0) && nodeList[selection.startIdx + 1]) {
        // 选中的编辑
        updateIndex = nodeList[selection.startIdx + 1].sectionIndex;
      }
      if (nodeIdx) {
        updateIndex = nodeList[nodeIdx - 1].sectionIndex;
        console.log('cur updateIndex', updateIndex);
      }

      const startIndex = nodeList.findIndex((i) => i.sectionIndex === updateIndex) - startAdjust;
      let endIndex = nodeList.findLastIndex((i) => i.sectionIndex === updateIndex);
      // 调整逻辑 后置标签没有sectionindex的信息
      if (updateIndex && updateIndex > 0) {
        const ajEndIndex = nodeList.findIndex(
          (i) => updateIndex && updateIndex > 0 && i.sectionIndex === updateIndex + 1,
        );
        if (ajEndIndex > endIndex) {
          endIndex = ajEndIndex - 1;
        }
      }

      const tempNodeList: RTNode[] = [];
      nodeList.forEach((el, i) => {
        console.log('tempNodeList.push', i, updateIndex, startIndex, nodeList[0].type);

        if (updateIndex === 0 && i === 0 && startIndex === 1 && nodeList[0].type === 'action') {
          console.log('tempNodeList.push action');
          tempNodeList.push(el);
        }
        if (i >= startIndex && i <= endIndex) {
          tempNodeList.push(el);
          // 如果后面那个是动作节点，并且是最后那个节点，加入到里面
          if (
            i === endIndex &&
            nodeList.length === endIndex + 2 &&
            nodeList[endIndex + 1] &&
            nodeList[endIndex + 1].type === 'action'
          ) {
            console.log('tempNodeList.push');
            tempNodeList.push(nodeList[endIndex + 1]);
          }
        }
      });
      let ssml = nodeListToSsml(tempNodeList, false, globalSpeed || '1.0');
      console.log('cur', updateIndex, startIndex, endIndex, ssml);
      // <speak><prosody rate="100%">
      // </prosody></speak>
      ssml = utils.removeInvalidTags(ssml);
      console.log('cur', updateIndex, startIndex, endIndex, ssml);
      this.props.onSectionChange?.(ssml, updateIndex, globalSpeed);
    }
  }

  // 重置配置
  private reloadText() {
    const { originSsml } = this.props;
    const { globalSpeed } = this.state;
    this.props.onChange(originSsml, globalSpeed, true);
  }

  // 一键复制
  private copyText() {
    const { nodeList, globalSpeed } = this.state;
    const ssml = nodeListToSsml(nodeList, true, globalSpeed);
    clipboardCopy(ssml);
    message.success(t('文本配置复制成功'));
  }

  // 全局速度更改
  private onSpeedChange(globalSpeed: string) {
    const { nodeList } = this.state;
    this.setState({ globalSpeed });
    this.onVisibleChange('speed', false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    this.onAuditionHandle('stop');
  }

  // 导入弹窗
  private importModalHandle(show: boolean, inputIdx?: number) {
    const { nodeList, insertInfo } = this.state;
    if (show) {
      const insertIdx = inputIdx && inputIdx > -1 ? inputIdx : nodeList.length;
      this.setState({
        insertInfo: {
          ...insertInfo,
          insertIdx,
        },
      });
      this.onAuditionHandle('stop');
    }
    this.setState({
      importModal: {
        show,
      },
    });
  }

  // 导入确认
  private insertConfirm(confirm: boolean) {
    if (confirm) {
      const {
        insertInfo: { insertIdx, insertList = [] },
        nodeList,
        globalSpeed,
        size,
      } = this.state;
      nodeList.splice(insertIdx as number, 0, ...insertList);
      this.setState({
        nodeList,
        size: size + insertList.length,
      });
      this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
      this.triggerSectionChange();
    }
    this.setState({
      insertConfirmModal: {
        show: false,
      },
      insertInfo: {},
    });
  }

  // 上传
  private onUpload(uploadType: UploadType) {
    this.fileRef?.click();
    this.uploadType = uploadType;
  }

  private async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { uploadType } = this;
    const {
      nodeList,
      size,
      insertInfo: { insertIdx },
    } = this.state;
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const file = files[0];
    const { name } = file;
    const suffix = name.slice(name.lastIndexOf('.'));
    let text = '';
    if (suffix === '.docx') {
      text = await utils.docxToString(file);
    } else if (suffix === '.txt') {
      text = await utils.txtToString(file);
      // 如果有乱码，用gb2312编码再试一次
      if (text.indexOf('�') > -1) text = await utils.txtToString(file, 'gb2312');
    } else {
      alert(t('格式错误，仅支持.docx与.txt的文档'));
    }
    text = utils.escape2Html(text.replace(/\r\n/g, '\n'));
    const insertList = textToNodeList(text);
    if (uploadType === 'cover') {
      const insertRange = {
        startIdx: 0,
        endIdx: nodeList.length,
      };
      this.insertText({
        type: t('导入'),
        insertRange,
        insertList,
      });
    } else if (uploadType === 'insert') {
      const insertFunc = (insertInfo: any) => {
        this.setState({
          insertConfirmModal: {
            show: true,
          },
          insertInfo,
        });
      };
      this.insertText({
        type: t('导入'),
        insertIdx,
        insertList,
        insertFunc,
      });
    }
    this.importModalHandle(false);
    if (this.fileRef) {
      this.fileRef.value = '';
    }
  }

  // 插入前校验,
  private onInsert() {
    const { size } = this.state;
    if (size === this.props.maxSize) {
      this.modalHandle('preventModal', true, t('导入'));
      this.importModalHandle(false);
    } else {
      this.onUpload('insert');
    }
  }

  /* ------ 选区相关 ------*/
  // 获取选区idx
  private getSelection() {
    const selection = window.getSelection();
    // console.log('getSelection', selection);
    if (!selection) return;
    let selectionText = selection.toString();
    const { anchorNode } = selection;
    if (!anchorNode) return this.state.selection;

    let {
      startContainer: {
        parentElement: startElement, // 选中的第一个元素
      },
      endContainer: {
        parentElement: endElement, // 选中的最后一个元素
      },
    } = selection.getRangeAt(0);
    if (!startElement || !endElement) return;
    // 选择到了node内部
    if (startElement.className.indexOf('rt-node') < 0) startElement = startElement.parentElement;
    // 三机选择段落
    if (['video-make-content', 'template-video-fragment-edit'].includes(endElement.className)) {
      const rtNodeEles = document.getElementsByClassName('rt-node');
      endElement = rtNodeEles[rtNodeEles.length - 1] as HTMLElement;
    } else {
      // 选择到了node内部
      if (endElement.className.indexOf('rt-node') < 0) endElement = endElement.parentElement;
    }
    // @ts-ignore
    const startIdx = parseInt(startElement.getAttribute('data-idx') || '0', 10);
    // @ts-ignore
    const endIdx = parseInt(endElement.getAttribute('data-idx') || '0', 10) + 1;
    // 过滤出文本node
    if (!!selectionText) {
      const { nodeList } = this.state;
      selectionText = nodeList
        .filter((n, idx) => idx >= startIdx && idx < endIdx && textCountNodeTypes.includes(n.type))
        .map((n) => n.text)
        .join('');
    }
    // console.log('selectionTextTemp', selectionText);
    return {
      startIdx,
      endIdx,
      selectionText,
      startElement,
      endElement,
    };
  }

  // 取消选区
  private cancelSelection() {
    const ctxSelection = window.getSelection();
    if (ctxSelection) {
      ctxSelection.removeAllRanges();
    }
  }

  // 全选
  private selectAll() {
    const context = document.getElementById('rt-context');
    if (!context) return;
    const range = document.createRange();
    range.selectNodeContents(context);
    const ctxSelection = window.getSelection();
    if (!ctxSelection) return;
    ctxSelection.removeAllRanges();
    ctxSelection.addRange(range);
    let selectionText = ctxSelection.toString();
    // 过滤出文本node
    if (!!selectionText) {
      const { nodeList } = this.state;
      selectionText = nodeList
        .filter((n) => textCountNodeTypes.includes(n.type))
        .map((n) => n.text)
        .join('');
    }
    setTimeout(() => {
      this.onInputBlur();
      const selection: SelectionInfo = {
        startIdx: 0,
        endIdx: this.state.nodeList.length,
        selectionText,
      };
      this.setState({ selection });
      // 选择5个字及以上&不嵌套表情可插入表情
      this.setState({
        selectionInExpression: this.state.nodeList.every((i) => !i.inExpression),
      });
    }, 200);
    this.onAuditionHandle('standby');
  }

  private onSelectStart(e: any) {
    // console.log('onSelectStart', e);
    // 右键事件不触发取消选中
    if (e?.button === 2) return;
    // @ts-ignore
    document?.selection?.empty() || window?.getSelection()?.removeAllRanges();
  }

  // 选择结束
  private onSelectEnd(e: any) {
    // console.log('onSelectEnd', e);
    if (e?.button === 2) return;
    e.stopPropagation();
    const selection = this.getSelection();
    // console.log('onSelectEnd', selection);
    this.setStateSync({
      selection,
    }).then(() => this.selectHandle(e));
  }

  // 选中控制
  private selectHandle(e: any) {
    // console.log('selectHandle', e);
    this.onPopHide(true);
    const { nodeList, selection } = this.state;
    const { startIdx, selectionText = '', startElement, endElement, endIdx } = selection;
    if (!startElement || !endElement) return;
    const startClassName = startElement.className.replace(/rt-node /g, '');
    const endClassName = endElement.className.replace(/rt-node /g, '');
    const notSelectionClass = ['rt-pause', 'rt-action', 'rt-action-invalid'];
    if (notSelectionClass.includes(startClassName) || notSelectionClass.includes(endClassName)) return;
    if (selectionText === '\n') return;
    const textLen = selectionText.length;
    if (!!e) e.target = this.nodeDomList[startIdx]; // mouseUp时，可能是后一个元素的前一半位置，所以设置指针指向初始元素

    const list = nodeList.slice(startIdx, endIdx);
    const startNode = nodeList[startIdx];
    const endNode = nodeList[endIdx - 1];
    // console.log('startNode', startNode, 'endNode', endNode);
    // 判断选中区间和局部语速区间是否有交集
    let localSpeedShow = true;
    let expressionAvalid = true;
    if (list.find((item) => [LOCAL_SPEED_START, LOCAL_SPEED_END].includes(item.type))) {
      localSpeedShow = false;
      expressionAvalid = false;
    }
    // 判断开始结束node是否已在成对标签内
    if (startNode.localSpeed || endNode.localSpeed) {
      localSpeedShow = false;
    }
    if (startNode.inExpression || startNode.inWord || startNode.inSub) {
      localSpeedShow = false;
      expressionAvalid = false;
    }
    if (endNode.inExpression || endNode.inWord || endNode.inSub) {
      localSpeedShow = false;
      expressionAvalid = false;
    }
    // for (let i = startIdx; i < endIdx; i++) {
    //   const activeNode = nodeList[i];
    //   if ([LOCAL_SPEED_START, LOCAL_SPEED_END].includes(activeNode.type)) {
    //     localSpeedShow = false;
    //     break;
    //   }
    // }
    // if (localSpeedShow === true) {
    //   let idx = 0;
    //   const nodeListLen = nodeList.length;
    //   while (startIdx - idx >= 0 && endIdx + idx < nodeListLen) {
    //     const frontNode = nodeList[startIdx - idx];
    //     const behindNode = nodeList[endIdx + idx];
    //     const { type: frontType } = frontNode;
    //     const { type: behindType } = behindNode;
    //     // 向前检索到End或者到头部无Start，向后检索到Start或者到尾部无End，则不在局部语速/成对标签内
    //     if (PARE_END_NODE_MAP[frontType] || PARE_START_NODE_MAP[behindType]) break;
    //     if (!!PARE_START_NODE_MAP[frontType] || !!PARE_END_NODE_MAP[behindType]) {
    //       localSpeedShow = false;
    //       break;
    //     }
    //     idx += 1;
    //   }
    // }

    // 试听气泡
    const textRegExp = /[a-zA-Z0-9\u4e00-\u9fa5]/;
    if (textRegExp.test(selectionText) && this.props.enableAudition) {
      setTimeout(() => this.onInputBlur(), 200);
      this.handle('stop');
      this.auditionPopShow(localSpeedShow && !this.props.isMicrosoftTts);
    }

    // 是数字，设置数字读音
    if (!this.props.isMicrosoftTts) {
      if (/^[0-9]+\.?[0-9]*$/.test(selectionText)) {
        this.numberNodeClick({}, e);
      } else if (utils.checkDate(selectionText)) {
        this.numberNodeClick({}, e);
      } else if (utils.checkTime(selectionText)) {
        this.numberNodeClick({}, e);
      }
    }

    // 选中单个字设置多音字
    if (textLen === 1) {
      if (nodeList[startIdx].type === 'text' && !this.props.isMicrosoftTts) {
        const textRegExp = /[\u4e00-\u9fa5]/;
        const { text } = nodeList[startIdx];
        if (!text) return;
        if (!textRegExp.test(text)) return;
        this.polyphonicNodeClick(startIdx, true, e);
      }
    }
    // 选择1个字及以上&不嵌套表情可插入表情
    if (textLen > expressionMinTextLength - 1) {
      // console.log('expressionSelect', list);
      this.setState({
        selectionInExpression: list.every((i) => !i.inExpression) && expressionAvalid,
      });
    }
  }

  // 复制
  private onCtxCopy(e: any) {
    // console.log('onCtxCopy');
    e.preventDefault();
    const { nodeList, selection, globalSpeed } = this.state;
    const { startIdx, endIdx } = selection;
    const list = nodeList.slice(startIdx, endIdx);
    const clipboard = filterSinglePareNode(list);
    e.clipboardData.setData('text', nodeListToSsml(clipboard, false, globalSpeed));
  }

  // 剪切
  private onCtxCut(e: any) {
    e.preventDefault();
    let { size } = this.state;
    const { globalSpeed, selection } = this.state;
    let { nodeList } = this.state;
    const { startIdx, endIdx } = selection;
    const delInfo = this.delListWithPareNode(nodeList, startIdx, endIdx, [inputNode]);
    nodeList = delInfo.nodeList;
    let clipboard = delInfo.delList;
    clipboard = filterSinglePareNode(clipboard);
    clipboard.forEach(({ text = '' }) => (size -= this.calTextLen(text)));

    this.setStateSync({
      inputIdx: startIdx,
      nodeList,
      size,
    }).then(() => this.inputRef?.focus());

    e.clipboardData.setData('text', nodeListToSsml(clipboard, false, globalSpeed));
    this.onPopHide(false);
    this.onAuditionHandle('stop');
  }

  // 计算文本长度
  private calTextLen(text: string) {
    let len = 0;
    if (text) {
      if (/[\u0B80-\u0BFF]+/g.test(text)) {
        len = splitTamilText(text).length;
      } else {
        len = text.length;
      }
    }
    return len;
  }

  // 选区粘贴
  private onCtxPaste(e: any) {
    e.preventDefault(); // 阻止粘贴
    const { selection, inputIdx, noneTagEnum, nodeList } = this.state;
    if (inputIdx > -1) return; // 见 onInputPaste()
    let clipboardText = e.clipboardData.getData('text');
    if (!utils.isSsml(clipboardText)) {
      clipboardText = utils.formatXml(clipboardText);
    }
    const { startIdx, endIdx } = selection;
    // 无选区 取消
    if (startIdx === 0 && endIdx === 0) return;

    let [insertList] = ssmlToNodeList(clipboardText);
    // 如在表情区间中粘贴，过滤insertList中的表情
    if (
      nodeList[startIdx - 1]?.inExpression ||
      nodeList[startIdx + 1]?.inExpression ||
      nodeList[startIdx - 1]?.type === NODE_EXPRESSION_START ||
      nodeList[startIdx + 1]?.type === NODE_EXPRESSION_END
    ) {
      insertList = insertList.filter((i) => ![NODE_EXPRESSION_START, NODE_EXPRESSION_END].includes(i.type));
    }
    // 过滤不可用标签
    if (!!noneTagEnum?.length) insertList = onTagFilter(insertList, noneTagEnum);

    this.insertText({
      type: t('粘贴'),
      insertRange: selection,
      insertList,
    });
    this.onPopHide(false);
    this.onAuditionHandle('stop');
  }

  // mousedown绑定事件
  private isInContext = (e: any) => {
    // console.log('isInContext', e.target);
    // 移入富文本编辑器
    if (this.checkElementInRichText(e.target) || this.state.subInputModal.show) {
      (document.querySelector('body') as HTMLElement).style.userSelect = 'none';
      this.setState({ inContext: true });
    } else {
      this.setState({ inContext: false });
    }
  };

  // mouseup绑定事件
  private moveOutCancel = (e: any) => {
    // console.log('moveOutCancel', e.target);
    (document.querySelector('body') as HTMLElement).style.userSelect = 'auto';
    const windowSelection = window?.getSelection();
    // 无选中内容
    // console.log('windowSelection', !windowSelection?.anchorNode, !windowSelection?.focusNode);
    if (!windowSelection || !windowSelection?.anchorNode || !windowSelection?.focusNode) return;
    if (!this.state.inContext && !this.checkElementInRichText(e.target)) {
      // 在外部选选中删除富文本内选取内容
      if (!!this.state.selection.startElement) window?.getSelection()?.removeAllRanges();
      this.setState({
        selection: {
          startIdx: 0,
          endIdx: 0,
          selectionText: '',
          startElement: null,
          endElement: null,
        },
      });
      this.setState({ inContext: false });
      return;
    }
    this.setState({ inContext: false });
    const {
      startContainer: {
        parentElement: startElement, // 选中的第一个元素
      },
      endContainer: {
        parentElement: endElement, // 选中的最后一个元素
      },
    } = windowSelection?.getRangeAt(0);
    // console.log('windowSelection', windowSelection, startElement, endElement);
    if (
      (!this.checkElementInRichText(startElement) && this.checkElementInRichText(endElement)) ||
      (this.checkElementInRichText(startElement) && !this.checkElementInRichText(endElement))
    ) {
      // 从外部开始选区or选中外部元素取消选择
      this.setState({
        selection: {
          startIdx: 0,
          endIdx: 0,
          selectionText: '',
          startElement: null,
          endElement: null,
        },
      });
      window?.getSelection()?.removeAllRanges();
    } else if (
      !this.state.auditionPop.show &&
      (this.checkElementInRichText(startElement) || this.checkElementInRichText(endElement))
    ) {
      // 从富文本内选取&光标在富文本外弹起，选中富文本内容
      const selection = this.getSelection();
      this.setStateSync({
        selection,
      }).then(() => this.selectHandle(null));
    }
  };

  // 检查元素是否在富文本内容rt-context 中
  private checkElementInRichText = (e: HTMLElement | null | undefined) => {
    // console.log('000', e);
    let cur = e;
    while (cur) {
      // console.log(111, cur.id);
      if (cur.id === 'rich-text') {
        // console.log('checkElementInRichText', true);
        return true;
      }
      cur = cur.parentElement;
    }
    // console.log('checkElementInRichText', false);
    return false;
  };

  // 文档keyDown
  private onCtxKeyDown = (event: any) => {
    // 如果事件已经在进行中，则不做任何事。
    if (event.defaultPrevented) return;
    // console.log('rt keydown event', event, event.keyCode);

    const { keyCode, ctrlKey, metaKey } = event;
    const { listenEvent } = this.props;
    const { nodeList, selection, inputIdx, globalSpeed } = this.state;
    if (!listenEvent || inputIdx > -1) return;
    // 有光标 return
    const { startIdx, endIdx, selectionText } = selection;

    switch (event.key) {
      case 'ArrowUp':
        // “↑”方向键
        if (!selection.selectionText) return;
        this.cursorEnjambment('up');
        this.onAuditionHide();
        break;
      case 'ArrowDown':
        // “↓”方向键
        if (!selection.selectionText) return;
        this.cursorEnjambment('down');
        this.onAuditionHide();
        break;
      case 'ArrowLeft':
        // “←”方向键
        if (!selection.selectionText) return;
        this.cancelSelection();
        this.cursorMove(startIdx, true);
        this.onAuditionHide();
        break;
      case 'ArrowRight':
        // “→”方向键
        if (!selection.selectionText) return;
        this.cancelSelection();
        this.cursorMove(endIdx, true);
        this.onAuditionHide();
        break;
      case 'Enter':
        // “回车”键
        break;
      case 'Escape':
        // “ESC”键
        this.cancelSelection();
        this.onAuditionHide();
        break;
      default:
        return;
    }

    // ctrl+A || command+A 全选
    if (keyCode === 65) {
      if (!isActiveInput()) return;
      if (ctrlKey || metaKey) this.selectAll();
    }

    // 取消默认动作，从而避免处理两次。
    event.preventDefault();
  };

  private onDocumentKeyDown = (event: KeyboardEvent) => {
    // console.log('document keydown event', event, event.keyCode, this.state.selection);

    // @ts-ignore
    // if (this.checkElementInRichText(event.target)) {
    // if (event.defaultPrevented) return;
    const { keyCode } = event;
    const { listenEvent } = this.props;
    const { selection, inputIdx, globalSpeed } = this.state;
    let { nodeList } = this.state;
    let { size } = this.state;
    if (!listenEvent || inputIdx > -1) return;
    // 有光标 return
    const { startIdx, endIdx, selectionText } = selection;
    console.log('onDocumentKeyDown', keyCode, selection);
    switch (keyCode) {
      // delete backspace
      case 8:
      case 46:
        {
          if (!selectionText) return;
          const delInfo = this.delListWithPareNode(nodeList, startIdx, endIdx, [inputNode]);
          const { delList, nodeList: list } = delInfo;
          nodeList = list;
          delList.forEach(({ text = '' }) => (size -= this.calTextLen(text)));

          this.setStateSync({
            inputIdx: startIdx,
            nodeList,
            size,
          }).then(() => this.inputRef?.focus());

          this.onPopHide(false);
          this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
          this.triggerSectionChange();
          this.onAuditionHandle('stop');
        }
        break;
    }
    // event.preventDefault();
    // }
  };

  /* ------ 光标相关 ------*/

  // 光标插入
  private cursorInsert(idx: number, e: any) {
    console.log('cursorInsert', idx);
    e.stopPropagation();
    // @ts-ignore
    const { selectionText } = this.getSelection();
    if (selectionText.length > 0) return;
    const { clientX, target } = e;
    const { left, width } = target.getBoundingClientRect();
    const position = clientX - left < width / 2 ? 0 : 1;
    const inputIdx = idx + position;

    const { nodeList } = this.state;

    const insertSectionIndex = nodeList[inputIdx]?.sectionIndex;
    if (insertSectionIndex !== undefined) {
      this.props.updateCurIndex?.(insertSectionIndex);
    }

    nodeList.splice(inputIdx, 0, inputNode);
    this.setStateSync({
      inputIdx,
      nodeList,
      actionHide: false,
    }).then(() => this.inputRef?.focus()); // TODO 动作
    this.onAuditionHandle('stop');
  }

  // 在空白处按下文档末尾展示光标（直接用onClick会选区选取文字时，会误触）
  private onCtxMouseDown(e: any) {
    // 不能使用e.stopPropagation,否则会阻碍window.onmousedown的劫持
    // console.log('onCtxMouseDown', e);
    this.onSelectStart(e);
    if (e?.button === 2 && this.state.inputIdx !== -1) {
      // this.inputRef?.focus();
      e.preventDefault();
      return;
    }
    if (e.target.id !== 'rt-context') return;
    // 选区设置
    this.setState({ ctxMouseDown: true });

    // 抬起后自动将ctxMouseDown置为false，以供下次判断使用
    const ctxMouseDownFunc = () => {
      this.setState({ ctxMouseDown: false });
      window.removeEventListener('mouseup', ctxMouseDownFunc);
    };
    window.addEventListener('mouseup', ctxMouseDownFunc);
  }

  private onCtxMouseUp(e: any) {
    const { nodeList, ctxMouseDown } = this.state;
    // console.log('onCtxMouseUp', e, ctxMouseDown);
    const { pageX: x, pageY: y } = e;
    if (e.button === 2 && this.state.inputIdx !== -1) return;
    // 判断mouseDown是不是在背景，是的话才会设置光标
    if (ctxMouseDown) {
      const { nodeDomList } = this;
      const domLen = nodeDomList.length; // TODO 可优化？
      let inputIdx = nodeList.length;
      // 光标插入到折行文本末尾
      for (let i = 0; i < domLen; i++) {
        const node = nodeDomList[i];
        if (node) {
          const { top } = node.getBoundingClientRect();
          if (top > e.clientY) {
            inputIdx = i - 1;
            break;
          }
        }
      }
      nodeList.splice(inputIdx, 0, inputNode);
      this.setStateSync({
        inputIdx,
        nodeList,
      }).then(() => this.inputRef?.focus());
    } else {
      const selection = this.getSelection();
      this.setStateSync({
        selection,
        mouseUpInfo: {
          x,
          y,
        },
      }).then(() => this.selectHandle(e));
    }
  }

  // 输入按键
  private onCursorKeyDown(e: any) {
    if (this.props.textInputDisable) {
      console.log('onCursorKeyDown textInputDisable');
      return;
    }
    let { inputIdx, size } = this.state;
    const { actionHide, globalSpeed, expressionHide } = this.state;
    let { nodeList } = this.state;
    const { keyCode, shiftKey, ctrlKey, metaKey } = e;
    const nodeListLen = nodeList.length;
    if (actionHide || expressionHide) return;
    if (shiftKey) {
      // TODO
    } else {
      switch (keyCode) {
        // 删除 backspae
        case 8:
          {
            e.stopPropagation();
            if (inputIdx <= 0) return;
            const backspaceNode = nodeList.splice(inputIdx - 1, 1)[0];
            const { type, text = '' } = backspaceNode;
            if (type === 'polyphonic') {
              nodeList.splice(inputIdx - 1, 0, textNode(text));
            } else if (type === 'number') {
              const numberList = text.split('').map((item) => textNode(item));
              nodeList.splice(inputIdx - 1, 0, ...numberList);
              inputIdx += numberList.length;
            } else if (!!PARE_START_NODE_MAP[type]) {
              nodeList = deleteEndByStart(inputIdx, nodeList, type);
              inputIdx -= 1;
            } else if (!!PARE_END_NODE_MAP[type]) {
              nodeList = deleteStartByEnd(inputIdx - 1, nodeList, type);
              inputIdx -= 2;
            } else {
              size -= this.calTextLen(text);
              inputIdx -= 1;
            }
            this.setState({
              inputIdx,
              nodeList,
              size,
            });
            this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
            this.triggerSectionChange();
          }
          break;
        // 删除 delete
        case 46:
          {
            e.stopPropagation();
            const deleteNode = nodeList.splice(inputIdx + 1, 1)[0];
            const { text = '', type } = deleteNode;
            if (!!PARE_START_NODE_MAP[type]) {
              nodeList = deleteEndByStart(inputIdx, nodeList, type);
            } else if (!!PARE_END_NODE_MAP[type]) {
              nodeList = deleteStartByEnd(inputIdx - 1, nodeList, type);
            }
            const deleteText = text;
            if (deleteText) size -= this.calTextLen(deleteText);
            this.setState({
              inputIdx,
              nodeList,
              size,
            });
            this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
            this.triggerSectionChange();
          }
          break;
        // home
        case 36:
          this.cursorMove(0, false);
          break;
        // end
        case 35:
          this.cursorMove(nodeListLen - 1, false);
          break;
        // 左
        case 37:
          if (inputIdx - 1 < 0) return; // 最头部限制
          this.cursorMove(inputIdx - 1, false);
          break;
        // 右
        case 39:
          if (inputIdx + 1 >= nodeListLen) return; // 最尾部限制
          this.cursorMove(inputIdx + 1, false);
          break;
        // 上
        case 38:
          this.cursorEnjambment('up');
          return; // 不是break break执行两次设置nodeList，指针不正常
        // 下
        case 40:
          this.cursorEnjambment('down');
          return;
        // 全选
        case 65:
          if (ctrlKey || metaKey) this.selectAll();
          break;
        default:
          return;
      }
    }
  }

  // 移动光标
  private cursorMove(idx: number, focus: boolean) {
    const { nodeList, inputIdx } = this.state;
    if (inputIdx > -1) nodeList.splice(inputIdx, 1); // 光标先删后加
    nodeList.splice(idx, 0, inputNode);
    this.setStateSync({
      inputIdx: idx,
      nodeList,
    }).then(() => {
      if (focus) this.inputRef?.focus();
    });
  }

  // 光标跨行移动
  private async cursorEnjambment(direction: 'up' | 'down') {
    const { inputIdx, nodeList, selection } = this.state;
    let hasResult = false;
    const { nodeDomList } = this;

    let startIdx = -1;
    let inputNode;
    if (inputIdx > -1) {
      startIdx = inputIdx + 1;
      inputNode = this.inputRef;
    } else {
      startIdx = selection.startIdx;
      inputNode = nodeDomList[startIdx + 1];
    }
    if (!inputNode) return;
    const { left: cursorLeft, top: cursorTop, bottom: cursorBottom } = inputNode.getBoundingClientRect();
    if (direction === 'up') {
      for (let i = startIdx; i >= 0; i--) {
        const nodeDom = nodeDomList[i];
        if (!nodeDom) continue;
        const { left, top, width, height } = nodeDom.getBoundingClientRect();
        if (top < cursorTop - height / 2 && left - width / 2 <= cursorLeft) {
          this.cursorMove(i, true);
          hasResult = true;
          break;
        }
      }
      // 第一行按↑，直接跳到起始位置
      if (!hasResult) this.cursorMove(0, true);
    } else if (direction === 'down') {
      for (let i = startIdx, len = nodeDomList.length; i < len; i++) {
        const item = nodeDomList[i];
        if (!item) continue;
        const { top, right, width, height } = item.getBoundingClientRect();
        if (top >= cursorTop + height / 2) {
          // <br>直接跳
          if (item.tagName === 'BR') {
            this.cursorMove(i - 1, true);
            hasResult = true;
            break;
          } else if (right + width / 2 >= cursorLeft) {
            this.cursorMove(i, true);
            hasResult = true;
            break;
          }
        }
      }
      // 倒数第二行，移到最后
      if (!hasResult) this.cursorMove(nodeList.length - 1, true);

      // 超过底边 向上滚3行
      const inner = document.getElementById('rt-scroll-inner');
      const scroll = document.getElementById('rt-scroll');
      if (scroll && inner) {
        const { bottom: innerBottom } = scroll.getBoundingClientRect();
        if (cursorBottom > innerBottom - 22) inner.scrollTop += 66;
      }
    }
  }

  private focusCursor() {
    this.setStateSync({
      inputIdx: 0,
      nodeList: [inputNode],
    }).then(() => this.inputRef?.focus());
  }

  /* ------ 输入相关 -------*/

  // input更改
  private onInputChange(e: any) {
    if (this.props.textInputDisable) {
      console.log('onInputChange textInputDisable');
      if (this.inputRef) {
        this.inputRef.innerText = '';
      }
      return;
    }
    e.stopPropagation();
    const text = e.target.innerText.replace(/\n\n/g, '\n').replace(' ', ' '); // span在contentEditable模式下，输入的空格的unicode为%a0,无法被模型识别，需要转换成%20的空格
    const { composition, inputIdx, nodeList, size, globalSpeed } = this.state;
    let [inLocalSpeed, inExpression, inWord, inSub] = [false, false, false, false];
    // 局部语速内
    if (nodeList[inputIdx - 1]?.localSpeed || nodeList[inputIdx + 1]?.localSpeed) {
      inLocalSpeed = true;
    }
    // 表情内
    if (nodeList[inputIdx - 1]?.inExpression || nodeList[inputIdx + 1]?.inExpression) {
      inExpression = true;
    }
    // 连读内
    if (nodeList[inputIdx - 1]?.inWord || nodeList[inputIdx + 1]?.inWord) {
      inWord = true;
    }
    // 替换文本内
    if (nodeList[inputIdx - 1]?.inSub || nodeList[inputIdx + 1]?.inSub) {
      inSub = true;
    }
    const inputNodeList = textToNodeList(text, { inLocalSpeed, inExpression, inWord, inSub });
    const inputNodeLen = inputNodeList.length;
    if (composition === false) {
      // 禁止输入
      if (size >= this.props.maxSize) {
        this.modalHandle('preventModal', true, t('输入'));
        this.inputRef?.blur();
        return;
      }
      nodeList.splice(inputIdx, 0, ...inputNodeList);
      this.setState({
        nodeList,
        inputIdx: inputIdx + inputNodeLen,
        size: size + inputNodeLen,
      });
      this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
      this.triggerSectionChange();
      this.onAuditionHandle('stop');
      if (this.inputRef) {
        this.inputRef.innerText = '';
      }
    }
  }

  // input 失焦
  private async onInputBlur(e?: any) {
    this.setState({ inputContextMenuVisible: false });
    console.log('onInputBlur', e);
    let { nodeList } = this.state;
    const { inputIdx, globalSpeed } = this.state;
    nodeList = nodeList.filter(({ type }) => type !== 'input'); // TODO 可优化直接删除inputIdx,粘贴超长时，删的不准
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    await this.setStateSync({
      nodeList,
      inputIdx: -1,
      blurIdx: inputIdx,
    });
    setTimeout(() => this.setState({ blurIdx: -1 }), 200); // blurIdx保存2s
  }

  // 输入法开始
  private onInputCompositionStart() {
    this.setState({ composition: true });
  }

  // 输入法结束
  private onInputCompositionEnd(e: any) {
    const { inputIdx, nodeList } = this.state;
    const text = e.target.innerText;
    console.log(text, 'onInputCompositionEnd');
    this.setState({ composition: false });
    let [inLocalSpeed, inExpression, inWord, inSub] = [false, false, false, false];
    // 局部语速内
    if (nodeList[inputIdx - 1]?.localSpeed || nodeList[inputIdx + 1]?.localSpeed) {
      inLocalSpeed = true;
    }
    // 标签内
    if (nodeList[inputIdx - 1]?.inExpression || nodeList[inputIdx + 1]?.inExpression) {
      inExpression = true;
    }
    // 连读内
    if (nodeList[inputIdx - 1]?.inWord || nodeList[inputIdx + 1]?.inWord) {
      inWord = true;
    }
    // 替换文本内
    if (nodeList[inputIdx - 1]?.inSub || nodeList[inputIdx + 1]?.inSub) {
      inSub = true;
    }
    let textList = [];
    if (/[\u0B80-\u0BFF]+/g.test(text)) {
      textList = splitTamilText(text);
    } else {
      textList = text.split('');
    }
    const insertList = textList.map((item: string) => textNode(item, { inLocalSpeed, inExpression, inWord, inSub }));
    this.insertText({
      type: t('输入'),
      insertIdx: inputIdx,
      insertList,
    });
    if (this.inputRef) this.inputRef.innerText = '';
  }

  // input中粘贴
  private async onInputPaste(e: any) {
    if (this.props.textInputDisable) {
      console.log('onInputPaste textInputDisable');
      if (this.inputRef) {
        this.inputRef.innerText = '';
      }
      return;
    }
    e.preventDefault(); // 阻止粘贴
    const { inputIdx, noneTagEnum, nodeList } = this.state;
    let clipboardText = '';
    if (e?.clipboardData) {
      clipboardText = e?.clipboardData.getData('text');
    } else {
      await navigator.clipboard.readText().then((text) => {
        clipboardText = text;
        console.log('0', clipboardText);
      });
    }
    // console.log('000', clipboardText);
    if (!utils.isSsml(clipboardText)) {
      clipboardText = utils.formatXml(clipboardText);
    }
    let [insertList] = ssmlToNodeList(clipboardText);
    // 如在表情区间中粘贴，过滤insertList中的表情
    if (
      nodeList[inputIdx - 1]?.inExpression ||
      nodeList[inputIdx + 1]?.inExpression ||
      nodeList[inputIdx - 1]?.type === NODE_EXPRESSION_START ||
      nodeList[inputIdx + 1]?.type === NODE_EXPRESSION_END
    ) {
      insertList = insertList.filter((i) => ![NODE_EXPRESSION_START, NODE_EXPRESSION_END].includes(i.type));
    }
    // console.log('111', clipboardText, insertList);
    // 过滤不可用标签
    insertList = onTagFilter(insertList, noneTagEnum);
    this.insertText({
      type: t('粘贴'),
      insertIdx: inputIdx,
      insertList,
    });
  }

  private onInputMenuPaste(e: any) {
    this.setState({ inputContextMenuVisible: false });
    e.preventDefault();
    console.log('onInputMenuPaste', e);
    this.onInputPaste(e);
  }

  /* ------ tag点击相关 -------*/

  // 暂停节点点击
  private pauseNodeClick(idx: number, e: any) {
    e.stopPropagation();
    const { width, height, left, right, top } = e.target.getBoundingClientRect();
    const { nodeList } = this.state;
    const { tag } = nodeList[idx];

    const pauseBar = {
      show: true,
      idx,
      value: tag,
      position: {
        left: left - 3,
        right,
        top: top + height + 7,
      },
    };

    this.setState({
      pauseBar,
      actionHide: false,
    });
    this.onPopHide(true);
  }

  // 多音字节点点击
  private async polyphonicNodeClick(idx: number, isSelection: boolean, e: any) {
    if (!e) return;
    e.stopPropagation();
    let { target } = e;
    // 父元素中心
    if (['rt-polyphonic-text', 'rt-tag'].includes(target.className)) {
      target = target.parentNode;
    } else if (target.getElementsByClassName('rt-tag')[0]) {
      [target] = target.getElementsByClassName('rt-tag');
    }
    const { width, height, left, right, top, bottom } = target.getBoundingClientRect();
    const { nodeList } = this.state;
    const { text = '', tag } = nodeList[idx];
    const polyphonic = await this.props.getPolyphonic(text); // 获取读音
    const polyphonicList = polyphonic[text]?.pinyinsDefault;
    const pinyinsNum = polyphonic[text]?.pinyinsNum;
    if (!polyphonicList || polyphonicList.length === 1) return;

    const polyphonicEnum: PolyphonicEnum[] = [
      ...polyphonicList.map((label, idx) => ({
        label,
        value: pinyinsNum[idx],
      })),
    ];
    if (!isSelection) {
      polyphonicEnum.push({
        label: t('移除多音标注'),
        value: t('移除多音标注'),
        danger: true,
      });
    }
    const richTextEle = document.getElementById('rich-text') as HTMLElement;
    const { top: rtTop } = richTextEle.getBoundingClientRect();
    const isFirstLine = top - 35 < rtTop + 16 + 24;
    // 选中第一行pop下移防止被试听pop遮挡
    const polyphonicBar = {
      show: true,
      idx,
      value: tag,
      position: {
        left,
        top: isFirstLine && this.state.auditionPop.show ? bottom + 43 : bottom + 8,
      },
    };

    this.setState({
      polyphonicBar,
      polyphonicEnum,
      actionHide: false,
    });
    this.onPopHide(true);
  }

  // 数字节点
  private numberNodeClick({ idx, tag }: { idx?: number; tag?: string }, e: any) {
    e.stopPropagation();
    const selection = this.getSelection();
    if (!selection) return;
    const { selectionText } = selection;
    let defaultEnums = [...numberDefaultEnum];
    if (utils.checkDate(selectionText)) {
      defaultEnums = dateEnum;
    }
    if (utils.checkTime(selectionText)) {
      defaultEnums = timeEnum;
    }
    // 修改
    if (idx !== undefined && idx > -1) {
      let { target } = e;
      // 父元素中心
      if (['rt-polyphonic-text', 'rt-tag'].includes(target.className)) target = target.parentNode;

      const { width, height, left, top } = target.getBoundingClientRect();
      let deleteLabel = t('移除数字标签');
      if (tag === 'date' || tag === 'time') {
        defaultEnums = [];
        deleteLabel = tag === 'date' ? t('移除日期标签') : t('移除时间标签');
      }
      const numberEnum: NumberEnum[] = [...defaultEnums];
      numberEnum.push({
        label: deleteLabel,
        value: 'remove',
        danger: true,
      });

      const numberBar: CommonBar = {
        show: true,
        idx,
        type: 'update',
        value: tag,
        position: {
          left: left - (52 - width) / 2,
          top: top + height + 7,
        },
      };
      this.setState({
        numberBar,
        numberEnum,
        actionHide: false,
      });
      this.onPopHide(true);
    } else {
      this.numberAdd(defaultEnums);
    }
  }

  // 动作节点点击
  private actionNodeClick({ idx, value }: { idx: number; value: string }, e: any) {
    e.stopPropagation();
    let { target } = e;
    // 父元素中心
    while (target.className.indexOf('rt-node') === -1) {
      target = target.parentNode;
    }

    const { left, top } = target.getBoundingClientRect();
    const actionBar: CommonBar = {
      show: true,
      idx,
      value,
      position: {
        left,
        top,
      },
    };
    // 设置动作置灰
    const disabledActionMap = this.getDisabledActionMap(idx, 'node');
    this.setStateSync({
      actionBar,
      disabledActionMap,
      actionHide: false,
    }).then(() => this.onPopHide(true));
  }

  // 局部语速节点点击
  localSpeedNodeClick(idx: number, e: any) {
    e.stopPropagation();
    const { target } = e;
    const { width, height, left, top } = target.getBoundingClientRect();
    const localSpeedBar = {
      show: true,
      position: {
        top: top + height + 7,
        left: left - (52 - width) / 2,
      },
      idx,
    };
    this.setStateSync({
      localSpeedBar,
    }).then(() => this.onPopHide(true));
  }

  private numberAdd(enums: NumberEnum[]) {
    const selection = this.getSelection();
    if (!selection) return;
    const { startIdx, endIdx } = selection;
    const nodeDomList = this.nodeDomList.slice(startIdx, endIdx);
    let rangeRight = 0;
    if (nodeDomList.length === 0) return;
    const firstNodeDom = nodeDomList[0];
    if (!firstNodeDom) return;
    const { top: rangeTop, left: rangeLeft } = firstNodeDom.getBoundingClientRect();
    nodeDomList.forEach((item) => {
      if (!item) return;
      const { top, right } = item.getBoundingClientRect();
      if (top === rangeTop && right > rangeRight) rangeRight = right;
    });
    const richTextEle = document.getElementById('rich-text') as HTMLElement;
    const { top: rtTop } = richTextEle.getBoundingClientRect();
    const isFirstLine = rangeTop - 35 < rtTop + 16 + 24;
    const numberBar: CommonBar = {
      show: true,
      idx: -1,
      type: 'new',
      position: {
        left: rangeLeft + (rangeRight - rangeLeft) / 2 - 25,
        top: isFirstLine && this.props.enableAudition ? rangeTop + 70 : rangeTop + 35,
      },
    };
    this.setState({
      numberBar,
      numberEnum: enums,
    });
  }

  /* ------ tag设置相关 ------*/
  private async onVisibleChange(name: string, visible: boolean) {
    // @ts-ignore
    this.setState({ [`${name}Focus`]: visible }); // 控制button选中状态
    if (!visible) {
      // TODO
      await this.setStateSync({ [`${name}Hide`]: true });
      await this.setStateSync({ [`${name}Hide`]: false });
    }
  }

  // 暂停按钮
  private pauseHandle() {
    this.holdFocus();
    const { blurIdx } = this.state;
    if (blurIdx === -1) return;
    this.setState({
      pauseHide: false,
      insertIdx: blurIdx,
    });
  }

  private addPause({ value: tag }: Row) {
    const { nodeList, insertIdx, globalSpeed } = this.state;
    const ahead = this.pauseIsInvalid(nodeList, insertIdx, false, false);
    const behind = this.pauseIsInvalid(nodeList, insertIdx, true, true);
    if (ahead || behind) {
      message.error(t('不支持连续插入停顿符，请重新操作'));
      return;
    }
    // 停顿不能包含在替换文本内
    const behindSubStartIndex = nodeList.findIndex((n, idx) => n.type === NODE_SUB_START && idx > insertIdx);
    const behindSubEndIndex = nodeList.findIndex((n, idx) => n.type === NODE_SUB_END && idx > insertIdx);
    if (behindSubEndIndex < behindSubStartIndex || (behindSubStartIndex === -1 && behindSubEndIndex !== -1)) {
      message.warning(t('【停顿】不能放在【替换文本】内'));
      return;
    }
    // 停顿不能包含在连续标签内
    const behindWordStartIndex = nodeList.findIndex((n, idx) => n.type === NODE_WORD_START && idx > insertIdx);
    const behindWordEndIndex = nodeList.findIndex((n, idx) => n.type === NODE_WORD_END && idx > insertIdx);
    if (behindWordEndIndex < behindWordStartIndex || (behindWordStartIndex === -1 && behindWordEndIndex !== -1)) {
      message.warning(t('【停顿】不能放在【连续】内'));
      return;
    }
    nodeList.splice(insertIdx, 0, pauseNode(tag));
    this.setState({ nodeList, insertIdx: -1 });
    this.onVisibleChange('pause', false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange(1);
    this.onAuditionHandle('stop');
  }

  private setPause({ value: tag }: Row) {
    const { nodeList, pauseBar, globalSpeed } = this.state;
    const { idx } = pauseBar;
    if (tag === t('移除停顿')) {
      nodeList.splice(idx, 1);
    } else {
      // @ts-ignore
      nodeList[idx].tag = tag;
      nodeList[idx].value = tag;
    }
    this.setState({
      nodeList,
      pauseBar: {
        ...pauseBar,
        show: false,
      },
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    this.onAuditionHandle('stop');
  }

  // 设置多音字
  private setPolyphonic({ label: tag, value }: Row) {
    const { nodeList, polyphonicBar, globalSpeed } = this.state;
    const { idx } = polyphonicBar;
    const node = nodeList[idx];
    const nodeText = node.text;
    if (!nodeText) return;
    // 多音字不能包含在替换文本内
    const behindSubStartIndex = nodeList.findIndex((n, index) => n.type === NODE_SUB_START && index > idx);
    const behindSubEndIndex = nodeList.findIndex((n, index) => n.type === NODE_SUB_END && index > idx);
    if (behindSubEndIndex < behindSubStartIndex || (behindSubStartIndex === -1 && behindSubEndIndex !== -1)) {
      message.warning(t('【多音字】不能放在【替换文本】内'));
      return;
    }
    // 多音字不能包含在连续文本内
    const behindWordStartIndex = nodeList.findIndex((n, index) => {
      return n.type === NODE_WORD_START && index > idx;
    });
    const behindWordEndIndex = nodeList.findIndex((n, index) => n.type === NODE_WORD_END && index > idx);
    if (behindWordEndIndex < behindWordStartIndex || (behindWordStartIndex === -1 && behindWordEndIndex !== -1)) {
      message.warning(t('【多音字】不能放在【连续】内'));
      return;
    }
    if (tag === t('移除多音标注')) {
      nodeList[idx] = textNode(nodeText);
    } else {
      nodeList[idx] = polyphonicNode(tag, nodeText, value);
    }
    this.setState({ nodeList });
    this.onPopHide(false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    this.onAuditionHandle('stop');
  }

  // 设置数字读音
  private setNumber(barType: string, { value: tag }: Row) {
    const { nodeList, numberBar, selection, globalSpeed } = this.state;
    const { startIdx, endIdx } = selection;
    const list = nodeList.slice(startIdx, endIdx);
    // 数值不能包含在替换文本内
    const behindSubStartIndex = nodeList.findIndex((n, idx) => n.type === NODE_SUB_START && idx > endIdx);
    const behindSubEndIndex = nodeList.findIndex((n, idx) => n.type === NODE_SUB_END && idx > endIdx);
    if (behindSubEndIndex < behindSubStartIndex || (behindSubStartIndex === -1 && behindSubEndIndex !== -1)) {
      message.warning(t('【数字标签】不能放在【替换文本】内'));
      return;
    }
    // 新建 直接添加数字多音节点
    if (barType === 'new') {
      let text = '';
      list.forEach((item) => (text += item.text));
      nodeList.splice(startIdx, endIdx - startIdx, numberNode(tag, text));
    } else {
      const { idx } = numberBar;
      const node = nodeList[idx] || {};
      const nodeText = node.text || '';
      if (tag === 'remove') {
        if (nodeText) {
          const numberTextList = nodeText.split('').map((item) => textNode(item));
          nodeList.splice(idx, 1, ...numberTextList);
        }
      } else {
        nodeList[idx] = numberNode(tag, nodeText);
      }
    }

    this.setState({ nodeList });
    this.onPopHide(false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    this.onAuditionHandle('stop');
  }

  // 动作按钮
  private async actionPaneHandle(e: any) {
    if (!e) return;
    e.preventDefault();
    this.holdFocus();
    // 展示前先做判断，此处是否可插入动作
    const { blurIdx: insertIdx, nodeList } = this.state;
    const { actionGroup } = this.props;
    // 无光标
    if (insertIdx === -1) {
      message.error(t('请先插入光标'));
      return;
    }
    const disabledActionMap = this.getDisabledActionMap(insertIdx, 'head');
    let actionLen = 0;
    actionGroup.forEach((item) => {
      actionLen += item.actionList.length;
    });

    // 全部都不能插入弹toast警告
    if (Object.keys(disabledActionMap).length === actionLen) {
      const nodeListInAction = this.getNodeListInAction(nodeList);
      const isInAction = !!nodeListInAction.find(([start, end]) => insertIdx >= start && insertIdx <= end);
      await this.setStateSync({
        actionHide: true,
        isInputError: true,
        inputErrorMsg: isInAction ? t('不可插入动作，已有设置，动作冲突') : t('预留字数不够'),
        inputIdx: insertIdx,
      }).then(() => this.inputRef?.focus());
      this.onVisibleChange('action', false);
      return;
    }

    this.setState({
      disabledActionMap,
      insertIdx,
    });
  }

  // 添加动作
  private addAction({ actionId, actionName }: { actionId: string; actionName: string }) {
    const { nodeList, insertIdx, globalSpeed } = this.state;
    let addNum = 0;
    if (
      insertIdx < nodeList.length - 2 &&
      nodeList[insertIdx - 1] &&
      nodeList[insertIdx + 1] &&
      nodeList[insertIdx - 1].sectionIndex !== nodeList[insertIdx + 1].sectionIndex
    ) {
      addNum = 1;
    }
    nodeList.splice(insertIdx, 0, actionNode(actionName, actionId));
    this.setState({
      nodeList,
      insertIdx: -1,
    });
    this.triggerSectionChange(addNum, insertIdx);
    this.onVisibleChange('action', false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.onAuditionHandle('stop');
  }

  // 设置动作
  private setAction(idx: number, { actionId, actionName }: { actionId: string; actionName: string }) {
    const { nodeList, globalSpeed } = this.state;
    const currNode = nodeList[idx];
    currNode.tag = actionName;
    currNode.value = actionId;
    this.setState({ nodeList });
    this.onPopHide(false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange(0, idx);
    this.onAuditionHandle('stop');
  }

  // 删除动作
  private delAction(idx: number) {
    const { nodeList, globalSpeed } = this.state;
    nodeList.splice(idx, 1);
    this.setState({ nodeList });
    this.onPopHide(false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange(0, idx);
    this.onAuditionHandle('stop');
  }

  // 智能动作 - 根据文本预测播报动作
  private analyzeSsml(isConfirmed?: boolean) {
    const { nodeList, globalSpeed, smartActionLoading } = this.state;
    if (!!smartActionLoading) return;
    const analyzerText = nodeList.map(({ text }) => text).join('');
    if (!analyzerText) {
      message.warning(t('预测文本不可为空'));
    }
    const { anchorCode } = this.props;
    if (nodeList.find((node) => node.type === NODE_ACTION) && !isConfirmed) {
      const that = this;
      confirm({
        title: t('开启智能动作后，原配置动作将被覆盖，请确认是否开启'),
        centered: true,
        onOk() {
          that.analyzeSsml(true);
        },
      });
      return;
    }
    this.setState({ smartActionLoading: true });
    // 注：当前接口仅支持text => ssml，会把ssml的已有配置都清空
    getAnalyzedSsml({ anchorCode, analyzerText, speed: parseFloat(globalSpeed) })
      .then(({ analyzerResult }) => {
        // 计算预测后每个node前纯文本长度
        const [analyzedNodeList] = ssmlToNodeList(analyzerResult);
        const analyzedNodeTextList: TextLengthNode[] = analyzedNodeList.map((node, idx) => {
          return {
            ...node,
            beforeTextLen: this.getNodeListTextLength(analyzedNodeList, 0, idx),
          };
        });
        // 标记node前纯文本数量
        const noTextNodeList: TextLengthNode[] = [];
        nodeList.forEach((node, idx) => {
          if (![NODE_TEXT, NODE_ACTION].includes(node.type)) {
            noTextNodeList.push({
              ...node,
              beforeTextLen: this.getNodeListTextLength(nodeList, 0, idx),
            });
          }
        });
        let insertTextNodeLength = 0;
        // 还原非纯文本node位置
        noTextNodeList.forEach((node, i) => {
          const insertNodeIdx = analyzedNodeTextList.findIndex((i) => i.beforeTextLen === node.beforeTextLen);
          if (insertNodeIdx !== -1) {
            // 每多添加一个node，后续node位置+1
            analyzedNodeList.splice(
              insertNodeIdx + i - insertTextNodeLength,
              node.text?.length || 0,
              lodashOmit(node, ['beforeTextLen']),
            );
            insertTextNodeLength += node.text?.length || 0;
          }
        });
        // this.setState({ nodeList: analyzedNodeList });
        this.props.onChange(nodeListToSsml(analyzedNodeList, true, globalSpeed), globalSpeed, true);
        const setionLength = this.props.curSections?.length || 0;
        this.triggerSectionChange(
          0,
          0,
          Array.from({ length: setionLength }, (_, i) => i),
        );
      })
      .finally(() => this.setState({ smartActionLoading: false }));
  }

  /* ------ 其他 ------*/
  // 添加局部语速
  onLocalSpeedAdd(speed: any) {
    const localSpeed = (speed * 100).toFixed();
    const { nodeList, globalSpeed, selection } = this.state;
    const {
      config: { localSpeed: localSeedConfig },
    } = this.props;
    if (localSeedConfig === 'disabled') {
      message.info(t('当前音色不支持局部语速'));
      return;
    }
    // const selection = this.getSelection();
    if (!selection) {
      console.warn(t('无选中区'));
      return;
    }
    let { startIdx, endIdx } = selection;

    if (
      startIdx > -1 &&
      endIdx > -1 &&
      nodeList[startIdx] &&
      nodeList[endIdx - 1] &&
      nodeList[startIdx].sectionIndex !== nodeList[endIdx - 1].sectionIndex
    ) {
      message.warning(t('【不同分句】不能同时设置【局部语速】'));
      return;
    }

    // 避免关闭语速弹窗，在富文本完弹起触发getSelection()更改startIdx&endIdx导致重复插入局部语速标签
    if (startIdx > 0 && nodeList[startIdx - 1].type === LOCAL_SPEED_START) startIdx -= 1;
    if (endIdx < nodeList.length && nodeList[endIdx].type === LOCAL_SPEED_END) endIdx -= 1;
    let list = nodeList.slice(startIdx, endIdx);
    // console.log(startIdx, endIdx, list);
    list = list.map((item) => ({
      ...item,
      localSpeed: true,
    }));
    nodeList.splice(startIdx, endIdx - startIdx, ...list);
    if (nodeList[startIdx].type === 'localSpeedStart') {
      // @ts-ignore
      nodeList[startIdx] = localSpeedStart(localSpeed);
      // @ts-ignore
      nodeList[endIdx + 1] = localSpeedEnd(localSpeed);
    } else {
      // @ts-ignore
      nodeList.splice(endIdx, 0, localSpeedEnd(localSpeed));
      // @ts-ignore
      nodeList.splice(startIdx, 0, localSpeedStart(localSpeed));
    }
    this.setState({
      nodeList,
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }

  // 删除局部语速
  onLocalSpeedDel() {
    const { localSpeedBar, globalSpeed } = this.state;
    const { idx } = localSpeedBar;
    const { nodeList } = this.state;
    nodeList.splice(idx, 1);
    for (let i = idx - 1; i >= 0; i--) {
      const node = nodeList[i];
      if (node.type === 'localSpeedStart') {
        let list = nodeList.slice(i + 1, idx);
        list = list.map(({ key, type, text, value, tag, inExpression, inSub, inWord }) => ({
          key,
          type,
          text,
          ...(value && {
            value,
          }),
          ...(tag && {
            tag,
          }),
          ...(inExpression && {
            inExpression,
          }),
          ...(inSub && {
            inSub,
          }),
          ...(inWord && {
            inWord,
          }),
        }));
        nodeList.splice(i, idx - i, ...list);
        break;
      }
    }
    this.setState({
      nodeList,
      localSpeedBar: {},
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }
  // 试听气泡定位
  auditionPopShow(localSpeed = false) {
    const selection = this.getSelection();
    if (!selection) {
      console.warn(t('无选中区'));
      return;
    }
    let { startElement }: any = selection;
    const { endElement, selectionText }: any = selection;
    // 不是在节点中，通过鼠标位置判断
    if (startElement.className.indexOf('rt-node') < 0) {
      const { y } = this.state.mouseUpInfo;
      for (let i = this.nodeDomList.length - 1; i >= 0; i--) {
        const node = this.nodeDomList[i];
        if (!node) continue;
        const { top } = node.getBoundingClientRect();
        if (top > y) {
          startElement = node;
          break;
        }
      }
    }
    const { left: rangeLeft, top: rangeTop, height: rangeHeight } = startElement.getBoundingClientRect();

    let maxRight = 0;
    const startIdx = parseInt(startElement.getAttribute('data-idx'), 10);
    const endIdx = parseInt(endElement.getAttribute('data-idx'), 10);
    for (let i = startIdx; i <= endIdx; i++) {
      const node = this.nodeDomList[i];
      // @ts-ignore
      const { right, top } = node.getBoundingClientRect();
      if (top > rangeTop) break;
      maxRight = Math.max(maxRight, right);
    }
    const richTextEle = document.getElementById('rich-text') as HTMLElement;
    const { top: rtTop } = richTextEle.getBoundingClientRect();
    // 如第一行选中文本top + 试听pop高度 < 富文本框top + paddingTop + 按钮高度，试听pop挪到文本下方
    const isFirstLine = rangeTop - 35 < rtTop + 16 + 24;
    const auditionPop = {
      show: true,
      left: rangeLeft + (maxRight - rangeLeft) / 2,
      top: isFirstLine ? rangeTop + rangeHeight + 5 : rangeTop - 35,
      placement: isFirstLine ? 'bottom' : 'top',
      localSpeed,
    };

    this.setState(
      {
        auditionPop,
      },
      () => this.onPopHide(true),
    );
  }

  onAuditionHandle(type: string) {
    if (type === 'init') {
      this.onAuditionInit();
    } else {
      this.handle(type, null, null);
      if (type === 'stop') {
        this.setState({
          sentenceSection: [],
          selection: {
            startIdx: 0,
            endIdx: 0,
            selectionText: '',
          },
          blurIdx: -1,
        });
        this.onPopHide(false);
        this.actionPaneHandle(false);
      }
    }
  }

  handle(type: string, ssmlList?: any, globalSpeed?: any) {
    switch (type) {
      case 'standby':
        break;
      case 'init': {
        const auditionText = ssmlList?.map((ssml: string) => utils.transformXml2Text(ssml)).join('') || '';
        if (auditionText.length > 100) {
          message.warning(t('试听内容过长（${auditionText.length}/100字）', { length: auditionText.length }));
        } else {
          auditionLoading({
            ssmlList,
            globalSpeed,
          });
        }
        break;
      }
      case 'play':
        auditionPlay();
        break;
      case 'pause':
        auditionPause();
        break;
      case 'stop':
        auditionStop(t('因您已操作修改文本，已为您自动终止试听'));
        break;
      default:
        return;
    }
  }

  onAuditionHide() {
    this.onPopHide(false);
  }

  // 播放文字初始化：1 分段(100字后第一个标点截断)，2 记录分段节点位置 3，转为模型可识别的ssml
  onAuditionInit() {
    let { nodeList } = this.state;
    const { blurIdx, globalSpeed, selection } = this.state;
    let { startIdx, endIdx } = selection;
    const { selectionText } = selection;
    // 无光标
    if (blurIdx === -1 && startIdx === endIdx) {
      // console.error('从头到尾播放 1 无光标');
    } else if (blurIdx !== -1) {
      // 有光标
      if (blurIdx === nodeList.length) {
        startIdx = 0;
        endIdx = blurIdx - 1;
        // console.error('从头到尾播放 2 光标在末尾');
      } else {
        nodeList = this.localSpeedCheck(blurIdx, nodeList.length - 1); // 选中区间的文字
        // console.error('从光标到尾播放');
      }
    } else {
      nodeList = this.localSpeedCheck(startIdx, endIdx); // 选中区间的文字
      // console.error('选中文字播放');
    }
    if (startIdx === endIdx) {
      // 选中数字标签
      if (nodeList[startIdx].type === 'number') {
        nodeList = this.localSpeedCheck(startIdx, endIdx + 1);
      } else {
        startIdx = blurIdx === -1 ? 0 : blurIdx;
      }
    }
    nodeList = filterSinglePareNode(nodeList);
    const { ssmlList, sentenceSection } = this.onTextSlice(nodeList, startIdx);
    let utf8Length = 0;
    ssmlList.forEach((text) => {
      utf8Length += commonUtils.getLengthOfUtf8(commonUtils.transformXml2Text(text).trim());
    });
    // tts播报文本utf8编码字符最小长度
    if (utf8Length < minUtf8TTSLength) {
      message.error(t('字数过短'));
      return;
    }
    this.setState({
      sentenceSection,
      selection: {
        startIdx: 0,
        endIdx: 0,
        selectionText: '',
      },
    });
    this.handle('init', ssmlList, globalSpeed);
  }

  // 检测局部语速情况，返回完整标签的nodeList
  localSpeedCheck(startIdx: number, endIdx: number) {
    const { nodeList } = this.state;
    let sectionList = nodeList.slice(startIdx, endIdx);
    let hasEnd = false;
    let hasStart = false;
    // 正序检索End, 有则在前面加一个Start
    for (let i = 0, len = sectionList.length; i < len; i++) {
      const node = sectionList[i];
      // @ts-ignore
      if (node.type === 'localSpeedStart') break;
      // @ts-ignore
      if (node.type === 'localSpeedEnd') {
        // @ts-ignore
        sectionList.unshift(localSpeedStart(node.value));
        hasEnd = true;
        break;
      }
    }
    // 倒叙检索Start，有则在后面加一个End
    for (let i = sectionList.length - 1; i >= 0; i--) {
      const node = sectionList[i];
      // @ts-ignore
      if (node.type === 'localSpeedEnd') break;
      // @ts-ignore
      if (node.type === 'localSpeedStart') {
        // @ts-ignore
        sectionList.push(localSpeedEnd(node.tag));
        hasStart = true;
        break;
      }
    }
    // 区间内没有Start和End，则用双指针向前向后分别检索是否区间外面有局部语速标签,
    if (!hasStart && !hasEnd) {
      let idx = 0;
      const len = nodeList.length;
      while (startIdx - idx - 1 >= 0 && endIdx + idx < len) {
        const frontNode = nodeList[startIdx - idx - 1];
        const behindNode = nodeList[endIdx + idx];
        const { type: frontType, tag: frontTag } = frontNode;
        const { type: behindType, tag: behindTag } = behindNode;
        // 向前检索到End或者到头部无Start，向后检索到Start或者到尾部无End，则不在局部语速内
        // @ts-ignore
        if (frontType === 'localSpeedEnd') break;
        // @ts-ignore
        if (behindType === 'localSpeedStart') break;
        // 检索到其中一项，则证明在局部语速内，需要在前后加上局部语速标签
        // @ts-ignore
        if (frontType === 'localSpeedStart') {
          // @ts-ignore
          sectionList = [localSpeedStart(frontTag), ...sectionList, localSpeedEnd(frontTag)];
          break;
        }
        // @ts-ignore
        if (behindType === 'localSpeedEnd') {
          // @ts-ignore
          sectionList = [localSpeedStart(behindTag), ...sectionList, localSpeedEnd(behindTag)];
          break;
        }
        idx += 1;
      }
    }
    return sectionList;
  }

  // 文字分片
  onTextSlice(paramNodeList: RTNode[], startIdx = 0, size = 100) {
    let nodeList = paramNodeList;
    const { globalSpeed, nodeList: list } = this.state;
    if (!nodeList) nodeList = list;
    const sentenceList = []; // 分段语句列表
    const sentenceSection = []; // 截断位置
    let floor: any = [];
    let idx = 0;
    let sentenceIdx = 0;
    let cacheList: RTNode[] = [];
    const textRegExp = /^[a-zA-Z0-9_\u4e00-\u9fa5\x20]+$/; // 单个空格不拆句兼容英文
    let localSpeed = globalSpeed;
    let inLocalSpeed = false;

    const append = (item: any) => {
      idx += item.text?.length || 0;
      cacheList.push(item);
    };
    const end = (item: any) => {
      // 在局部语速中截断，需要在末尾补上localSpeedEnd标签，然后在第二段头部添加localSpeedStart标签
      if (inLocalSpeed) {
        sentenceList[sentenceIdx] = [...cacheList, item, localSpeedEnd(localSpeed)];
        cacheList = [localSpeedStart(localSpeed)];
      } else {
        sentenceList[sentenceIdx] = [...cacheList, item];
        cacheList = [];
      }
      sentenceIdx += 1;
      idx = 0;
    };

    // 确定起始位置
    floor.push(startIdx);
    nodeList.forEach((item: any, i: any) => {
      const { text, type, tag } = item;
      const hasSymbol = !textRegExp.test(text);
      if (type === 'localSpeedStart') {
        localSpeed = tag;
        inLocalSpeed = true;
      } else if (type === 'localSpeedEnd') {
        localSpeed = globalSpeed;
        inLocalSpeed = false;
      }

      const behindText = nodeList
        .slice(i + 1, nodeList.length)
        .filter((n) => n.type === 'text')
        .map((node) => node.text)
        .join('');
      const behindTextUtf8Length = commonUtils.getLengthOfUtf8(behindText);

      const beforeText = cacheList
        .filter((n) => n.type === 'text')
        .map((node) => node.text)
        .join('');
      const beforeTextUtf8Length = commonUtils.getLengthOfUtf8(beforeText);
      // 遇换行&前后文本utf8编码长度不小于4&前后文本存在中英文截断
      if (
        type === 'wrap' &&
        beforeTextUtf8Length >= minUtf8TTSLength &&
        behindTextUtf8Length >= minUtf8TTSLength &&
        /[a-zA-Z0-9\u4e00-\u9fa5]/.test(behindText) &&
        /[a-zA-Z0-9\u4e00-\u9fa5]/.test(beforeText)
      ) {
        if (!list.slice(floor[0], startIdx + i).filter((n) => n.type === 'text').length) {
          floor = [startIdx + i + 1];
          cacheList = [];
        } else {
          floor.push(startIdx + i);
          sentenceSection.push(floor);
          floor = [startIdx + i + 1];
          end(item);
        }
        // 添加局部语速
      } else if (idx >= size) {
        // 有标点&标点后文本utf8编码长度不小于4&前后文本存在中英文截断
        if (hasSymbol && /[a-zA-Z\u4e00-\u9fa5]/.test(behindText) && behindTextUtf8Length >= minUtf8TTSLength) {
          floor.push(startIdx + i);
          sentenceSection.push(floor);
          floor = [startIdx + i + 1];
          end(item);
        } else {
          append(item);
        }
      } else {
        append(item);
      }
    });
    // 结尾位置
    if (cacheList.length) {
      floor.push(startIdx + nodeList.length - 1);
      sentenceSection.push(floor);
      sentenceList[sentenceIdx] = [...cacheList];
    }
    const ssmlList = sentenceList.map((item) => nodeListToSsml(item, true, globalSpeed));
    return {
      ssmlList,
      sentenceSection,
    };
  }

  // 隐藏所有pop
  private onPopHide(tigger?: boolean) {
    const func = () => {
      const {
        pauseBar,
        polyphonicBar,
        numberBar,
        actionBar,
        expressionBar,
        localSpeedBar,
        auditionPop,
        wordBar,
        subBar,
      } = this.state;
      if (
        !polyphonicBar.show &&
        !pauseBar.show &&
        !numberBar.show &&
        !actionBar.show &&
        !expressionBar.show &&
        !auditionPop.show &&
        !localSpeedBar.show &&
        !wordBar.show &&
        !subBar.show
      )
        return;
      this.setState({
        pauseBar: {
          ...pauseBar,
          show: false,
        }, // 需要清空语速bar
        polyphonicBar: {
          ...polyphonicBar,
          show: false,
        },
        numberBar: {
          ...numberBar,
          show: false,
        },
        actionBar: {
          ...actionBar,
          show: false,
        },
        expressionBar: {
          ...expressionBar,
          show: false,
        },
        auditionPop: {
          ...auditionPop,
          show: false,
        },
        localSpeedBar: {
          ...localSpeedBar,
          show: false,
        },
        wordBar: {
          ...wordBar,
          show: false,
        },
        subBar: {
          ...subBar,
          show: false,
        },
      });
    };
    // 需要触发
    if (tigger) {
      window.removeEventListener('mousedown', func);
      window.addEventListener('mousedown', func);
    } else {
      // 直接执行
      func();
      window.onclick = null; // 解绑按钮上的click事件
    }
  }

  /* ------ modal ------*/
  private modalHandle(key: string, show: boolean, type: string) {
    // @ts-ignore
    let modal = this.state[key];
    modal = {
      ...modal,
      show,
      ...(type && { type }),
    };
    // @ts-ignore
    this.setState({ [key]: modal });
  }

  /*
   * @params type              string          插入类型  输入，导入，粘贴
   * @params insertIdxOrRange  number | array  插入坐标  1 | [1,2]
   * @params insertList        array           插入列表  NodeList
   * */
  // 1 插入文本（入口）
  private insertText({
    type,
    insertIdx: paramInsertIdx,
    insertList: paramInsertList,
    insertRange,
    insertFunc,
  }: {
    type: string;
    insertIdx?: number;
    insertRange?: InsertRange;
    insertList: RTNode[];
    insertFunc?: (params: InsertInfo & { type: string }) => void;
  }) {
    // 缓存实参
    let insertList = paramInsertList || [];
    let insertIdx = paramInsertIdx;
    const { maxSize } = this.props;
    const { size, nodeList } = this.state;
    let insertSize = 0; // TODO 是否可以少一个循环
    insertList.forEach((item) => {
      insertSize += item.text?.length || 0;
    });
    if (insertRange) {
      // 选区插入
      const { startIdx, endIdx } = insertRange;
      const delSize = endIdx - startIdx;
      // 超长，只能插入部分
      if (size - delSize + insertSize > maxSize) {
        const insertMaxSize = maxSize - size + delSize;
        insertList = this.listSplice(insertList, insertMaxSize);
        this.insertPartText({
          insertRange,
          insertList,
          type,
        });
      } else {
        this.insertFullText({
          insertRange,
          insertList,
        });
      }
    } else {
      // 光标插入
      if (insertIdx === -1) insertIdx = nodeList.length;
      // 禁止导入
      if (size >= maxSize) {
        this.modalHandle('preventModal', true, type);
        return;
      }
      // 超长，只能插入部分
      if (size + insertSize > maxSize) {
        const insertMaxSize = maxSize - size;
        insertList = this.listSplice(insertList, insertMaxSize);
        this.insertPartText({
          insertIdx,
          insertList,
          type,
        });
      } else {
        if (insertFunc) {
          insertFunc({
            insertIdx,
            insertList,
            type,
          });
        } else {
          this.insertFullText({
            insertIdx,
            insertList,
          });
        }
      }
    }
  }

  // 按照字数修剪列表
  private listSplice(insertList: RTNode[], maxSize: number) {
    const list = [];
    let textNum = 0;
    for (let i = 0, len = insertList.length; i < len; i++) {
      const node = insertList[i];
      textNum += node.text?.length || 0;
      if (textNum > maxSize) break;
      list.push(node);
    }
    return list;
  }

  // 2 插入部分文本
  private insertPartText({
    insertIdx,
    insertRange,
    insertList,
    type,
  }: InsertInfo & {
    type: string;
  }) {
    this.setState({
      insertInfo: {
        ...(insertIdx && { insertIdx }),
        ...(insertRange && { insertRange }),
        insertList,
      },
    });
    this.modalHandle('partModal', true, type);
  }

  // 3 部分插入确认
  private insertPartConfirm() {
    let { size, nodeList } = this.state;
    const {
      insertInfo: { insertIdx = -1, insertRange, insertList }, // ****** insertIdx 默认值
      globalSpeed,
    } = this.state;

    let insertSize = 0;
    insertList?.forEach((item) => {
      insertSize += item.text?.length || 0;
    });

    if (insertRange) {
      const { startIdx, endIdx } = insertRange;
      size = size - (endIdx - startIdx) + insertSize;
      const delInfo = this.delListWithPareNode(nodeList, startIdx, endIdx, insertList);
      nodeList = delInfo.nodeList;
      // console.error('insertPartConfirm 选区 插入部分成功');
    } else {
      size = size + insertSize;
      nodeList.splice(insertIdx, 0, ...(insertList || []));
      // console.error('insertPartConfirm 光标 插入部分成功');
    }
    this.setState({
      nodeList,
      size,
    });
    this.partModalHide();
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }

  // 2 普通插入
  private insertFullText({ insertIdx = -1, insertRange, insertList }: InsertInfo) {
    // ****** insertIdx，默认值
    let { size, inputIdx, nodeList } = this.state;

    const { globalSpeed } = this.state;

    let insertSize = 0;
    insertList?.forEach((item) => {
      if (item.type === 'text' && item.text && /[\u0B80-\u0BFF]+/g.test(item.text)) {
        insertSize += 1;
      } else {
        insertSize += item.text?.length || 0;
      }
    });
    // 光标改变
    if (inputIdx !== -1) inputIdx += (insertList || []).length;
    if (insertRange) {
      const { startIdx, endIdx } = insertRange;
      inputIdx = startIdx + (insertList || []).length;
      size = size - (endIdx - startIdx) + insertSize;
      const delInfo = this.delListWithPareNode(nodeList, startIdx, endIdx, insertList);
      nodeList = delInfo.nodeList;
      this.setStateSync({
        inputIdx,
        nodeList,
        size,
      }).then(() => this.inputRef?.focus());
      // console.error('正常选区', type);
    } else {
      size = size + insertSize;
      nodeList.splice(insertIdx, 0, ...(insertList || []));
      this.setState({
        inputIdx,
        nodeList,
        size,
      });
      // console.error('正常光标', type, inputIdx);
    }
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }

  private partModalHide() {
    const { partModal } = this.state;
    partModal.show = false;
    this.setState({ partModal });
  }

  /* ------ 标签失效判断 ------*/

  // 暂停是否不可用
  private pauseIsInvalid(nodeList: RTNode[], idx: number, behind = true /* 向后检测*/, selfStart?: boolean): boolean {
    let n = -1;
    if (behind) {
      n = selfStart ? 0 : 1;
    }
    let invalid = false;
    while (nodeList[idx + n] && invalid === false) {
      const node = nodeList[idx + n];
      const { type, text = '' } = node;
      if (['input', 'wrap', 'action'].includes(type)) {
        if (behind) n += 1;
        else n -= 1;
      } else if (type === 'text' && [' ', ' '].includes(text)) {
        // 这两个空格不一样,编辑器中的空格和浏览器中的空格有差别
        if (behind) n += 1;
        else n -= 1;
      } else if (type === 'pause') {
        invalid = false;
        break;
      } else {
        break;
      }
    }
    return invalid;
  }

  // 动作是否不可用
  private actionIsInvalid(nodeList: RTNode[], paramIdx: number) {
    // 缓存实参
    let idx = paramIdx;
    const { globalSpeed, actionMap, actionRules, maxCountMap } = this.state;
    const {
      modelSource,
      config: { actionTts },
    } = this.props;

    if (idx < 0 || !actionMap) return false;
    const thisNode: RTNode = nodeList[idx];
    const thisActionId = thisNode.value;
    if (!thisActionId) return false;
    const thisAction = actionMap[thisActionId];
    if (!thisAction) return false;
    const thisDurBefore = thisAction.durBefore;
    const thisDurAfter = thisAction.durAfter;
    if (modelSource && ziyanSourceList.includes(modelSource) && actionTts !== 'none') {
      const { beforeTtsTime, afterTtsTime } = this.getTtsTimeBetweenAction(idx);
      // console.log('actionIsInvalid', { idx, thisDurBefore, thisDurAfter, beforeTtsTime, afterTtsTime });
      if (thisDurBefore > beforeTtsTime) return true;
    } else {
      let count = 0;
      const maxCount = maxCountMap[globalSpeed];
      idx -= 1;
      while (idx >= 0 && count <= maxCount) {
        const beforeNode = nodeList[idx];
        const { type, text = '', value = '' } = beforeNode;
        switch (type) {
          case 'input':
          case 'pause':
          case 'localSpeedStart':
          case 'localSpeedEnd':
          case NODE_EXPRESSION_START:
          case NODE_EXPRESSION_END:
          case NODE_WORD_START:
          case NODE_WORD_END:
          case NODE_SUB_START:
          case NODE_SUB_END:
            idx -= 1;
            break;
          case 'text':
          case 'wrap':
          case 'polyphonic':
            idx -= 1;
            count += 1;
            break;
          case 'number':
            idx -= 1;
            count += text.length;
            break;
          case 'action': {
            if (!this.actionIsInvalid(nodeList, idx)) {
              const beforeActionId = value;
              const beforeAction = actionMap[beforeActionId];
              const beforeDurAfter = beforeAction?.durAfter;
              const duration = Math.max(Math.ceil((beforeDurAfter + thisDurBefore) / 1000), 0);
              const minWordCount = actionRules[`${duration}-${globalSpeed}`]; // 距前节点最小数量
              if (minWordCount > count) return true; // 失效
              return false;
            }
            idx -= 1;
            break;
          }
          default:
            idx -= 1;
            console.error(t('存在未知节点'));
            break;
        }
      }
    }

    return false;
  }

  // 预览
  private preview = () => {
    const { nodeList, globalSpeed } = this.state;
    this.setState({ previewTime: +new Date(), previewSsml: nodeListToSsml(nodeList, true, globalSpeed) });
  };

  private previewModalClose = () => {
    this.setState({ previewTime: undefined });
  };

  // 表情选择列表
  private expressionPaneHandle = (e: Event) => {
    e.preventDefault();
    const { selection, nodeList, expressionFocus, selectionInExpression } = this.state;
    if (!expressionFocus && (!selectionInExpression || selection.selectionText.length < expressionMinTextLength)) {
      message.warning(t('请先选中要插入表情的文本'));
      throw new Error(t('请先选中要插入表情的文本'));
    }
    let { startIdx: expressionStartIdx, endIdx: expressionEndIdx } = selection;
    // 选中中包含表情标签时去除
    // console.log('expressionStartIdx0', expressionStartIdx, 'expressionEndIdx0', expressionEndIdx);
    if (nodeList[expressionStartIdx]?.type === NODE_EXPRESSION_END) expressionStartIdx += 1;
    if (nodeList[expressionEndIdx - 1]?.type === NODE_EXPRESSION_START) expressionEndIdx -= 1;
    // console.log('expressionStartIdx', expressionStartIdx, 'expressionEndIdx', expressionEndIdx);
    this.setState({
      expressionStartIdx,
      expressionEndIdx,
    });
  };
  // 插入指定表情
  private addExpression = ({ actionId }: { actionId: string }) => {
    const { nodeList, globalSpeed, expressionStartIdx, expressionEndIdx } = this.state;
    if (
      expressionStartIdx > -1 &&
      expressionEndIdx > -1 &&
      nodeList[expressionStartIdx] &&
      nodeList[expressionEndIdx - 1] &&
      nodeList[expressionStartIdx].sectionIndex !== nodeList[expressionEndIdx - 1].sectionIndex
    ) {
      message.warning(t('【不同分句】不能放在【表情】内'));
      return;
    }
    let list = nodeList.slice(expressionStartIdx, expressionEndIdx);
    list = list.map((item) => ({
      ...item,
      inExpression: true,
    }));
    nodeList.splice(expressionStartIdx, expressionEndIdx - expressionStartIdx, ...list);
    if (nodeList[expressionStartIdx].type === NODE_EXPRESSION_START) {
      nodeList[expressionStartIdx] = expressionStartNode(actionId);
      nodeList[expressionEndIdx + 1] = expressionEndNode(actionId);
    } else {
      nodeList.splice(expressionEndIdx, 0, expressionEndNode(actionId));
      nodeList.splice(expressionStartIdx, 0, expressionStartNode(actionId));
    }
    this.setState({
      nodeList,
    });
    this.onVisibleChange('expression', false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    setTimeout(() => {
      this.triggerSectionChange(1, this.state.expressionEndIdx);
    }, 300);
  };
  // 获取nodeList中endIdx前纯文本长度
  private getNodeListTextLength(nodeList: RTNode[], startIdx = 0, endIdx = 0) {
    return nodeList
      .slice(startIdx, endIdx)
      .map((i) => i.text)
      .join('').length;
  }
  // 已插入表情标签是否可用
  private isExpressionInvalid = (nodeIdx: number, type: string) => {
    let textLen = 0;
    const { nodeList } = this.state;
    // 表情开始结束标签中间文本长度至少为5
    if (type === NODE_EXPRESSION_START) {
      const nextEndIdx = nodeList.findIndex((node, idx) => node.type === NODE_EXPRESSION_END && idx > nodeIdx);
      if (nextEndIdx !== -1) textLen = this.getNodeListTextLength(nodeList, nodeIdx, nextEndIdx);
    } else if (type === NODE_EXPRESSION_END) {
      const preStartIdx = nodeList.findLastIndex((node, idx) => node.type === NODE_EXPRESSION_START && nodeIdx > idx);
      if (preStartIdx !== -1) textLen = this.getNodeListTextLength(nodeList, preStartIdx, nodeIdx);
    }
    return textLen < expressionRecommendTextLength;
  };
  // 表情标签点击
  private expressionNodeClick = ({ idx, value }: { idx: number; value: string }, e: any) => {
    e.stopPropagation();
    let { target } = e;
    // 父元素中心
    while (target.className.indexOf('rt-node') === -1) {
      target = target.parentNode;
    }

    const { left, top } = target.getBoundingClientRect();
    const expressionBar: CommonBar = {
      show: true,
      idx,
      value,
      position: {
        left,
        top,
      },
    };
    // 设置表情置灰，置灰表情可替换
    const { expressionMap } = this.state;
    const disabledExpressionMap = {};
    // const disabledExpressionMap = this.isExpressionInvalid(idx, NODE_EXPRESSION_START) ? { ...expressionMap } : {};
    this.setStateSync({
      expressionBar,
      disabledExpressionMap,
      expressionHide: false,
    }).then(() => this.onPopHide(true));
  };
  // 更换表情
  private setExpression = (idx: number, { actionId }: { actionId: string }) => {
    const { nodeList, globalSpeed } = this.state;
    const curNode = nodeList[idx];
    curNode.tag = actionId;
    curNode.value = actionId;
    this.setState({ nodeList });
    this.onPopHide(false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  };
  // 移除表情标签
  private delExpression = (nodeIdx: number) => {
    const { nodeList, globalSpeed } = this.state;
    nodeList.splice(nodeIdx, 1);
    for (let i = nodeIdx; i <= nodeList.length; i++) {
      const node = nodeList[i];
      if (node.type === NODE_EXPRESSION_END) {
        let list = nodeList.slice(nodeIdx, i);
        list = list.map(({ key, type, text, value, tag, localSpeed, inWord, inSub }) => ({
          key,
          type,
          text,
          ...(value && {
            value,
          }),
          ...(tag && {
            tag,
          }),
          ...(localSpeed && {
            localSpeed,
          }),
          ...(inWord && {
            inWord,
          }),
          ...(inSub && {
            inSub,
          }),
        }));
        nodeList.splice(nodeIdx, i - nodeIdx + 1, ...list);
        break;
      }
    }
    this.onPopHide(false);
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange(0, nodeIdx);
  };

  // 区间删除成对标签
  private delListWithPareNode(nodeList: RTNode[], startIdx: number, endIdx: number, insertNodes?: RTNode[]) {
    const delList = nodeList.slice(startIdx, endIdx);
    let delNum = 0;
    for (let i = delList.length - 1; i >= 0; i--) {
      const node = delList[i];
      if (!!PARE_END_NODE_MAP[node.type]) break;
      if (!!PARE_START_NODE_MAP[node.type]) {
        for (let j = endIdx, len = nodeList.length; j < len; j++) {
          const { type } = nodeList[j];
          if (PARE_START_NODE_MAP[node.type].endNodeType === type) {
            let list = nodeList.slice(endIdx - i, j);
            const { tag } = PARE_START_NODE_MAP[node.type];
            list = list.map((item) => {
              const newItem = { ...item };
              newItem[tag] = false;
              return newItem;
            });
            nodeList.splice(endIdx - i, j - (endIdx - i) + 1, ...list);
            break;
          }
        }
        break;
      }
    }
    // 从区间前向后找End，找到后向前找Start的位置
    for (let i = 0, len = delList.length; i < len; i++) {
      const node = delList[i];
      if (!!PARE_START_NODE_MAP[node.type]) break;
      if (!!PARE_END_NODE_MAP[node.type]) {
        for (let j = startIdx; j >= 0; j--) {
          const { type } = nodeList[j];
          if (PARE_END_NODE_MAP[node.type].startNodeType === type) {
            let list = nodeList.slice(j + 1, startIdx + i);
            const { tag } = PARE_END_NODE_MAP[node.type];
            list = list.map((item) => {
              const newItem = { ...item };
              newItem[tag] = false;
              return newItem;
            });
            nodeList.splice(j, list.length + 1, ...list);
            delNum = 1;
            break;
          }
        }
        break;
      }
    }
    if (insertNodes && insertNodes instanceof Array) {
      nodeList.splice(startIdx - delNum, endIdx - startIdx, ...insertNodes);
    } else {
      nodeList.splice(startIdx - delNum, endIdx - startIdx);
    }
    return {
      nodeList,
      delList,
      delNum,
    };
  }

  // 获取被动作占用的nodeIdx区间
  private getNodeListInAction(nodeList: RTNode[]) {
    const { actionMap, actionRules, globalSpeed } = this.state;
    // console.log('getNodeListInAction', actionMap, actionRules);
    const {
      modelSource,
      config: { actionTts },
    } = this.props;
    const nodeListInAction: number[][] = [];
    const textTypes = [NODE_NUMBER, NODE_TEXT, NODE_PINYIN];
    nodeList.forEach((node, idx) => {
      if (node.type === NODE_ACTION) {
        const thisActionId = node.value;
        if (!thisActionId) return;
        const thisAction = actionMap[thisActionId];
        if (!thisAction) return;
        const { durBefore, durAfter } = thisAction;
        let [beforeTextInActionLength, afterTextInActionLength] = [0, 0];
        if (modelSource && ziyanSourceList.includes(modelSource) && actionTts !== 'none') {
          ({ beforeTextInActionLength, afterTextInActionLength } = this.getTtsTimeBetweenAction(
            idx,
            durBefore,
            durAfter,
          ));
        } else {
          // pcg_ai 动作mock插入时长字数
          const beforeTime = Math.min(Math.max(Math.ceil(durBefore / 1000), 0), 12);
          beforeTextInActionLength = actionRules[`${beforeTime}-${globalSpeed}`];
          const afterTime = Math.min(Math.max(Math.ceil(durAfter / 1000), 0), 12);
          afterTextInActionLength = actionRules[`${afterTime}-${globalSpeed}`];
        }
        let [beforeTextIndex, afterTextIndex] = [idx, idx];
        if (!!beforeTextInActionLength) {
          let count = 0;
          let i = idx - 1;

          while (i >= 0 && count < beforeTextInActionLength) {
            if (textTypes.includes(nodeList[i].type)) {
              count += 1;
            }
            i -= 1;
          }

          beforeTextIndex = i + 1;
        }

        if (!!afterTextInActionLength) {
          let count = 0;
          let i = idx + 1;

          while (i < nodeList.length && count < afterTextInActionLength) {
            if (textTypes.includes(nodeList[i].type)) {
              count += 1;
            }
            i += 1;
          }

          afterTextIndex = i - 1;
        }
        // if (!!beforeTextInActionLength)
        //   beforeTextIndex = nodeList.findLastIndex(
        //     (n, index) =>
        //       textTypes.includes(n.type) &&
        //       nodeList.filter((i, textIdx) => textTypes.includes(i.type) && textIdx >= index && textIdx < idx)
        //         .length === beforeTextInActionLength,
        //   );

        // if (!!afterTextInActionLength)
        //   afterTextIndex = nodeList.findIndex(
        //     (n, index) =>
        //       textTypes.includes(n.type) &&
        //       nodeList.filter((i, textIdx) => textTypes.includes(i.type) && textIdx <= index && textIdx > idx)
        //         .length === afterTextInActionLength,
        //   );
        // console.log({ beforeTextInActionLength, afterTextInActionLength, beforeTextIndex, afterTextIndex });
        nodeListInAction.push([
          beforeTextIndex === -1 ? 0 : beforeTextIndex,
          afterTextIndex === -1 ? nodeList.length - 1 : afterTextIndex,
        ]);
      }
    });
    // console.log('nodeListInAction222', nodeListInAction);
    return nodeListInAction;
  }

  // 切换主播检测or过滤不支持的动作&表情标签
  onActionExpressionCheck(
    newActionMap: { [key: string]: string },
    newExpressionMap: { [key: string]: string },
    filter?: boolean,
  ) {
    const { globalSpeed, nodeList, expressionMap, actionMap } = this.state;
    // 检测出新形象不支持的动作&表情，去重
    const filterActionList = [
      ...new Set(
        nodeList
          .filter(({ type, value }) => type === NODE_ACTION && !!value && !newActionMap[value])
          .map(({ value }) => value),
      ),
    ].map((value) => {
      let tag = '';
      if (!!value) {
        tag = actionMap[value] ? actionMap[value].actionName : value;
      }
      return { value, tag };
    });
    const filterExpressionList = [
      ...new Set(
        nodeList
          .filter(({ type, value }) => type === NODE_EXPRESSION_START && !!value && !newExpressionMap[value])
          .map(({ value }) => value),
      ),
    ].map((value) => {
      let tag = '';
      if (!!value) {
        tag = expressionMap[value] ? expressionMap[value].actionName : value;
      }
      return { value, tag };
    });
    // 确认过滤动作&表情标签
    if (!!filter) {
      let filterNodeList = [...nodeList];
      if (!!filterExpressionList.length) {
        // 删除表情区间文本tag
        let tempStartExpressionIdx: undefined | number = undefined;
        filterNodeList = nodeList.map((node, idx) => {
          const { key, type, text, value, tag, localSpeed } = node;
          if (
            type === NODE_EXPRESSION_START &&
            filterExpressionList.findIndex(({ value: filterValue }) => value === filterValue) !== -1
          ) {
            tempStartExpressionIdx = idx;
          }
          if (
            type === NODE_EXPRESSION_END &&
            filterExpressionList.findIndex(({ value: filterValue }) => value === filterValue) !== -1
          ) {
            tempStartExpressionIdx = undefined;
          }

          return tempStartExpressionIdx !== undefined
            ? {
                key,
                type,
                text,
                value,
                tag,
                ...(value && {
                  value,
                }),
                ...(tag && {
                  tag,
                }),
                ...(localSpeed && {
                  localSpeed,
                }),
              }
            : {
                ...node,
              };
        });
      }
      // 删除动作&表情标签
      filterNodeList = filterNodeList.filter(
        ({ type, value }) =>
          ![NODE_ACTION, NODE_EXPRESSION_START, NODE_EXPRESSION_END].includes(type) ||
          (type === NODE_ACTION &&
            filterActionList.findIndex(({ value: filterValue }) => value === filterValue) === -1) ||
          ([NODE_EXPRESSION_START, NODE_EXPRESSION_END].includes(type) &&
            filterExpressionList.findIndex(({ value: filterValue }) => value === filterValue) === -1),
      );

      this.setState({
        nodeList: filterNodeList,
      });
      this.props.onChange(nodeListToSsml(filterNodeList, true, globalSpeed), globalSpeed);
      this.triggerSectionChange();
    } else {
      return [filterActionList, filterExpressionList];
    }
  }

  // 右键事件
  onContextMenu(e: any) {
    // console.log('onContextMenu', e);
    if (e.button === 2 && this.state.inputIdx !== -1) {
      e.preventDefault();
      this.setState({ inputContextMenuVisible: true });
    }
  }
  // 判断header中按钮区域是否需要滚动
  private updateHeaderRefScoll() {
    const { clientWidth = 0, scrollWidth = 0 } = this.headerRef?.current;
    this.setState({ headerScrollbtnVisible: scrollWidth > clientWidth, headerScrollVal: 0 });
  }
  // 点击header左右滑动按钮
  private onScrollHeader(dir: string) {
    const { clientWidth = 0, scrollWidth = 0 } = this.headerRef?.current;
    if (dir === 'right') {
      this.setState({ headerScrollVal: clientWidth - scrollWidth });
    } else if (dir === 'left') {
      this.setState({ headerScrollVal: 0 });
    }
  }
  // 插入连读
  private addWord() {
    const {
      config: { word },
    } = this.props;
    if (word === 'disabled') {
      message.info(t('当前音色不支持连续'));
      return;
    }
    const { nodeList, globalSpeed } = this.state;
    const selection = this.getSelection();
    if (!selection) {
      console.warn(t('无选中区'));
      return;
    }
    const { startIdx, endIdx, selectionText } = selection;
    if (selectionText.length < 2) {
      message.warning(t('划选范围不得少于2个字'));
      return;
    }
    if (
      startIdx > -1 &&
      endIdx > -1 &&
      nodeList[startIdx] &&
      nodeList[endIdx - 1] &&
      nodeList[startIdx].sectionIndex !== nodeList[endIdx - 1].sectionIndex
    ) {
      message.warning(t('【不同分句】不能放在【连续】内'));
      return;
    }
    if (/[0-9]+/.test(selectionText)) {
      message.warning(t('【数值】不能放在【连续】内'));
      return;
    }
    if (!/^[a-zA-Z\u4e00-\u9fa5]+$/.test(selectionText)) {
      message.warning(t('【标点符号】不能放在【连续】内'));
      return;
    }
    if (/[a-zA-z]+/.test(selectionText) && /[\u4e00-\u9fa5]+/.test(selectionText)) {
      message.warning(t('不支持中英混合进行连读'));
      return;
    }
    let list = nodeList.slice(startIdx, endIdx);
    if (list.find((item) => [NODE_PAUSE].includes(item.type))) {
      message.warning(t('【停顿】不能放在【连续】内'));
      return;
    }
    if (list.find((item) => [NODE_PINYIN].includes(item.type))) {
      message.warning(t('【多音字】不能放在【连续】内'));
      return;
    }
    if (list.find((item) => [LOCAL_SPEED_START, LOCAL_SPEED_END].includes(item.type))) {
      message.warning(t('划选范围不可与局部语速交叉'));
      return;
    }
    if (list.find((item) => [NODE_EXPRESSION_START, NODE_EXPRESSION_END].includes(item.type))) {
      message.warning(t('划选范围不可与表情交叉'));
      return;
    }
    if (list.find((item) => [NODE_SUB_START, NODE_SUB_END].includes(item.type) || !!item.inSub)) {
      message.warning(t('划选范围不可与替换文本交叉'));
      return;
    }
    if (!list.every((item) => !item.inWord)) {
      message.warning(t('划选范围已连续'));
      return;
    }
    list = list.map((item) => ({
      ...item,
      inWord: true,
    }));
    nodeList.splice(startIdx, endIdx - startIdx, ...list);
    if (nodeList[startIdx].type === NODE_WORD_START) {
      nodeList[startIdx] = wordStartNode();
      nodeList[endIdx + 1] = wordEndNode();
    } else {
      nodeList.splice(endIdx, 0, wordEndNode());
      nodeList.splice(startIdx, 0, wordStartNode());
    }
    this.setState({
      nodeList,
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }
  private wordNodeClick(idx: number, e: any) {
    e.stopPropagation();
    const { target } = e;
    const { width, height, left, top } = target.getBoundingClientRect();
    const wordBar = {
      show: true,
      position: {
        top: top + height + 7,
        left: left - (52 - width) / 2,
      },
      idx,
    };
    this.setStateSync({
      wordBar,
    }).then(() => this.onPopHide(true));
  }
  private onWordDel() {
    const { wordBar, globalSpeed } = this.state;
    const { idx } = wordBar;
    const { nodeList } = this.state;
    nodeList.splice(idx, 1);
    for (let i = idx - 1; i >= 0; i--) {
      const node = nodeList[i];
      if (node.type === NODE_WORD_START) {
        let list = nodeList.slice(i + 1, idx);
        list = list.map(({ key, type, text, value, tag, inExpression, inSub, localSpeed }) => ({
          key,
          type,
          text,
          ...(value && {
            value,
          }),
          ...(tag && {
            tag,
          }),
          ...(inExpression && {
            inExpression,
          }),
          ...(inSub && {
            inSub,
          }),
          ...(localSpeed && {
            localSpeed,
          }),
        }));
        nodeList.splice(i, idx - i, ...list);
        break;
      }
    }
    this.setState({
      nodeList,
      wordBar: { show: false, idx: -1 },
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }
  // 替换文本设置弹窗
  private showSubModal(val = '') {
    let [subStartIdx, subEndIdx] = [-1, -1];
    if (!!val) {
      // 编辑
      const {
        nodeList,
        subBar: { idx },
      } = this.state;
      for (let i = idx - 1; i >= 0; i--) {
        const node = nodeList[i];
        if (node.type === NODE_SUB_START) {
          subStartIdx = i;
          subEndIdx = idx - 1;
          break;
        }
      }
    } else {
      // 添加
      this.setState({
        selection: {
          startIdx: 0,
          endIdx: 0,
          selectionText: '',
          startElement: null,
          endElement: null,
        },
      });
      const selection = this.getSelection();
      if (!selection) {
        console.warn(t('无选中区'));
        return;
      }
      const { startIdx, endIdx, selectionText } = selection;
      if (selectionText.length === 0) {
        message.warning(t('请选中文本替换'));
        return;
      }
      const { nodeList } = this.state;
      if (
        startIdx > -1 &&
        endIdx > -1 &&
        nodeList[startIdx] &&
        nodeList[endIdx - 1] &&
        nodeList[startIdx].sectionIndex !== nodeList[endIdx - 1].sectionIndex
      ) {
        message.warning(t('【不同分句】不能放在【替换文本】内'));
        return;
      }
      const list = nodeList.slice(startIdx, endIdx);
      if (list.find((item) => [LOCAL_SPEED_START, LOCAL_SPEED_END].includes(item.type))) {
        message.warning(t('划选范围不可与局部语速交叉'));
        return;
      }
      if (list.find((item) => [NODE_EXPRESSION_START, NODE_EXPRESSION_END].includes(item.type))) {
        message.warning(t('划选范围不可与表情交叉'));
        return;
      }
      if (list.find((item) => [NODE_WORD_START, NODE_WORD_END].includes(item.type) || !!item.inWord)) {
        message.warning(t('划选范围不可与连续交叉'));
        return;
      }
      if (!list.every((item) => !item.inSub)) {
        message.warning(t('划选范围已替换文本'));
        return;
      }
      if (list.find((item) => [NODE_PAUSE].includes(item.type))) {
        message.warning(t('【停顿】不能放在【替换文本】内'));
        return;
      }
      if (list.find((item) => [NODE_NUMBER].includes(item.type))) {
        message.warning(t('【数字标签】不能放在【替换文本】内'));
        return;
      }
      if (list.find((item) => [NODE_PINYIN].includes(item.type))) {
        message.warning(t('【多音字】不能放在【替换文本】内'));
        return;
      }
      [subStartIdx, subEndIdx] = [startIdx, endIdx];
    }
    this.setState({ subInputModal: { show: true, subStartIdx, subEndIdx, value: val || '' } });
  }
  // 替换文本确认提交
  private confirmSubInput = (val: string) => {
    const {
      nodeList,
      globalSpeed,
      subInputModal: { subStartIdx, subEndIdx },
    } = this.state;
    let list = nodeList.slice(subStartIdx, subEndIdx);
    list = list.map((item) => ({
      ...item,
      inSub: true,
    }));
    nodeList.splice(subStartIdx, subEndIdx - subStartIdx, ...list);
    if (nodeList[subStartIdx].type === NODE_SUB_START) {
      nodeList[subStartIdx] = subStartNode(val);
      nodeList[subEndIdx + 1] = subEndNode(val);
    } else {
      nodeList.splice(subEndIdx, 0, subEndNode(val));
      nodeList.splice(subStartIdx, 0, subStartNode(val));
    }
    this.setState({
      nodeList,
      subInputModal: { show: false, subStartIdx: -1, subEndIdx: -1, value: '' },
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
    message.success(t('替换文本成功'));
  };
  private subNodeClick({ idx, value }: { idx: number; value: string }, e: any) {
    e.stopPropagation();
    const { target } = e;
    const { width, height, left, top } = target.getBoundingClientRect();
    const subBar = {
      show: true,
      position: {
        top: top + height + 7,
        left: left - (46 - width) / 2,
      },
      value,
      idx,
    };
    this.setStateSync({
      subBar,
    }).then(() => this.onPopHide(true));
  }
  private setSub({ value: tag }: Row) {
    if (tag === 'edit') {
      const { subBar } = this.state;
      this.showSubModal(subBar.value);
    }
    if (tag === 'delete') {
      this.onSubDel();
    }
  }
  private onSubDel() {
    const { subBar, globalSpeed } = this.state;
    const { idx } = subBar;
    const { nodeList } = this.state;
    nodeList.splice(idx, 1);
    for (let i = idx - 1; i >= 0; i--) {
      const node = nodeList[i];
      if (node.type === NODE_SUB_START) {
        let list = nodeList.slice(i + 1, idx);
        list = list.map(({ key, type, text, value, tag, inExpression, inWord, localSpeed }) => ({
          key,
          type,
          text,
          ...(value && {
            value,
          }),
          ...(tag && {
            tag,
          }),
          ...(inExpression && {
            inExpression,
          }),
          ...(inWord && {
            inWord,
          }),
          ...(localSpeed && {
            localSpeed,
          }),
        }));
        nodeList.splice(i, idx - i, ...list);
        break;
      }
    }
    this.setState({
      nodeList,
      subBar: { show: false, idx: -1 },
    });
    this.props.onChange(nodeListToSsml(nodeList, true, globalSpeed), globalSpeed);
    this.triggerSectionChange();
  }
}

export default connect(({ auditionInfo, imageConfig }: { auditionInfo: AuditionState; imageConfig: ImageConfig }) => ({
  auditionInfo,
  imageConfig,
}))(RichText);
