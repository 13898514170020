import i18n from 'utils/i18n';
import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '../../../../common';
import { Tip } from '../index';
import './action_preview.scss';

import { Action } from '../../../../../types/sentence';
import ConstEnum from '../../utils/const';
const { t } = i18n;
interface IProps {
  data: Action;
  active: boolean;
  dynamic: boolean;
  disabled: boolean;
  border: boolean;
  className: string | object;
  mode: string;
  hide?: boolean;
  onClick: (e: any) => void; // ******
}

// 动作预览组件
const Index = (props: IProps) => {
  const {
    data: { actionName, duration, previewUrl, dynamicUrl }, // 包含actionId ******
    active,
    dynamic,
    disabled,
    className,
    onClick,
    border,
    mode,
    hide = false,
  } = props;

  const [tipVisible, setTipVisible] = useState(false);

  const tipHandle = (status: boolean) => setTipVisible(status);

  return (
    <div className={classNames('rt-action-preview', className, { border })}>
      <div className={classNames('preview-pane', { active: active && !disabled })} onClick={(e) => onClick(e)}>
        {Number.isFinite(duration) && (
          <span className="time">
            <span className="time-text">{Math.ceil(duration / 1000)}s</span>
          </span>
        )}
        {!!dynamicUrl && dynamic ? (
          // <video className="dynamic-default" src={dynamicUrl} muted autoPlay loop />
          <video className="preview" src={!!hide ? '' : dynamicUrl} muted loop autoPlay />
        ) : (
          <Fragment>
            <img className="preview" src={previewUrl} alt={t('预览')} />
            {/* <video className="dynamic" src={dynamicUrl} muted autoPlay loop /> */}
          </Fragment>
        )}
        <div className="text">{actionName}</div>
        {/* {active && !disabled && <div className="active" />} */}
      </div>
      {/* ------ 不可用蒙层 ------*/}
      {disabled && (
        <div
          className={classNames('mask', { active })}
          onMouseEnter={() => tipHandle(true)}
          onMouseLeave={() => tipHandle(false)}
        >
          <Tip
            className="action-disable-tip"
            trigger={['hover']}
            visible={tipVisible}
            placement="top"
            arrowColor="#514f4f"
            overlay={
              <div className="disable-tip-ctx">
                {mode !== 'expression' ? (
                  <>
                    <p>{t('1. 该动作因与前/后文冲突无法插入')}</p>
                    <p>{t('2. 请更换光标位置后，再尝试插入')}</p>
                  </>
                ) : (
                  <p>
                    {t('为保证表情持续效果，建议至少选中连续{ConstEnum.expressionRecommendTextLength}个字插入表情', {
                      length: ConstEnum.expressionRecommendTextLength,
                    })}
                  </p>
                )}
              </div>
            }
          >
            <div className="action-tip">
              <Icon className="action-tip-icon" name="help" size={20} color="rgba(255, 255, 255, 0.8)" />
            </div>
          </Tip>
        </div>
      )}
    </div>
  );
};

Index.defaultProps = {
  data: {},
  active: false,
  dynamic: false, // 直接展示动图
  disabled: false, // 禁用
  border: false,
  className: '',
  mode: 'action',
  onClick: () => {},
};

export default Index;
