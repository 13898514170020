import React from 'react';

import './index.scss';

type EmptyProps = {
  width?: number;
  height?: number;
  marginTop?: string;
  prompt?: string; // 提示文案
};
const Empty = (props: EmptyProps) => {
  const { width = 160, height = 90, prompt = '' } = props;
  return (
    <div className="empty-prompt" style={{ backgroundSize: `${width}px ${height}px`, paddingTop: `${height + 56}px` }}>
      {prompt}
    </div>
  );
};

export default React.memo(Empty);
