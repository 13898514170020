import i18n from 'utils/i18n';
import React, { useState, useEffect } from 'react';
import { Pop, ActionPane } from '../index';
import { Icon } from '../../..';
import './action.scss';
const { t } = i18n;
interface IProps {
  data: {
    show: boolean;
    idx?: number;
    value?: string;
    position?: {
      left: number;
      top: number;
    };
  };
  disabledActionMap: any;
  actionEnum: any;
  actionPositionMap: any;
  mode: string;
  onSelect: (idx: number, actionItem: any) => void;
  onDelete: (idx: number) => void;
}

const ActionBar = (props: IProps) => {
  const { data, disabledActionMap, actionEnum, actionPositionMap, mode } = props;
  const { show, idx = -1, value, position } = data;
  const { left = 0, top = 0 } = position || {};
  // const [leftShift, setLeftShift] = useState(false); // 右边超出编辑器，想做挪动
  // const [topShift, setTopShift] = useState(false); // 右边超出编辑器，想做挪动
  // const [rtRight, setRtRight] = useState(0); // 富文本右边

  // useEffect(() => {
  //   const richTextEle = document.getElementById('rich-text') as HTMLElement;
  //   if (richTextEle) {
  //     const { right: rtRight } = richTextEle.getBoundingClientRect();
  //     setRtRight(rtRight);
  //     setLeftShift(left + 428 > rtRight);
  //     setTopShift(top + 500 > window.innerHeight);
  //   }
  // }, [show]);

  return (
    <Pop
      visible={show}
      type="fixed"
      position={{ left: left - 448, top: top > 250 ? top - 250 : top }}
      style={{ zIndex: 2000 }}
    >
      <div className="rt-action-bar">
        <ActionPane
          active={value}
          actionEnum={actionEnum}
          actionPositionMap={actionPositionMap}
          disabledActionMap={disabledActionMap}
          mode={mode}
          onSelect={(actionItem: any) => props.onSelect(idx, actionItem)}
        />
        <div className="action-del" onClick={() => props.onDelete(idx)}>
          <Icon className="action-del-icon" name="delete" size={16} />
          <span className="text">{mode === 'expression' ? t('移除配置表情') : t('移除配置动作')}</span>
        </div>
      </div>
    </Pop>
  );
};

ActionBar.defaultProps = {
  actionEnum: [],
  actionPositionMap: null,
  mode: 'action',
  data: {
    show: false,
    position: {
      top: 0,
      left: 0,
    },
  },
  onSelect: () => {},
  onDelete: () => {},
};

export default ActionBar;
