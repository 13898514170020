import React, { useState, useRef } from 'react';
import { Popconfirm, PopconfirmProps } from 'antd';
type IProps = PopconfirmProps & {
  zoom?: number;
};

// chrome128.0.6613.85版本以后getBoundingClientRect获取的宽高=原宽高*zoom，旧版本获取的宽高不受zoom影响，故对外层有zoom的tooltip做此修复
const ZoomPopconfirm = (props: IProps) => {
  const { ...rest } = props;
  const [zoom, setZoom] = useState<number>(1);
  const [visibility, setVisibility] = useState<any>(undefined);
  const popconfirmRef = useRef<any>(null);
  const onVisibleChange = (visible: boolean) => {
    const popconfirmDom = popconfirmRef?.current?.triggerRef?.current;
    if (popconfirmDom) {
      const { width, height } = popconfirmDom.getBoundingClientRect();
      const { offsetWidth, offsetHeight } = popconfirmDom;
      if (+width.toFixed(0) !== offsetWidth || +height.toFixed(0) !== offsetHeight) {
        setVisibility('hidden');
        setZoom(offsetWidth / width);
        // 防止tooltip抖动 待优化
        setTimeout(() => {
          setVisibility('visible');
        }, 500);
      }
    }
    props?.onVisibleChange?.(visible);
  };
  return (
    <Popconfirm
      {...rest}
      ref={popconfirmRef}
      overlayStyle={{ zoom, transform: `scale(${1 / zoom})`, visibility }}
      onVisibleChange={onVisibleChange}
    >
      {props.children}
    </Popconfirm>
  );
};

export default React.memo(ZoomPopconfirm);
