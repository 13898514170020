import i18n from 'utils/i18n';
import { ActionType } from '../../types/sentence';
const { t } = i18n;
export const ActionTypeList = [
  { key: ActionType.QUESTION, title: t('问题') },
  { key: ActionType.REPLY, title: t('回复语') },
  { key: ActionType.SCENE, title: t('终端场景') },
  { key: ActionType.DOMAIN, title: t('领域') },
  { key: ActionType.COMMAND, title: t('场景指令') },
  { key: ActionType.BOTQUESTION, title: t('话术') },
];

export const SourceList = [
  { key: 'chat', title: t('寒暄库') },
  { key: 'qa_pairs', title: t('问答知识库') },
  { key: 'scene-qa', title: t('场景化问答') },
];

export const ziyanSourceList = ['self_study', 'ziyan'];

export const apassUrlMap = {
  // local: 'https://gw.dev.tvs.qq.com',
  local: 'https://gw.test.tvs.qq.com',
  test: 'https://gw.test.tvs.qq.com',
  development: 'https://gw.dev.tvs.qq.com',
  gray: 'https://gwgray.tvs.qq.com',
  production: 'https://gw.tvs.qq.com',
};

export const recheckModalInfo = [
  {
    type: 'reset',
    title: t('确认进行回复语重置吗？'),
    content: t('批量操作后，会将所有选中的回复语重置为初始回复语，请问您是否继续？'),
  },
  {
    type: 'match',
    title: t('确认进行批量动作匹配吗？'),
    content: t('批量匹配后，将对所有选中的回复语进行智能匹配动作；回复语上现有的动作会被覆盖。请问您是否继续？'),
  },
];

export const uploadTemplatePath = '%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88new.xlsx';
export const botUploadTemplatePath =
  '%E4%BD%BF%E7%94%A8%E5%A4%A7%E6%A8%A1%E5%9E%8B%E5%AF%B9%E8%AF%9D_%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E8%AF%9D%E6%9C%AF%E9%85%8D%E7%BD%AE%E6%A8%A1%E7%89%88.xlsx';

// 入库方式
export const putWayMap: { [key: number]: string } = {
  0: t('全部'),
  1: t('手工添加'),
  2: t('自动添加'),
  3: t('话术同步'),
};

// 回复语方式
export const replyTypeMap: { [key: string]: string } = {
  chat: t('闲聊'),
  qa_pairs: t('知识问答'),
  task: t('任务型'),
  other: t('其他'),
  'scene-qa': t('场景化问答'),
};
// 自动入库的回复语来源
export const autoReplySourceList = [
  { key: 'task', title: t('任务型') },
  { key: 'other', title: t('其他') },
];
