import React, { useState, useEffect, useRef } from 'react';
import { Pop } from '../index';
import classNames from 'classnames';
import './options_bar.scss';

interface IProps {
  data: {
    show: boolean;
    position?: {
      left: number;
      top: number;
    };
  };
  children: React.ReactNode;
}

const OptionsBar = (props: IProps) => {
  const barRef = useRef<any>();
  const {
    data: { show, position: { left = 0, top = 0 } = {} },
    children,
  } = props;
  const [shift, setShift] = useState(false); // 右边超出编辑器，向左挪动
  const [rtRight, setRtRight] = useState(0); // 富文本右边

  useEffect(() => {
    const richTextEle = document.getElementById('rich-text') as HTMLElement;
    const { right } = richTextEle.getBoundingClientRect();
    setRtRight(right);
    setShift(left + 146 > right);
  }, [children]);

  return (
    <Pop visible={!!show} position={{ top, left: shift ? rtRight - 146 - 10 : left }}>
      <div className="rt-options-bar" ref={barRef}>
        {props.children}
      </div>
    </Pop>
  );
};

const Item = (props: any) => {
  const { danger, active } = props;
  return (
    <div
      className={classNames('rt-options-item', {
        danger,
        active,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

OptionsBar.defaultProps = {
  data: {},
  theme: '',
};
OptionsBar.Item = Item;
export default OptionsBar;
