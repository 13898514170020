import React, { useState, useEffect } from 'react';

import { ColorPicker, ColorObject, ColorPickerChangeTrigger } from 'tdesign-react';
import debounce from 'lodash/debounce';

import './index.scss';

interface IvhColorPickerProps {
  optionKey: string;
  disabled?: boolean;
  onChangeColor: Function;
  // defaultColor: string;
  color: string;
}
// const swatchColors = [
//   '#ECF2FE',
//   '#D4E3FC',
//   '#BBD3FB',
//   '#96BBF8',
//   '#699EF5',
//   '#4787F0',
//   '#266FE8',
//   '#0052D9',
//   '#0034B5',
//   '#001F97',
//   '#FDECEE',
//   '#F9D7D9',
//   '#F8B9BE',
//   '#F78D94',
//   '#F36D78',
//   '#E34D59',
//   '#C9353F',
//   '#C9354F',
//   '#951114',
//   '#790A0C',
// ]

const swatchColors = ['#DBE2EC', '#E7ADAC', '#EBC1A8', '#A6BAAF', '#8D91AA'];

const IvhColorPicker = (props: IvhColorPickerProps) => {
  const { disabled, optionKey, onChangeColor, color } = props;
  const [value, setValue] = useState(color);

  const debounceChange = debounce((value: string) => {
    // console.log('handleChange', value);
    onChangeColor(optionKey, value);
  }, 200);
  const handleChange = (value: string) => {
    setValue(value);
    debounceChange(value);
  };

  return (
    <ColorPicker
      disabled={disabled}
      value={value}
      format="HEX"
      colorModes={['monochrome']}
      showPrimaryColorPreview={false}
      recentColors={false}
      inputProps={{ className: 'ivh-color-input' }}
      swatchColors={swatchColors}
      onChange={handleChange}
      className="ivh-color-picker"
    />
  );
};

export default React.memo(IvhColorPicker);
