import React, { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import './pop.scss';

interface IProps {
  visible: boolean;
  className?: string | object;
  type: 'absolute' | 'relative' | 'fixed';
  position: object;
  style?: object;
  children: any;
}

let timer: any = null;
const Pop = (props: IProps) => {
  const { visible, className, type, position, style } = props;
  const [state, setState] = useState('init');

  useEffect(() => {
    if (visible) {
      setState('show');
      clearTimeout(timer);
    } else {
      if (state === 'init') return;
      setState('hide');
      timer = setTimeout(() => setState('none'), 300);
    }
  }, [visible]);

  return (
    <Fragment>
      <div
        className={classNames('rt-pop', className, state, type)}
        style={{
          ...style,
          ...position,
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {state !== 'none' ? props.children : null}
      </div>
    </Fragment>
  );
};

Pop.defaultProps = {
  visible: false,
  hasMask: false,
  className: '',
  type: 'fixed',
  position: {},
};

export default Pop;
