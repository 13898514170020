import React, { FC } from 'react';
import classNames from 'classnames';
import './select_list.scss';

type Value = string;

export interface Row {
  value: Value;
  label: string;
  danger?: boolean;
}

interface IProps {
  data: Row[];
  active?: Value;
  style?: React.CSSProperties;
  onSelect: (data: Row, idx: number) => void;
}

const SelectList = (props: IProps) => {
  const { data, active, style } = props;

  const onSelect = (data: Row, idx: number) => {
    props.onSelect(data, idx);
  };

  return (
    <div style={style} className="select-list">
      {data.map((item, idx) => {
        const { label, value, danger } = item;
        return (
          <div
            key={idx}
            className={classNames('select-item', { danger, active: active === value || active === label })}
            onClick={() => onSelect(item, idx)}
          >
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default SelectList;
