import i18n from 'utils/i18n';
import React from 'react';
import TRTC from 'trtc-js-sdk';
import type { Stream, LocalStream, RemoteStream, Client, NetworkQuality, StreamConfig } from 'trtc-js-sdk';

import { apiPost } from 'apis/api/utils';
const { t } = i18n;
function isUndefined(data: any) {
  return typeof data === 'undefined';
}
export type { Stream, LocalStream, RemoteStream, Client, NetworkQuality, StreamConfig };

TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.NONE);

type RTCProps = {
  userID: string;
  isMockUser?: boolean;
  roomID: number;
  microphoneID?: string;
  mode: 'rtc' | 'live';
  audio: boolean | undefined;
  video: boolean | undefined;
  addUser?: (userID: string, streamType?: string) => void;
  removeUser?: (userID: string, streamType?: string) => void;
  addStream?: (stream: LocalStream | RemoteStream) => void;
  updateStream?: (stream: LocalStream | RemoteStream) => void;
  updateStreamConfig?: (userId: String, type: string, value?: NetworkQuality['uplinkNetworkQuality']) => void;
  removeStream?: (stream: LocalStream | RemoteStream) => void;
  onRef: (ref: RTC) => void;
  onShareScreenStart?: () => void;
  onShareScreenStop?: () => void;
  onStreamPlay?: Function;
  appID: string;
};

type RTCState = {};

export function isLocalStream(stream: LocalStream | RemoteStream | null): stream is RemoteStream {
  if (!stream) {
    return false;
  }
  // @ts-ignore
  return (stream as RemoteStream).getType() === 'local';
}

export const MOCK_USER_TAG = '$$_$$_';

export default class RTC extends React.Component<RTCProps, RTCState> {
  userID: string;
  isMockUser: boolean; // 特殊用户，模拟消息传递
  cameraID?: string;
  microphoneID?: string;
  mode: 'rtc' | 'live';
  audio: boolean | undefined;
  video: boolean | undefined;
  localStream: LocalStream | null;
  // remoteStreamList: RemoteStream[];
  client: Client | null;
  isJoining: boolean;
  isJoined: boolean;
  isPublishing: boolean;
  isPublished: boolean;
  isUnPublishing: boolean;
  isLeaving: boolean;
  userSig: string;
  dom: any;
  mirror: boolean;
  shareStream: LocalStream | null;
  constructor(props: RTCProps) {
    super(props);
    this.isMockUser = !!props.isMockUser;
    this.userID = this.isMockUser ? MOCK_USER_TAG + props.userID : props.userID;
    this.mode = props.mode || 'rtc';
    this.audio = props.audio;
    this.video = props.video;

    this.localStream = null;
    this.client = null;
    this.shareStream = null;
    this.isJoining = false;
    this.isJoined = false;
    this.isPublishing = false;
    this.isPublished = false;
    this.isUnPublishing = false;
    this.isLeaving = false;
    this.userSig = '';
    // this.privateMapKey = 255;
    this.mirror = false;
    this.dom = null;
  }

  async componentDidMount() {
    this.props.onRef(this);
    const checkResult = await TRTC.checkSystemRequirements();
    if (!checkResult.result) {
      alert(t('当前浏览器不支持 WebRTC SDK, 请更换其他浏览器'));
    }
    // if (this.isMockUser) {
    //   return;
    // }
    // const that = this;
    // window.addEventListener('beforeunload', (event) => {
    //   if (that.isJoined) {
    //     event.preventDefault();
    //     // eslint-disable-next-line no-param-reassign
    //     event.returnValue = 'Are you sure you want to close';
    //   }
    // });
  }

  async componentWillUnmount() {
    this.handleLeave();
  }

  setUserSig(userSig: string) {
    this.userSig = userSig;
  }

  async getUserSig() {
    if (!this.isMockUser) {
      return;
    }
    console.warn(t('getUserSig: ******只在开发调试时使用*****'));
    let res: any = null;
    try {
      res = await apiPost(
        'apiGetUsersig',
        {
          Payload: {
            SDKAppID: this.props.appID,
            UserID: this.userID,
          },
          Header: {},
        },
        {
          dataType: 'json',
        },
      );
    } catch (err) {
      console.error('apiGetUsersig', err);
    }
    this.userSig = res.data.UserSig;
  }

  // 初始化客户端
  async initClient() {
    await this.getUserSig();

    this.client = TRTC.createClient({
      mode: this.mode,
      sdkAppId: Number(this.props.appID),
      userId: this.userID,
      userSig: this.userSig,
    });
    if (!this.isMockUser) {
      this.handleClientEvents();
    }
    return this.client;
  }

  async initLocalStream() {
    this.localStream = TRTC.createStream({
      audio: this.audio,
      video: this.video,
      userId: this.userID,
      cameraId: this.cameraID,
      microphoneId: this.microphoneID,
    });
    try {
      await this.localStream.initialize();
      console.log(t('初始化本地流成功'));
      this.props.addStream && this.props.addStream(this.localStream);
      return this.localStream;
    } catch (error: any) {
      this.localStream = null;
      alert(`${JSON.stringify(error.message)}`);
    }
  }

  async createShareStream() {
    this.shareStream = TRTC.createStream({
      userId: `share_${this.props.userID}`,
      screen: true, // 采集屏幕分享
    });
    await this.shareStream.initialize();
    await this.client?.publish(this.shareStream, { isAuxiliary: true });
    if (this.props.onShareScreenStart) {
      this.props.onShareScreenStart();
    }

    // 屏幕分享流监听屏幕分享停止事件
    this.shareStream.on('screen-sharing-stopped', async () => {
      this.closeShareStream();
    });
  }

  async closeShareStream() {
    if (!this.shareStream) {
      return;
    }
    await this.client?.unpublish(this.shareStream);
    this.shareStream.close();
    this.shareStream = null;
    if (this.props.onShareScreenStop) {
      this.props.onShareScreenStop();
    }
  }

  destroyLocalStream() {
    this.localStream && this.props.removeStream && this.props.removeStream(this.localStream);
    this.localStream && this.localStream.stop();
    this.localStream && this.localStream.close();
    this.localStream = null;
    this.dom = null;
  }

  playStream(dom: HTMLDivElement, stream: LocalStream | RemoteStream | null, options: any = {}) {
    // @ts-ignore
    if (!isLocalStream(stream) && stream.getType() === 'main' && stream.getUserId().indexOf('share') >= 0) {
      stream
        ?.play(dom, {
          objectFit: 'contain',
          mirror: this.mirror,
        })
        .catch();
    } else {
      console.log(t('播放视频'), stream);
      stream?.play(dom, { ...options }).catch((err) => {
        // eslint-disable-next-line @tencent/tea-i18n/no-bare-zh-in-js
        console.log('播放失败', err);
      });
      if (stream === this.localStream) {
        this.dom = dom;
      }
    }
  }

  resumeStream(stream: Stream) {
    stream.resume();
  }

  async handleJoin(userID?: string) {
    console.log('join', this.isJoining, this.isJoined);
    if (this.isJoining || this.isJoined) {
      return;
    }
    if (userID) {
      this.userID = this.isMockUser ? MOCK_USER_TAG + userID : userID;
    }
    this.isJoining = true;
    try {
      await this.initClient();
      await this.client?.join({ roomId: this.props.roomID });
      console.log('join room success!', this.props.roomID);

      this.isJoining = false;
      this.isJoined = true;
      this.props.addUser && this.props.addUser(this.userID, 'local');

      // this.startGetAudioLevel();
    } catch (error) {
      this.isJoining = false;
      console.error('join room failed', error);
    }
  }

  async handlePublish() {
    if (!this.isJoined || this.isPublishing || this.isPublished) {
      return;
    }
    this.isPublishing = true;
    !this.localStream && (await this.initLocalStream());
    if (!this.localStream) {
      // eslint-disable-next-line @tencent/tea-i18n/no-bare-zh-in-js
      console.log('发布流：本地流初始化失败');
      return;
    }
    try {
      await this.client?.publish(this.localStream);
      console.log('publish localStream success!');

      this.isPublishing = false;
      this.isPublished = true;
      // this.setState && this.setState('publish', this.isPublished);
    } catch (error) {
      this.isPublishing = false;
      console.error('publish localStream failed', error);
    }
  }

  async handleUnPublish() {
    if (!this.isPublished || this.isUnPublishing) {
      return;
    }
    this.isUnPublishing = true;
    if (!this.localStream) {
      console.log(t('取消发布本地流：本地流不存在'));
      return;
    }
    try {
      await this.client?.unpublish(this.localStream);
      console.log('unpublish localStream success!');

      this.isUnPublishing = false;
      this.isPublished = false;
      // this.setState && this.setState('publish', this.isPublished);
    } catch (error: any) {
      this.isUnPublishing = false;
      console.error('unpublish localStream failed', error);
      switch (error.getCode()) {
        case 4096: // ErrorCode = 0x1001 INVALID_OPERATION
          console.error('stream has not been published yet, please publish first', 2000);
          break;
        case 4097: // ErrorCode = 0x1001 INVALID_PARAMETER
          console.error('publish is ongoing, please try unpublish later', 2000);
          break;
        default:
          console.error('unpublish localStream failed! please try again later', 2000);
          break;
      }
    }
    this.localStream && (await this.destroyLocalStream());
  }

  async handleSubscribe(remoteStream: RemoteStream, config = { audio: true, video: true }) {
    try {
      await this.client?.subscribe(remoteStream, {
        audio: isUndefined(config.audio) ? true : config.audio,
        video: isUndefined(config.video) ? true : config.video,
      });
    } catch (error) {
      console.error(
        `subscribe ${remoteStream.getUserId()} with audio: ${config.audio} video: ${config.video} error`,
        error,
      );
    }
  }

  async handleUnSubscribe(remoteStream: RemoteStream) {
    try {
      await this.client?.unsubscribe(remoteStream);
    } catch (error) {
      console.error(`unsubscribe ${remoteStream.getUserId()} error`, error);
    }
  }

  async handleLeave() {
    if (!this.isJoined || this.isLeaving) {
      return;
    }
    this.isLeaving = true;
    this.stopGetAudioLevel();
    try {
      await this.client?.leave();
      console.warn('leave room success');

      if (this.isPublished && !this.isMockUser) {
        await this.handleUnPublish();
      }
      this.userSig = '';
      this.isLeaving = false;
      this.isJoined = false;
      this.props.removeUser && this.props.removeUser(this.userID, 'local');
      // this.setState && this.setState('join', this.isJoined);
    } catch (error) {
      this.isLeaving = false;
      console.error('leave room error', error);
    }
  }

  muteVideo() {
    this.localStream && this.localStream.muteVideo();
  }

  muteAudio() {
    this.localStream && this.localStream.muteAudio();
  }

  unmuteVideo() {
    this.localStream && this.localStream.unmuteVideo();
  }

  unmuteAudio() {
    this.localStream && this.localStream.unmuteAudio();
  }

  stopGetAudioLevel() {
    this.client && this.client.enableAudioVolumeEvaluation(-1);
  }

  handleStreamEvents(stream: Stream) {
    stream.on('error', (error) => {
      const errorCode = error.getCode();
      if (errorCode === 0x4043) {
        // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
        this.props.updateStreamConfig && this.props.updateStreamConfig(stream.getUserId(), 'resume-stream');
      }
    });
    stream.on('player-state-changed', (event) => {
      console.log(`${event.type} player is ${event.state} because of ${event.reason}`);
      this.props.onStreamPlay && this.props.onStreamPlay(event.type, event.state);
    });
  }

  handleClientEvents() {
    if (!this.client) {
      return;
    }
    this.client.on('error', (error) => {
      console.error(error);
    });
    this.client.on('client-banned', async (event) => {
      console.error(`client has been banned for ${event.reason}`);

      this.isPublished = false;
      this.localStream = null;
      await this.handleLeave();

      alert(event.reason);
    });
    // fired when a remote peer is joining the room
    this.client.on('peer-join', (event) => {
      const { userId } = event;
      console.log(`peer-join ${userId}`, event);
      this.props.addUser && this.props.addUser(userId);
    });
    // fired when a remote peer is leaving the room
    this.client.on('peer-leave', (event) => {
      const { userId } = event;
      console.log(`peer-leave ${userId}`, event);
      this.props.removeUser && this.props.removeUser(userId);
    });

    // fired when a remote stream is added
    this.client.on('stream-added', (event) => {
      const { stream: remoteStream } = event;
      const remoteUserID = remoteStream.getUserId();
      console.log(`remote stream added: [${remoteUserID}] type: ${remoteStream.getType()}`);
      // subscribe to this remote stream
      this.handleSubscribe(remoteStream);
      this.props.addStream && this.props.addStream(remoteStream);
    });
    // fired when a remote stream has been subscribed
    this.client.on('stream-subscribed', (event) => {
      const { stream: remoteStream } = event;
      console.log('stream-subscribed userId: ', remoteStream.getUserId());
      // play
    });
    // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
    this.client.on('stream-removed', (event) => {
      const { stream: remoteStream } = event;
      remoteStream.stop();
      this.props.removeStream && this.props.removeStream(remoteStream);
      console.warn(`stream-removed userId: ${remoteStream.getUserId()} type: ${remoteStream.getType()}`);
    });

    this.client.on('stream-updated', (event) => {
      const { stream: remoteStream } = event;
      this.props.updateStream && this.props.updateStream(remoteStream);
      console.log(
        `type: ${remoteStream.getType()} stream-updated hasAudio: ${remoteStream.hasAudio()} hasVideo: ${remoteStream.hasVideo()}`,
      );
    });

    this.client.on('mute-audio', (event) => {
      const { userId } = event;
      console.log(`${userId} mute audio`);
      this.props.updateStreamConfig && this.props.updateStreamConfig(userId, 'mute-audio');
    });
    this.client.on('unmute-audio', (event) => {
      const { userId } = event;
      console.log(`${userId} unmute audio`);
      this.props.updateStreamConfig && this.props.updateStreamConfig(userId, 'unmute-audio');
    });
    this.client.on('mute-video', (event) => {
      const { userId } = event;
      console.log(`${userId} mute video`);
      this.props.updateStreamConfig && this.props.updateStreamConfig(userId, 'mute-video');
    });
    this.client.on('unmute-video', (event) => {
      const { userId } = event;
      console.log(`${userId} unmute video`);
      this.props.updateStreamConfig && this.props.updateStreamConfig(userId, 'unmute-video');
    });

    this.client.on('connection-state-changed', (event) => {
      console.log(`RtcClient state changed to ${event.state} from ${event.prevState}`);
    });

    this.client.on('network-quality', (event) => {
      const { uplinkNetworkQuality, downlinkNetworkQuality } = event;
      if (this.props.updateStreamConfig) {
        this.props.updateStreamConfig(this.userID, 'uplink-network-quality', uplinkNetworkQuality);
        this.props.updateStreamConfig(this.userID, 'downlink-network-quality', downlinkNetworkQuality);
      }
    });
    this.client.on('error', (error) => {
      console.error('client error observed: ', error);
    });
  }

  render() {
    return <div style={{ width: 0, height: 0 }}></div>;
  }
}
