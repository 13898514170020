import i18n from 'utils/i18n';
import React, { useEffect, useState, useMemo, useRef } from 'react';

import { Popup } from 'tdesign-react';
import { CloseIcon } from 'tdesign-icons-react';
import { Button } from 'antd';
import IvhColorPicker from './components/IvhColorPicker';

import utils from 'utils';
import { ColorOptionList, ColorHexOption } from './types';

import './index.scss';
const { t } = i18n;
interface ColorSettingProps extends ColorOptionList {
  disabled?: boolean;
  onChangeColorOption: Function;
  // colorList?: { key: string; color: string }[];
}

const ColorSetting = (props: ColorSettingProps) => {
  const { onChangeColorOption } = props;
  const [visible, setVisible] = useState(false);
  const { optionList, disabled } = props;
  const [colors, setColors] = useState<ColorHexOption[]>([]); // 前两个配置项颜色
  const [isReset, setIsReset] = useState(false);
  const resetColorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // console.log('optionList', optionList);
    // 无配置项情况颜色修改
    if (colors.length && optionList?.every((i) => !i.value)) {
      setColors([]);
      resetColors();
      return;
    }

    const newColors = optionList.map((option) => {
      const { key, name } = option;
      let colorHex = '#000000';
      if (Number.isFinite(option?.defaultValue?.colorValue)) {
        colorHex = utils.transNumToHex(option.defaultValue?.colorValue || 0);
      } else if (!!option?.defaultValue?.colorValue) {
        colorHex = option.defaultValue.colorValue;
      }

      if (option?.value && Number.isFinite(option?.value?.colorValue)) {
        colorHex = utils.transNumToHex(option.value.colorValue || 0);
      } else if (!!option?.value?.colorValue) {
        colorHex = option.value.colorValue;
      }

      return {
        key,
        name,
        color: colorHex,
      };
    });
    // 切换服装重置color
    setColors(newColors);
  }, [optionList]);

  const resetColors = () => {
    setIsReset(true);
    const defaultOptionList = optionList.map((option) => ({
      ...option,
      value: { colorValue: option.defaultValue?.colorValue },
    }));
    onChangeColorOption(defaultOptionList, true);
    resetColorRef?.current?.blur();
    // colorPicker直接setValue有内存溢出问题，恢复默认特殊处理
    setTimeout(() => setIsReset(false), 100);
  };

  const handleColorOption = (key: string, color: string) => {
    const colorNumber = utils.transHexToNum(color);
    // console.log('handleColorOption', key, colorNumber);
    const newOptionList = optionList.map((option) => ({
      ...option,
      value: option.key === key ? { colorValue: colorNumber } : { ...option.value },
      // value: option.key === key ? { color_value: color } : { ...option.value },
      // color_value: option.key === key ? colorNumber : option.color_value,
    }));
    onChangeColorOption(newOptionList);
  };

  const SettingPannel = useMemo(
    () => (
      <div className="color-setting-pannel" onClick={(e) => e.stopPropagation()}>
        <div className="pannel-header">
          <span>{t('样式')}</span>
          <CloseIcon onClick={() => setVisible(false)} />
        </div>
        <div className="pannel-content">
          {colors.map((item) => (
            <div key={item.key} className="pannel-item">
              <span className="item-name">{item.name}</span>
              {!isReset && (
                <IvhColorPicker
                  disabled={disabled}
                  color={item.color}
                  optionKey={item.key}
                  onChangeColor={handleColorOption}
                />
              )}
            </div>
          ))}
        </div>
        <Button ref={resetColorRef} disabled={disabled} className="reset-color-btn" onClick={resetColors}>
          {t('恢复默认')}
        </Button>
      </div>
    ),
    [optionList, disabled, isReset],
  );

  return (
    <Popup
      visible={visible}
      trigger="click"
      placement="bottom-right"
      content={SettingPannel}
      overlayInnerClassName="color-setting-popup"
      onVisibleChange={setVisible}
    >
      <div className="color-setting" onClick={(e) => e.stopPropagation()}>
        {colors
          .filter((i, index) => index < 2)
          .map((item) => (
            <div
              key={item.key}
              style={{
                flex: 1,
                backgroundColor: item.color,
              }}
            ></div>
          ))}
      </div>
    </Popup>
  );
};

export default React.memo(ColorSetting);
