import React, { useState, useEffect } from 'react';
import TabPane from './tab_pane';
import classNames from 'classnames';
import './tabs.scss';

interface IProps {
  className?: string;
  style?: object;
  activeKey: number;
  children: any;
  onChange: (key: number) => void;
}

const Tabs = (props: IProps) => {
  const { children, style, className } = props;
  const [activeKey, setActiveKey] = useState(1);
  const childLen = children ? children.length : 0;

  useEffect(() => {
    if (props.activeKey !== activeKey) {
      setActiveKey(props.activeKey);
    }
  }, [props.activeKey]);

  const onChange = (key: number) => {
    setActiveKey(key);
    props.onChange(key);
  };

  return (
    <div className={classNames('rt-tabs', className)} style={style}>
      <div className="tabs-nav">
        {React.Children.map(children, (item, idx) => {
          const {
            key,
            props: { tab, info },
          } = item;
          const active = `${activeKey}` === key;
          return (
            <div
              key={`item-${idx}`}
              className={classNames('nav-item', key, { active })}
              onClick={() => onChange(parseInt(key, 10))}
            >
              {tab}
              {info}
            </div>
          );
        })}
      </div>
      {childLen > 1 ? children.filter((item: any) => item.key === String(activeKey)) : children}
    </div>
  );
};

Tabs.TabPane = TabPane;

Tabs.defaultProps = {
  onChange: () => {},
};

export default Tabs;
