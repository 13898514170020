import React from 'react';
import classNames from 'classnames';
import './iconfont.css';

interface IProps {
  className: string;
  name: string;
  size: number;
  color: string;
  multiColor: boolean;
  style: React.CSSProperties;
}

const Icon = (props: IProps) => {
  const { className, name, size, color, multiColor, style } = props;

  // TODO 大小有问题1
  if (multiColor)
    return (
      <svg
        className={classNames('rticon', className)}
        aria-hidden="true"
        style={{
          width: size,
          height: size,
          ...style,
        }}
      >
        <use xlinkHref={`#${name}`} />
      </svg>
    );

  return (
    <i
      className={classNames('rticon', className, `icon-${name}`)}
      style={{
        fontSize: size,
        color,
      }}
    />
  );
};

Icon.defaultProps = {
  className: '',
  style: {},
  name: '',
  size: 12,
  color: '',
  multiColor: false,
};

export default Icon;
