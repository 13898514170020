import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import './index.scss';

const scaleEnum = [0, 1, 2, 3, 4, 5, 6];
const step = 211 / 6;

const Slider = (props: any) => {
  const lineRef = useRef<any>(null);
  const { value, className } = props;
  const [init, setInit] = useState(false);
  const [pointLeft, setPointLeft] = useState(3 * step - 6);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    // 初始化
    if (value) {
      setPointLeft(((parseFloat(value) - 0.7) / 0.1) * step - 6);
    }
    setInit(true);
  }, [value]);

  // 在滑动条上滑动
  const onPointSlide = (e: any) => {
    const { clientX: startX } = e;
    setTransition(false);

    window.onmousemove = (evt) => {
      const { clientX: movingX } = evt;
      const changeX = movingX - startX;
      const nextLeft = Math.min(Math.max(-6, pointLeft + changeX), 205);
      setPointLeft(nextLeft);
    };

    window.onmouseup = (evt) => {
      window.onmousemove = null;
      window.onmouseup = null;
      setTransition(true);

      const { clientX: endX } = evt;
      const changeX = endX - startX;
      let nextLeft = pointLeft + changeX;
      if (nextLeft < -6) nextLeft = -11;
      else if (nextLeft > 225) nextLeft = 210;
      const unit = (nextLeft + 6) / step;
      // @ts-ignore
      const nthScale = Math.max(Math.ceil(unit) + (unit - parseInt(unit, 10) > 0.5 ? 0 : -1), 0); // 应该置于第几个刻度
      setPointLeft(nthScale * step - 6);
      onSlideChange(nthScale);
    };
  };

  // 点击刻度条
  const onPointSelect = (e: any) => {
    setTransition(true);
    const { clientX: clickX } = e;
    // @ts-ignore
    const { left: startX } = lineRef.current.getBoundingClientRect();
    const changeX = clickX - startX;
    const unit = changeX / step;
    // @ts-ignore
    const nthScale = Math.max(0, Math.ceil(unit) + (unit - parseInt(unit, 10) > 0.5 ? 0 : -1));
    setPointLeft(nthScale * step - 6);
    setTimeout(() => setTransition(false), 300);
    onSlideChange(nthScale);
  };

  const onSlideChange = (nthScale: any) => {
    props.onSlideChange((0.7 + nthScale * 0.1).toFixed(1));
  };

  return (
    <div className={classNames('rt-slider', className)} onClick={(e) => e.stopPropagation()}>
      <div className="rt-num-row">
        <span>70%</span>
        <span>100%</span>
        <span>130%</span>
      </div>
      <div className="rt-scale-row">
        {scaleEnum.map((item) => (
          <i key={item} className="rt-scale-item" />
        ))}
      </div>
      <div className="rt-scale-line" onClick={onPointSelect} ref={lineRef}>
        {init && (
          <div
            className={classNames('rt-point', {
              transition,
            })}
            style={{
              left: Math.max(pointLeft, -6),
            }}
            onMouseDown={onPointSlide}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="rt-point-bac" />
          </div>
        )}
      </div>
    </div>
  );
};

Slider.defaultProps = {
  onSlideChange: () => {}, // 刻度改变
};

export default Slider;
