import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import routers from 'routers';
// import { useSelector } from 'react-redux';

// import IndexPage from 'pages/index';
import Notfound from 'pages/error_page/404';
// import UnAuthorized from 'pages/error_page/403';
// import EntryChoose from 'pages/entry_choose_page';
// import SceneIndex from 'pages/scene_index';
import Home from 'pages/home';

// import { permissionIdMap } from 'utils/const';
const { Content } = Layout;

const IvhContent = () => {
  // const userPermissions = useSelector((state: { userPermissions: string[] }) => state.userPermissions);
  // const camMsg = useSelector((state: { camMsg: string }) => state.camMsg);
  return (
    <Content className="ivh-content">
      <Routes>
        <Route key="index" path="/" element={<Home />} />
        {/* 有3d空间&角色应用场景-选择enrty 废弃 */}
        {/* {userPermissions.includes(permissionIdMap.threeDimensionalSpace) &&
          userPermissions.includes(permissionIdMap.anchorService) && (
            <Route key="index" path="/" element={<EntryChoose />} />
          )} */}
        {/* 无3d空间权限-角色服务场景首页 废弃 */}
        {/* {!userPermissions.includes(permissionIdMap.threeDimensionalSpace) &&
          userPermissions.includes(permissionIdMap.anchorService) && (
            <Route key="index" path="/" element={<IndexPage />} />
          )} */}
        {/* 无角色服务场景-3d空间场景首页 废弃 */}
        {/* {userPermissions.includes(permissionIdMap.threeDimensionalSpace) &&
          !userPermissions.includes(permissionIdMap.anchorService) && (
            <Route key="index" path="/" element={<SceneIndex />} />
          )} */}
        {/* 无权访问 废弃 */}
        {/* {!userPermissions.includes(permissionIdMap.threeDimensionalSpace) &&
          !userPermissions.includes(permissionIdMap.anchorService) && (
            <Route key="index" path="/" element={<UnAuthorized />} />
          )} */}
        {routers.map((item, index) => (
          <Route key={index} path={item.page.toLowerCase()} element={<item.component />} />
        ))}
        <Route path="*" element={<Notfound />} />
      </Routes>
      {/* <CamModal camMsg={camMsg} /> */}
    </Content>
  );
};

export default React.memo(IvhContent);
