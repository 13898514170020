import React, { Fragment } from 'react';
import { ActionPreviewItem } from './index';
import { Space } from 'antd';
import { Action, ActionGroup } from '../../../types/action';
import './action_preview_list.scss';

interface IProps {
  imageSize?: number;
  actionGroups: ActionGroup[];
  style: React.CSSProperties;
  scrollClassName: string;
}

const ActionPreviewList = (props: IProps) => {
  const { actionGroups, imageSize, style, scrollClassName } = props;

  return (
    <div className="ivh-action-container" style={style}>
      {actionGroups.map((item: ActionGroup, idx: number) => {
        const { actionGroupName, actions = [] } = item;
        return (
          <Fragment key={idx}>
            <div className="ivh-subtitle">{actionGroupName}</div>
            <Space className="ivh-action-list" size={8} wrap>
              {actions.map((action: Action, idx2) => {
                const { actionCode, actionName, previewUrl, dynamicUrl } = action;
                return (
                  <ActionPreviewItem
                    key={`${actionCode}-${idx2}`}
                    imageSize={imageSize}
                    name={actionName}
                    previewUrl={previewUrl}
                    dynamicUrl={dynamicUrl}
                    scrollClassName={scrollClassName}
                  />
                );
              })}
            </Space>
          </Fragment>
        );
      })}
    </div>
  );
};

ActionPreviewList.defaultProps = {
  actionGroups: [],
  style: {},
};

export default ActionPreviewList;
