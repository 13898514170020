import i18n from 'utils/i18n';
import { RTNode, NumberEnum, TextStatus, CurSections } from '../types';

import utils from './index';
import commonUtils from 'utils';
import { splitTamilText } from './const';
const { t } = i18n;
/*
 * 所有节点
 * text  展示文字    无背景色，增减标签算字数
 * tag   标签       带背景色 不算字数
 * value 值         真正的标签值
 * */
export const inputNode: RTNode = {
  type: 'input',
};

export const textNode = (text: string, textStatus: TextStatus = {}): RTNode => {
  const {
    inExpression = false,
    inLocalSpeed = false,
    inWord = false,
    inSub = false,
    sectionIndex = undefined,
  } = textStatus;
  return {
    key: Math.random(),
    type: 'text',
    text,
    ...(inExpression && {
      inExpression,
    }),
    ...(inLocalSpeed && {
      localSpeed: inLocalSpeed,
    }),
    ...(sectionIndex !== undefined && {
      sectionIndex,
    }),
    ...(inWord && {
      inWord,
    }),
    ...(inSub && {
      inSub,
    }),
  };
};

export const pauseNode = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'pause',
  tag,
  text: '',
  value: tag,
});

export const polyphonicNode = (tag: string, text: string, value: string): RTNode => ({
  key: Math.random(),
  type: 'polyphonic',
  tag,
  text,
  value,
});

export const numberNode = (tag: string, text: string): RTNode => ({
  key: Math.random(),
  type: 'number',
  tag,
  text,
});
export const actionNode = (tag: string, value: string): RTNode => ({
  key: Math.random(),
  type: 'action',
  text: '',
  tag,
  value,
});
export const wrapNode = (): RTNode => ({
  key: Math.random(),
  type: 'wrap',
  text: '\n',
});
export const expressionStartNode = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'expressionStart',
  text: '',
  value: tag,
  tag,
});

export const expressionEndNode = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'expressionEnd',
  text: '',
  value: tag,
  tag,
});
export const localSpeedStart = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'localSpeedStart',
  text: '',
  value: tag,
  tag,
});

export const localSpeedEnd = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'localSpeedEnd',
  text: '',
  value: tag,
  tag,
});

export const wordStartNode = (): RTNode => ({
  key: Math.random(),
  type: 'wordStart',
  text: '',
});

export const wordEndNode = (): RTNode => ({
  key: Math.random(),
  type: 'wordEnd',
  text: '',
});

export const subStartNode = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'subStart',
  text: '',
  value: tag,
  tag,
});

export const subEndNode = (tag: string): RTNode => ({
  key: Math.random(),
  type: 'subEnd',
  text: '',
  value: tag,
  tag,
});

export const pauseEnum = (() => [
  { label: t('停顿0.5秒'), value: '500' },
  ...Array.from({ length: 10 }).map((_, index) => ({
    label: t('停顿${index + 1}秒', { sec: index + 1 }),
    value: `${(index + 1) * 1000}`,
  })),
])();

export const numberDefaultEnum: NumberEnum[] = [
  { label: t('数字'), value: 'number' },
  { label: t('金额'), value: 'currency' },
  { label: t('号码'), value: 'telephone' },
  // { label: t('地址'), value: 'address' },
  { label: t('编号'), value: 'id' },
  // { label: t('日期'), value: 'date' },
  // { label: t('时间'), value: 'time' },
  // { label: t('标点符号'), value: 'punctuation' },
  // { label: t('字符'), value: 'characters' },
  // { label: t('单位'), value: 'measure' },
  // { label: t('名字'), value: 'name' },
];
export const dateEnum = [{ label: t('日期'), value: 'date' }];
export const timeEnum = [{ label: t('时间'), value: 'time' }];

export const numberMap: { [key: string]: string } = {
  telephone: t('号码'),
  currency: t('金额'),
  number: t('数字'),
  digits: t('数字'),
  cardinal: t('数字'),
  // address: t('地址'),
  id: t('编号'),
  date: t('日期'),
  time: t('时间'),
  // punctuation: t('标点符号'),
  // characters: t('字符'),
  // measure: t('单位'),
  // name: t('名字'),
};

/* ------ 数据转换 ------*/
export const NODE_TYPE = {
  NODE_TEXT: 'text',
  NODE_PINYIN: 'polyphonic',
  NODE_PAUSE: 'pause',
  NODE_NUMBER: 'number',
  NODE_ACTION: 'action',
  NODE_WRAP: 'wrap',
  NODE_EXPRESSION_START: 'expressionStart',
  NODE_EXPRESSION_END: 'expressionEnd',
  LOCAL_SPEED_START: 'localSpeedStart',
  LOCAL_SPEED_END: 'localSpeedEnd',
  NODE_WORD_START: 'wordStart',
  NODE_WORD_END: 'wordEnd',
  NODE_SUB_START: 'subStart',
  NODE_SUB_END: 'subEnd',
};

export const {
  NODE_TEXT,
  NODE_PINYIN,
  NODE_PAUSE,
  NODE_NUMBER,
  NODE_ACTION,
  NODE_WRAP,
  NODE_EXPRESSION_START,
  NODE_EXPRESSION_END,
  LOCAL_SPEED_START,
  LOCAL_SPEED_END,
  NODE_WORD_START,
  NODE_WORD_END,
  NODE_SUB_START,
  NODE_SUB_END,
} = NODE_TYPE;

export const textCountNodeTypes = [NODE_TEXT, NODE_PINYIN, NODE_NUMBER, NODE_WRAP];

export const nodeToSsml = (
  node: RTNode,
  prevSSML: string,
  nodeList: RTNode[],
  idx: number,
  globalSpeed: any,
  hasSpeed: boolean,
) => {
  const { type, value, tag, text = '', localSpeed } = node;
  let ssml = '';

  const isStart = idx === 0;
  const isEnd = idx === nodeList.length - 1;
  if (hasSpeed && isStart && type !== LOCAL_SPEED_START && !localSpeed) {
    ssml += `<prosody rate="${Math.floor(+globalSpeed * 100)}%">`;
  }

  switch (type) {
    case NODE_TEXT: {
      ssml += utils.formatXml(text);
      break;
    }
    case NODE_PAUSE: {
      if (tag === t('连读')) {
        ssml += '<break strength="weak" time="0"/>';
      } else {
        ssml += `<break time="${value}ms"/>`;
      }
      break;
    }
    case NODE_PINYIN: {
      ssml += tag
        ? `<phoneme alphabet="py" ph="${value}" pinyin="${tag}">${text}</phoneme>`
        : `<phoneme alphabet="py" ph="${value}">${text}</phoneme>`;
      break;
    }
    case NODE_NUMBER: {
      // cardinal digits telephone currency
      let newTag = tag;
      if (tag === 'number') {
        newTag = text?.indexOf('.') > -1 ? 'cardinal' : 'digits';
      }
      ssml += `<say-as interpret-as="${newTag}">${text}</say-as>`;
      break;
    }
    case NODE_ACTION: {
      ssml += `<insert-action type="${value}"/>`;
      break;
    }
    // case NODE_WRAP: {
    //   ssml = '\n';
    //   break;
    // }
    case NODE_EXPRESSION_START: {
      ssml += `<insert-expression type="${value}">`;
      break;
    }
    case NODE_EXPRESSION_END: {
      ssml += '</insert-expression>';
      break;
    }
    case LOCAL_SPEED_START: {
      const prevNode = nodeList[idx - 1];
      if (prevNode && prevNode?.type !== LOCAL_SPEED_END && hasSpeed) ssml += '</prosody>';
      ssml += `<prosody rate="${value}%" local="true">`;
      break;
    }
    case LOCAL_SPEED_END: {
      ssml += '</prosody>';
      const nextNode = nodeList[idx + 1];
      if (nextNode && nextNode?.type !== LOCAL_SPEED_START && hasSpeed) {
        ssml += `<prosody rate="${Math.floor(+globalSpeed * 100)}%">`;
      }
      break;
    }
    case NODE_WORD_START: {
      ssml += `<word>`;
      break;
    }
    case NODE_WORD_END: {
      ssml += `</word>`;
      break;
    }
    case NODE_SUB_START: {
      ssml += `<sub alias="${value}">`;
      break;
    }
    case NODE_SUB_END: {
      ssml += `</sub>`;
      break;
    }
  }
  if (hasSpeed && isEnd && type !== 'localSpeedEnd') {
    ssml += '</prosody>';
  }
  return ssml;
};

// 文字转NodeList
export const textToNodeList = (text: string, textStatus: TextStatus = {}) => {
  const { inExpression = false, inLocalSpeed = false, inWord = false, inSub = false } = textStatus;
  return text
    .split('')
    .map((t) => (t === '\n' ? wrapNode() : textNode(t, { inExpression, inLocalSpeed, inWord, inSub })));
};

export const nodeListCheck = (nodeList: RTNode[]) => {
  let prevPauseIdx = -1;
  nodeList.forEach((item, idx) => {
    const { type, text = '' } = item;
    switch (type) {
      case 'pause':
        if (prevPauseIdx !== -1) nodeList.splice(prevPauseIdx, 1);
        // prevPauseIdx = idx;
        break;
      case 'text':
        if (![' ', ' '].includes(text)) prevPauseIdx = -1;
        break;
      case 'wrap':
        break;
      default:
        prevPauseIdx = -1;
    }
  });
  return nodeList;
};

export const onTagFilter = (paramNodeList: RTNode[] = [], types: string[] = []): RTNode[] => {
  // 缓存实参
  const nodeList = paramNodeList;
  if (types.length === 0) return nodeList;
  for (let i = 0, len = nodeList.length; i < len; i++) {
    const { type, text = '' } = nodeList[i];
    if (types.includes(type)) {
      if (type === 'polyphonic') {
        nodeList[i] = textNode(text);
      } else {
        nodeList.splice(i, 1);
        len -= 1;
      }
    }
  }
  return nodeList;
};

export const selectPrevent = (e: any) => {
  if (!isActiveInput()) return;
  let { target } = e;
  while (target) {
    if (target.id === 'rt-context') return;
    target = target.parentElement;
  }
  e.preventDefault();
};

// 是否在富文本内
export const isActiveInput = () => {
  if (document.activeElement?.id === 'rt-input') {
    return true;
  }
  return false;
};

export const ssmlToNodeList = (
  paramSsml: string,
  paramGlobalSpeed = '1.0',
  curSections: CurSections = [],
): [RTNode[], number, string] => {
  let globalSpeed = paramGlobalSpeed;
  const parser = new DOMParser();
  // 删除保存时在停顿后面保存的空格，若无此空格，暂停不生效
  let ssml = paramSsml.replace(
    /<break strength="(.+?)" time="?(.+?)"\/> /g,
    ($1, $2, $3) => `<break strength="${$2}" time="${$3}"/>`,
  );
  ssml = utils.removeInvalidTags(ssml);
  const document = parser.parseFromString(`<speak>${ssml}</speak>`, 'text/xml');
  const root = document.firstChild;
  if (!root) console.error(t('ssml解析失败'));
  const nodeList: RTNode[] = [];
  let size = 0;
  let tempSectionIndex = 0;
  let tempCurTextIndex = 0;
  const generateSSML = (
    node: any,
    nodeStatus: TextStatus = {
      inExpression: false,
      inLocalSpeed: false,
      inWord: false,
      inSub: false,
      sectionIndex: undefined,
    },
  ) => {
    const { nodeName, nodeValue } = node;
    const { inExpression, inLocalSpeed, inWord, inSub, sectionIndex } = nodeStatus;
    switch (nodeName) {
      case 'speak':
        node.childNodes.forEach((child: any) => generateSSML(child, { inLocalSpeed, inExpression, inSub, inWord }));
        break;
      case 'prosody': {
        const rate = parseFloat(node.getAttribute('rate'));
        const local = node.getAttribute('local');
        // @ts-ignore
        if (local) nodeList.push(localSpeedStart(rate));
        if (!local) globalSpeed = (rate / 100).toFixed(1);
        node.childNodes.forEach((child: any) =>
          generateSSML(child, { inExpression, inLocalSpeed: !!local, inWord, inSub }),
        );
        // @ts-ignore
        if (local) nodeList.push(localSpeedEnd(rate));
        break;
      }
      case 'insert-expression': {
        const tag = node.getAttribute('type');
        if (inExpression) break;
        if (tag) nodeList.push(expressionStartNode(tag));
        node.childNodes.forEach((child: any) =>
          generateSSML(child, { inExpression: true, inLocalSpeed, inWord, inSub }),
        );
        nodeList.push(expressionEndNode(tag));
        break;
      }
      case 'word': {
        if (inWord) break;
        nodeList.push(wordStartNode());
        node.childNodes.forEach((child: any) =>
          generateSSML(child, { inExpression, inLocalSpeed, inWord: true, inSub }),
        );
        nodeList.push(wordEndNode());
        break;
      }
      case 'sub': {
        const tag = node.getAttribute('alias');
        if (inSub) break;
        if (tag) nodeList.push(subStartNode(tag));
        node.childNodes.forEach((child: any) =>
          generateSSML(child, { inExpression, inLocalSpeed, inWord, inSub: true }),
        );
        nodeList.push(subEndNode(tag));
        break;
      }
      case 'break': {
        const strength = node.getAttribute('strength');
        // 兼容time=1000(old)和time=1000ms两种数据格式
        const timeAttr = Number(node.getAttribute('time').toString().replace('ms', ''));
        const time = `${commonUtils.formatFloat(timeAttr, 1)}`;
        if (strength === 'weak') {
          nodeList.push(pauseNode(t('连读')));
        } else {
          nodeList.push(pauseNode(time));
        }
        break;
      }
      case 'phoneme': {
        const pinyin = node.getAttribute('pinyin');
        const py = node.getAttribute('ph');
        const text = node.childNodes[0]?.nodeValue || '';
        if (curSections.length > 0 && text === curSections[tempSectionIndex]?.parsedText?.[tempCurTextIndex]) {
          tempCurTextIndex += 1;
          if (
            curSections[tempSectionIndex] &&
            tempCurTextIndex === curSections[tempSectionIndex].parsedText?.replace(/\n/g, '').length
          ) {
            tempSectionIndex = curSections.findIndex((sec, index) => index > tempSectionIndex && sec.sendTTS);
            tempCurTextIndex = 0;
          }
        }
        nodeList.push(polyphonicNode(pinyin, text, py));
        size += 1;
        break;
      }
      case 'insert-action': {
        const type = node.getAttribute('type');
        const tag = node.getAttribute('tag');
        nodeList.push(actionNode(tag, type));
        break;
      }
      case 'say-as': {
        const interpretAs = node.getAttribute('interpret-as');
        const text = node.childNodes[0]?.nodeValue || '';
        if (curSections.length > 0 && text === curSections[tempSectionIndex]?.parsedText?.[tempCurTextIndex]) {
          tempCurTextIndex += 1;
          if (
            curSections[tempSectionIndex] &&
            tempCurTextIndex === curSections[tempSectionIndex].parsedText?.replace(/\n/g, '').length
          ) {
            tempSectionIndex = curSections.findIndex((sec, index) => index > tempSectionIndex && sec.sendTTS);
            tempCurTextIndex = 0;
          }
        }
        nodeList.push(numberNode(interpretAs, text));
        size += text.length;
        break;
      }
      case '#text':
        if (nodeValue) {
          const nodeNewValue = utils.escape2Html(nodeValue);
          let nodeNewValueArr = [];
          if (/[\u0B80-\u0BFF]+/g.test(nodeNewValue)) {
            nodeNewValueArr = splitTamilText(nodeNewValue);
          } else {
            nodeNewValueArr = nodeNewValue.split('');
          }
          nodeNewValueArr.forEach((text: string) => {
            if (text === '\n') {
              nodeList.push(wrapNode());
            } else {
              // sectionIndex 是上次读取的位置值
              // tempSectionIndex 是当前读取的值
              // console.log(
              //   'text, curSections[tempSectionIndex].parsedText?.[tempCurTextIndex]',
              //   tempSectionIndex,
              //   tempCurTextIndex,
              //   text,
              //   curSections[tempSectionIndex]?.parsedText?.[tempCurTextIndex],
              // );
              if (curSections.length > 0 && text === curSections[tempSectionIndex]?.parsedText?.[tempCurTextIndex]) {
                nodeList.push(
                  textNode(text, { inExpression, inLocalSpeed, inWord, inSub, sectionIndex: tempSectionIndex }),
                );
                tempCurTextIndex += 1;
                if (
                  curSections[tempSectionIndex] &&
                  tempCurTextIndex === curSections[tempSectionIndex].parsedText?.replace(/\n/g, '').length
                ) {
                  tempSectionIndex = curSections.findIndex((sec, index) => index > tempSectionIndex && sec.sendTTS);
                  tempCurTextIndex = 0;
                }
              } else {
                console.log('nodeList insert text');
                nodeList.push(
                  textNode(text, { inExpression, inLocalSpeed, inWord, inSub, sectionIndex: tempSectionIndex }),
                );
              }
            }
          });
          size += nodeNewValueArr.length;
        }
        break;
      default:
        console.error(t('无此节点, 解析错误'), nodeName, nodeValue, node);
    }
  };
  generateSSML(root);
  return [nodeList, size, globalSpeed];
};

// 富文本转ssml
export const nodeListToSsml = (nodeList: RTNode[], hasSpeed = true, globalSpeed: string): string => {
  let ssml = '';

  nodeList.forEach((item, idx) => {
    ssml += nodeToSsml(item, ssml, nodeList, idx, globalSpeed, hasSpeed);
  });

  // if (hasSpeed) {
  //   ssml = `<prosody rate="${Math.floor(+globalSpeed * 100)}%">${ssml}</prosody>`;
  // }
  ssml = `<speak>${ssml}</speak>`;
  return ssml;
};

// 成对开始标签
export const PARE_START_NODE_MAP: {
  [key: string]: { endNodeType: string; tag: 'inExpression' | 'localSpeed' | 'inWord' | 'inSub' };
} = {
  expressionStart: { endNodeType: NODE_EXPRESSION_END, tag: 'inExpression' },
  localSpeedStart: { endNodeType: LOCAL_SPEED_END, tag: 'localSpeed' },
  wordStart: { endNodeType: NODE_WORD_END, tag: 'inWord' },
  subStart: { endNodeType: NODE_SUB_END, tag: 'inSub' },
};

// 成对结束标签
export const PARE_END_NODE_MAP: {
  [key: string]: { startNodeType: string; tag: 'inExpression' | 'localSpeed' | 'inWord' | 'inSub' };
} = {
  expressionEnd: { startNodeType: NODE_EXPRESSION_START, tag: 'inExpression' },
  localSpeedEnd: { startNodeType: LOCAL_SPEED_START, tag: 'localSpeed' },
  wordEnd: { startNodeType: NODE_WORD_START, tag: 'inWord' },
  subEnd: { startNodeType: NODE_SUB_START, tag: 'inSub' },
};

// 删除开始标签同步删除结束标签
export const deleteEndByStart = (startIdx: number, nodeList: RTNode[], nodeType: string) => {
  for (let i = startIdx, len = nodeList.length; i < len; i++) {
    const node = nodeList[i];
    if (node.type === PARE_START_NODE_MAP[nodeType]?.endNodeType) {
      let list = nodeList.slice(startIdx, i);
      const { tag } = PARE_START_NODE_MAP[nodeType];
      list = list.map((item) => {
        const newItem = { ...item };
        newItem[tag] = false;
        return newItem;
      });
      nodeList.splice(startIdx, i - startIdx + 1, ...list);
      break;
    }
  }
  return nodeList;
};

// 删除结束标签同步删除开始标签
export const deleteStartByEnd = (endIdx: number, nodeList: RTNode[], nodeType: string) => {
  for (let i = endIdx; i >= 0; i--) {
    const node = nodeList[i];
    if (node.type === PARE_END_NODE_MAP[nodeType]?.startNodeType) {
      let list = nodeList.slice(i + 1, endIdx);
      const { tag } = PARE_END_NODE_MAP[nodeType];
      list = list.map((item) => {
        const newItem = { ...item };
        newItem[tag] = false;
        return newItem;
      });
      nodeList.splice(i, endIdx - i, ...list);
      break;
    }
  }
  return nodeList;
};

// 删除nodeList中的单个成对标签
export const filterSinglePareNode = (list: RTNode[]) => {
  return list.filter((item, idx) => {
    let copyFlag = true;
    // 成对标签去除单独结束标签
    if (PARE_END_NODE_MAP[item.type]) {
      if (
        list.findIndex(
          (beforeNode, beforeIdx) => beforeNode.type === PARE_END_NODE_MAP[item.type].startNodeType && beforeIdx < idx,
        ) === -1
      ) {
        copyFlag = false;
      }
    }
    // 成对标签去除单独开始标签
    if (PARE_START_NODE_MAP[item.type]) {
      if (
        list.findIndex(
          (afterNode, afterIdx) => afterNode.type === PARE_START_NODE_MAP[item.type].endNodeType && afterIdx > idx,
        ) === -1
      ) {
        copyFlag = false;
      }
    }
    return copyFlag;
  });
};
