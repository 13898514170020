import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';
import { message } from 'tdesign-react';
import i18n from 'utils/i18n';
import clipboardCopy from 'copy-to-clipboard';
import { FileCopyIcon, JumpIcon } from 'tdesign-icons-react';
import LockIcon from 'assets/images/cam-lock.svg';
import { isPrivate } from 'utils/env';
import './index.scss';

const { t, language } = i18n;

type IProps = {
  camMsg?: string;
};

type CamBreakCondition = {
  effect: string;
  strategyId: number;
  condition: {
    key: string;
    ope: string;
    value: string[] | string;
  };
};

type CAMINFO = {
  identityName?: string;
  operation?: string;
  resource?: string;
  requestId?: string;
  conditions?: CamBreakCondition[];
};

const MsgReg =
  /\[request id:(.*)\].*you are not authorized to perform operation \((.*)\) resource \((.*)\) has no permission(.*)/;
const withConditionReg = /with or without condition:(.*)\]/;

const parseCamBreakContextNormal = (text: string) => {
  const parts: {
    requestId?: string;
    operation?: string;
    resource?: string;
    conditions: CamBreakCondition[];
  } = {
    requestId: undefined,
    operation: undefined,
    resource: undefined,
    conditions: [],
  };

  const match = MsgReg.exec(String(text).replace(/\n/g, ' '));
  if (match) {
    parts.requestId = match[1].trim();
    parts.operation = match[2].trim();
    parts.resource = match[3].trim();

    if (!match[4]) return parts;

    const conditionMatch = withConditionReg.exec(match[4].trim());
    if (!conditionMatch) return parts;

    try {
      const jsonString = conditionMatch[1].trim();
      const rawConditions = JSON.parse(jsonString.endsWith(']') ? jsonString : `${jsonString}]`);
      if (Array.isArray(rawConditions)) {
        for (const item of rawConditions) {
          let { condition } = item;
          const { effect, strategyId } = item;
          if (!Array.isArray(condition)) {
            condition = [condition];
          }
          for (const conditionItem of condition) {
            if (Boolean(conditionItem?.key) && Boolean(conditionItem?.ope) && Boolean(conditionItem?.value)) {
              parts.conditions.push({ condition: conditionItem, effect, strategyId });
            }
          }
        }
      }
    } catch (error) {
      /** ignore */
    }
  }

  return parts;
};

const getCAMBreakContextText = ({
  identityName = 'you are',
  operation,
  resource,
  requestId,
  conditions = [],
}: CAMINFO) => {
  const conditionsToShow = Array.isArray(conditions) ? conditions.map(({ condition }) => condition) : [];
  return `${identityName} not authorized to perform operation (${operation || ''})\nresource (${
    resource || ''
  }) has no permission${
    conditionsToShow.length > 0 ? `\nwith or without condition:${JSON.stringify(conditionsToShow)}` : ''
  }${requestId ? `\n[request id: ${requestId}]` : ''}
  `;
};

const CamModal = (props: IProps) => {
  const { camMsg } = props;
  const { uin } = useSelector((state: { userInfo: any }) => state.userInfo);
  const docUrl = !isPrivate
    ? `https://cloud.tencent.com/document/product/598/38350`
    : `https://intl.cloud.tencent.com/document/product/598/32677`;
  const copyText = () => {
    if (camMsg) {
      clipboardCopy(getCAMBreakContextText(camInfo));
      message.success(t('文本配置复制成功'));
    }
  };
  const [visible, setVisible] = useState(false);
  const [camInfo, setCamInfo] = useState<CAMINFO>({});
  useEffect(() => {
    setVisible(!!camMsg);
    if (camMsg) {
      setCamInfo({ identityName: uin ? `User (uin: ${uin}) is` : 'you are', ...parseCamBreakContextNormal(camMsg) });
    }
  }, [camMsg]);
  return (
    <Modal
      visible={visible}
      footer={null}
      width={800}
      wrapClassName="cam-modal-wrap"
      onCancel={() => setVisible(false)}
    >
      <h1 className="cam-header">
        <div className="cam-header-icon">
          <img className="light-blue-icon" src={LockIcon} />
        </div>
        {t('您当前没有操作权限')}
      </h1>
      <div className="cam-description">
        <p className="cam-description-title">{t('如何获得权限？')}</p>
        <p className="cam-description-content">
          {t('请将如下信息发送给主账号或具备权限授予能力的管理子用户，在访问管理 CAM 中为您添加相应权限：')}
        </p>
      </div>
      <div className="cam-content">
        <div>
          {camInfo.identityName} not authorized to perform operation
          <span className="cam-green"> ({camInfo.operation}) </span> resource
          <span className="cam-blue"> ({camInfo.resource}) </span> has no permission
          {!!camInfo?.conditions?.length && (
            <>
              <span> with or without condition: </span>
              {camInfo.conditions.map(({ condition: { key, ope, value } }, index) => (
                <Fragment key={`${key}-${ope}-${index}`}>
                  <span>key:</span>
                  <span className="cam-blue"> ({key}) </span>
                  <span>ope:</span>
                  <span className="cam-blue"> ({ope}) </span>
                  <span>value:</span>
                  <span className="cam-green"> ({Array.isArray(value) ? value.join(',') : value}) </span>
                </Fragment>
              ))}
            </>
          )}
          {!!camInfo.requestId && ` [request id: ${camInfo.requestId}]`}
        </div>
        <div>
          <FileCopyIcon onClick={copyText} />
        </div>
      </div>
      <div className="cam-footer">
        <a href={docUrl} target="_blank">
          {t('如何根据无权限信息创建权限策略?')}
          <JumpIcon />
        </a>
      </div>
      <div className="cam-modal-btns">
        <Button type="primary" onClick={() => setVisible(false)}>
          {t('我知道了')}
        </Button>
      </div>
    </Modal>
  );
};

export default React.memo(CamModal);
