import React from 'react';
import './tabs.scss';

interface IProps {
  tab: string;
  info: string;
  children: React.ReactNode;
}

const TabPane: React.FC<IProps> = (props) => <div className="rt-tab-pane">{props.children}</div>;

TabPane.defaultProps = {};

export default TabPane;
