import JSZip from 'jszip';

if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function (predicate) {
    'use strict';
    if (this === null) {
      throw new TypeError('Array.prototype.findLastIndex called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    const list = Object(this);
    const length = list.length >>> 0;
    // eslint-disable-next-line prefer-rest-params
    const thisArg = arguments[1];
    let value;

    for (let i = length - 1; i >= 0; i--) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return i;
      }
    }
    return -1;
  };
}

const utils = {
  // ******
  docxToString(file) {
    return new Promise((resolve) => {
      JSZip.loadAsync(file)
        .then(
          async (zip) => {
            let string = '';
            const zipEntry = zip.files['word/document.xml'];
            await zip
              .file(zipEntry.name)
              .async('string')
              .then((data) => {
                data.replace(/<w:p(.+?)>(.+?)<\/w:p>/g, ($1, $2, $3) => {
                  let new$3 = $3.replace(/<\/?.+?>/g, '');
                  new$3 = new$3.replace(/ /g, '');
                  string += `${new$3}\n`;
                });
              })
              .catch((err) => {
                console.error(err);
              });
            resolve(string);
          },
          () => {
            resolve('');
          },
        )
        .catch((err) => {
          console.error(err);
        });
    });
  },
  txtToString(file, code = 'utf-8') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, code);
      reader.onload = (res) => resolve(res.target.result);
      reader.onerror = (err) => reject(err);
    });
  },
  setStateSync: (that, data) =>
    new Promise((resolve) => {
      that.setState(data, resolve);
    }),
  // xml过滤为纯文本
  transformXml2Text(ssml) {
    return ssml.replace(/<\/?.+?>/gi, '');
  },
  // 特殊字符转义 &必须在第一个
  formatXml(text) {
    return text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&apos;')
      .replace(/"/g, '&quot;');
  },
  // 转义字符还原
  escape2Html(xml) {
    return xml
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&apos;/g, "'")
      .replace(/&quot;/g, '"');
  },
  // 根据语速标签判断是否为ssml语句
  isSsml(str) {
    // const speedRegExp = /<prosody rate="(.+?)">(.+?)<\/prosody>|<speak>(.+?)<\/speak>/;
    const speedRegExp = /<prosody rate="(.+?)">([\s\S]*)<\/prosody>|<speak>([\s\S]*)<\/speak>/;
    return speedRegExp.test(str);
  },
  checkDate(val) {
    const reg1 = /^(\d{2,4}年)?((0?[1-9]|1[0-2])月)?((0?[1-9]|[12][0-9]|3[01])(日|号))?$/;
    const reg2 = /^(\d{2,4}(-|\/|\.))?(0?[1-9]|1[0-2])(-|\/|\.)?((0?[1-9]|[12][0-9]|3[01]))?$/;
    // 日期范围
    const reg3 =
      /^\d{4}(年|\/|\.)\d{1,2}(月|\/|\.)?\d{1,2}日?~\d{4}(年|\/|\.)\d{1,2}(月|\/|\.)(0?[1-9]|[12][0-9]|3[01])日?$/;
    const reg4 = /^(\d{4}(年|\/|\.))?\d{1,2}(月|\/|\.)?\d{1,2}日?~(\d{1,2}(月|\/|\.))?(0?[1-9]|[12][0-9]|3[01])日?$/;
    return val.trim() && !/^\d+$/g.test(val) && (reg1.test(val) || reg2.test(val) || reg3.test(val) || reg4.test(val));
  },
  checkTime(val) {
    const reg1 = /^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/;
    const reg2 = /^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?(AM|PM|am|pm)?$/;
    const reg3 =
      /^(1[0-2]|0?[1-9]):[0-5][0-9](:[0-5][0-9])?(AM|PM|am|pm)?(~|-)?(1[0-2]|0?[1-9]):[0-5][0-9](:[0-5][0-9])?(AM|PM|am|pm)?$/;
    return val.trim() && (reg1.test(val) || reg2.test(val) || reg3.test(val));
  },
  removeInvalidTags(xmlStr) {
    let tempStr = xmlStr;
    const tagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    const tagMatches = tempStr.match(tagPattern);
    const tagStack = [];

    if (tagMatches) {
      for (const tag of tagMatches) {
        const tagName = tag.replace(tagPattern, '$1'); // 提取标签名
        if (tag.includes('</')) {
          // 如果是结束标签，检查是否匹配栈顶的开始标签
          if (tagStack.length > 0 && tagName === tagStack[tagStack.length - 1]) {
            tagStack.pop(); // 匹配成功，弹出栈顶的开始标签
          } else {
            tempStr = tempStr.replace(tag, ''); // 匹配失败，移除结束标签
          }
        } else if (!tag.endsWith('/>')) {
          // 如果是非自闭合的开始标签，压入栈中
          tagStack.push(tagName);
        }
      }

      // 移除所有未匹配的开始标签
      for (const tagName of tagStack) {
        tempStr = tempStr.replace(new RegExp(`<${tagName}\\b[^>]*>`, 'gi'), '');
      }
    }

    return tempStr;
  },
};

export default utils;
