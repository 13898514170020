import React, { useState, useRef } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import './index.scss';
type IProps = TooltipProps & {
  zoom?: number;
};

// chrome128.0.6613.85版本以后getBoundingClientRect获取的宽高=原宽高*zoom，旧版本获取的宽高不受zoom影响，故对外层有zoom的tooltip做此修复
const ZoomTooltip = (props: IProps) => {
  const { ...rest } = props;
  const [zoom, setZoom] = useState<number>(1);
  const [visibility, setVisibility] = useState<any>(undefined);
  const tooltipRef = useRef<any>(null);
  const onVisibleChange = (visible: boolean) => {
    const tooltipDom = tooltipRef?.current?.popupRef?.current?.getElement();
    if (tooltipDom) {
      const { width, height } = tooltipDom.getBoundingClientRect();
      const { offsetWidth, offsetHeight } = tooltipDom;
      if (+width.toFixed(0) !== offsetWidth || +height.toFixed(0) !== offsetHeight) {
        setVisibility('hidden');
        setZoom(offsetWidth / width);
        // 防止tooltip抖动 待优化
        setTimeout(() => {
          setVisibility('visible');
        }, 300);
      }
    }
    props?.onVisibleChange?.(visible);
  };
  return (
    <Tooltip
      {...rest}
      ref={tooltipRef}
      overlayStyle={{ zoom, transform: `scale(${1 / zoom})`, visibility }}
      onVisibleChange={onVisibleChange}
      overlayClassName="zoom-tooltip"
    >
      {props.children}
    </Tooltip>
  );
};

export default React.memo(ZoomTooltip);
