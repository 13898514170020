import React, { forwardRef, ForwardedRef } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import { Icon } from '../../../../common';
import { RTNode } from '../../types';

interface IProps {
  idx: number;
  data: RTNode;
  type: string;
  enableDetail: boolean;
  isInAction?: boolean;
  onClick?: (e: any) => void;
  onMouseUp: (e: any) => void;
  onNodeClick?: (e: any) => void;
  onMouseDown: (e: any) => void;
}

const localSpeedNode = forwardRef((props: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    idx,
    data: { tag },
    type,
    onClick,
    onMouseUp,
    enableDetail,
    isInAction,
    onNodeClick,
  } = props;
  // @ts-ignore
  const val = Math.round(parseFloat(tag, 10) * 100);
  return type === 'start' ? (
    <div
      className={classNames('rt-node', 'rt-ls-start', { isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      [
    </div>
  ) : (
    <div
      className={classNames('rt-node', 'rt-ls-end', { isInAction })}
      data-idx={idx}
      ref={ref}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      ]
      {enableDetail ? (
        <span className="rt-tag" onClick={onNodeClick}>
          {val}%
        </span>
      ) : (
        <Tooltip placement="bottom" trigger={['hover']} overlay={`${val}%`}>
          <span className="rt-tag rt-tag-simplify sample">
            <Icon className="tag-icon" name="thumbtack" size={12} color="#fff" />
          </span>
        </Tooltip>
      )}
    </div>
  );
});

export default localSpeedNode;
