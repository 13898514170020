import i18n from 'utils/i18n';
import React, { useEffect, useState } from 'react';
import { Dialog } from 'tdesign-react';
import { Input } from 'antd';

import './index.scss';
const { t } = i18n;
type IProps = {
  subInputModalVisible: boolean;
  subInputVal: string;
  onClose: Function;
  onConfirm: Function;
};

const SubInputModal = (props: IProps) => {
  const { subInputModalVisible, subInputVal, onClose, onConfirm } = props;
  const [inputVal, setInputVal] = useState('');

  useEffect(() => {
    setInputVal(subInputVal);
  }, [subInputModalVisible]);

  const closeSubModal = () => {
    onClose();
  };

  const confirmSubInput = () => {
    onConfirm(inputVal);
  };

  const handlechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setInputVal(inputValue.trim());
  };

  return (
    <Dialog
      visible={subInputModalVisible}
      header={t('替换文本')}
      closeOnOverlayClick={false}
      closeOnEscKeydown={false}
      placement="center"
      onClose={closeSubModal}
      onConfirm={confirmSubInput}
      cancelBtn={false}
      confirmBtn={{ content: t('提交'), disabled: !inputVal.length }}
      className="rt-sub-dialog"
    >
      <div className="sub-input-item">
        <div className="sub-input-label">{t('替换文本')}</div>
        <Input value={inputVal} maxLength={15} showCount onChange={handlechange} className="sub-dialog-input" />
      </div>
      <div className="sub-prompt">{t('例：原文本11.11可替换为双十一')}</div>
    </Dialog>
  );
};

export default React.memo(SubInputModal);
