import React from 'react';
import classNames from 'classnames';

import './index.scss';

type IProps = {
  className?: string;
  style?: Object;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
};

const SettingCard = (props: IProps) => {
  const { className, style, title, children } = props;

  return (
    <div className={classNames(['setting-card', className])} style={style}>
      {!!title && <div className="setting-card-title">{title}</div>}
      <div className="setting-card-list">{children}</div>
    </div>
  );
};

export default React.memo(SettingCard);
