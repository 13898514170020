import i18n from 'utils/i18n';
import React, { useState } from 'react';

import debounce from 'lodash/debounce';
import { Input, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';

import './index.scss';
import { SearchIcon, CloseCircleFilledIcon } from 'tdesign-icons-react';
const { t } = i18n;
type FilterProps = {
  onSearch: Function;
};

const { Group } = Radio;

const RoleListFilter = (props: FilterProps) => {
  const { onSearch } = props;
  const [searchedWord, setSearchedWord] = useState('');
  const [sortType, setSortType] = useState('desc');

  const sortOptions = [
    { label: t('最早'), value: 'asc' },
    { label: t('最新'), value: 'desc' },
  ];

  const debounceSearch = debounce((keyword: string, sortType) => {
    onSearch(keyword, sortType);
  }, 100);

  // 搜索关键词
  const onPressEnter = async (e: any) => {
    const val = e.target.value;
    const keyword = val?.trim() || '';
    setSearchedWord(keyword);
    debounceSearch(keyword, sortType);
  };

  // 修改排序方式
  const changeSortType = ({ target: { value } }: RadioChangeEvent) => {
    setSortType(value);
    debounceSearch(searchedWord, value);
  };

  // 清空搜索
  const clearSearch = () => {
    console.log('clear');
    setSearchedWord('');
    debounceSearch('', sortType);
  };

  return (
    <div className="search-box">
      <Input
        suffix={<SearchIcon />}
        allowClear={{ clearIcon: <CloseCircleFilledIcon onClick={clearSearch} /> }}
        placeholder={t('请输入数智人名称')}
        onPressEnter={onPressEnter}
        style={{ width: 200 }}
      />
      <Group options={sortOptions} value={sortType} optionType="button" onChange={changeSortType} />
    </div>
  );
};

export default React.memo(RoleListFilter);
