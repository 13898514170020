/**
 * 账号和权限业务方法
 */
import Cookies from 'js-cookie';
import utils from 'utils';

import { isPrivate } from 'utils/env';
/**
 * 用户登录信息
 */
export interface AuthInfo {
  // 腾讯云登录标记
  uin: string;
  // 腾讯云登录标记
  skey: string;
  // 腾讯云用户名
  userNick: string;
  username?: string;
}

/**
 * 检查登录的跳转参数
 */
export interface LoginParams {
  // 产品名称
  project: string;
  // 重定向地址，传入项目名称则不需要
  redictUrl?: string;
}

/**
 * cookie名常量
 */
const COOKIE_NAME = {
  // toDo 国际站配置修改，临时制定和公有云相同
  APP_KEY: 'appkey',
  AUTHORIZATION: 'authorization',
  // NICK: isPrivate ? 'ti-nickname' : 'nick',
  // UIN: isPrivate ? 'ti-user' : 'uin',
  // SKEY: isPrivate ? 'ti-token' : 'skey',
  NICK: 'nick',
  UIN: 'uin',
  SKEY: 'skey',
  username: 'lusername',
};

/**
 * 获取账号和权限相关的信息
 */
export const getAuthCookie = (): AuthInfo => ({
  uin: Cookies.get(COOKIE_NAME.UIN) || '',
  skey: Cookies.get(COOKIE_NAME.SKEY) || '',
  userNick: Cookies.get(COOKIE_NAME.NICK) || '',
  username: Cookies.get(COOKIE_NAME.username) || '',
});

/**
 * 产品地址
 */
interface ProuductMap {
  [key: string]: string;
}

const PRODUCT_MAP: ProuductMap = {
  virtualman: process.env.REACT_APP_BASE_URL || '', // 交互数智人
};

/**
 * 腾讯云官方登录地址
 */
const CLOUD_LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

/**
 * 腾讯云官方退出登录地址
 */
const CLOUD_LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;

/**
 * 获取腾讯云登录地址
 * @param params
 * @returns
 */
export const getCloudLoginUrl = (params: LoginParams) => {
  console.log('getIframeQueryVariable123', utils.getIframeQueryVariable('iframe'));
  if (utils.getIframeQueryVariable('iframe') === 'true') {
    return `${window.location.origin}/ivh#/empty_page`;
  }
  const { redictUrl, project } = params || {};
  const url = redictUrl || PRODUCT_MAP[project];
  // 腾讯云原生接口需要手动加重定向地址
  return CLOUD_LOGIN_URL + encodeURIComponent(url);
};

/**
 * 获取腾讯云退出登录地址
 * @param params 重定向地址
 */
export const getCloudLogoutUrl = (params: LoginParams) => {
  const { redictUrl, project } = params || {};
  const url = redictUrl || PRODUCT_MAP[project];
  return CLOUD_LOGOUT_URL + encodeURIComponent(url);
};

/**
 * 判断当前登录态
 * @param params
 * @returns 是否存在登录态
 */
export const checkAuthCookie = (params?: LoginParams): AuthInfo | null => {
  const authInfo = getAuthCookie();
  if (utils.getIframeQueryVariable('iframe') !== 'true') {
    const { uin, skey } = authInfo;
    if (!uin || !skey) {
      // 需要重定向
      if (params) {
        window.location.href = getCloudLoginUrl(params);
      }
      return null;
    }
  } else {
    authInfo.uin = '123';
    authInfo.skey = '';
    authInfo.userNick = '';
    authInfo.username = '';
  }
  return authInfo;
};

export const authInfo = checkAuthCookie();
