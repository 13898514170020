import i18n from 'utils/i18n';
import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { Button, message, Tooltip } from 'antd';
import 'react-quill/dist/quill.snow.css';
import './rich-text.scss';
import reloadIcon from 'assets/images/anticon-reload.png';
import { xssHandlerProcess } from 'utils/const';
const { t } = i18n;
const colorEnum: string[] = [
  '#e60000',
  '#ff9900',
  '#f17A69',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
  '#000000',
];

const limit = 600;

interface IState {
  initValue?: string;
  textLen: number;
}

interface IProps {
  value: string;
  height?: number;
  placeholder?: string;
  maxLength?: number;
  initValue?: string;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  onChange?: (e: any, len?: number) => void;
  onChangeSelection?: (e: any) => void;
}

class QuillRichText extends Component<IProps, IState> {
  quillRef: any;
  reactQuillRef: any;
  unprivilegedEditor: any;

  constructor(props: IProps) {
    super(props);
    this.state = { textLen: 0 };
    this.quillRef = null;
    this.reactQuillRef = null;
    this.unprivilegedEditor = null;
  }

  componentDidMount() {
    this.attachQuillRefs();
  }

  componentDidUpdate(prevProps: IProps) {
    this.attachQuillRefs();
    if (this.props.value !== prevProps.value) {
      this.setState({ textLen: this.quillRef?.getLength() - 1 || 0 });
    }
  }

  public static defaultProps = {
    onChange: () => {},
    onChangeSelection: () => {},
    onFocus: () => {},
    onBlur: () => {},
  };

  public render() {
    const { value, height, placeholder } = this.props;
    const { textLen } = this.state;
    return (
      <div className="quill-rich-text" style={{ height }}>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          theme="snow"
          placeholder={placeholder}
          value={xssHandlerProcess.process(value)}
          onChange={this.handleChange.bind(this)}
          onChangeSelection={this.onChangeSelection.bind(this)}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          modules={{
            toolbar: [
              ['bold', 'italic', { color: colorEnum }, { background: colorEnum }, 'underline'],
              [
                // [{ size: ['small', false, 'large', 'huge'] }],
                // [{ header: [1, 2, 3, 4, 5, 6, false] }],
                'link',
              ],
              [{ align: [] }],
            ],
          }}
        />
        <Tooltip title={t('重置文案')}>
          <Button className="reload" icon={<img src={reloadIcon}></img>} onClick={this.reload.bind(this)} />
        </Tooltip>
        <div className="length-tip">
          <span className={textLen > limit ? 'text-red' : ''}>{textLen}</span>
          <span>/</span>
          <span>{limit}</span>
        </div>
      </div>
    );
  }

  private attachQuillRefs = () => {
    if (typeof this.reactQuillRef?.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef?.getEditor();
    this.unprivilegedEditor = this.reactQuillRef.makeUnprivilegedEditor(this.quillRef);
  };

  private handleChange(value: string) {
    const len = this.quillRef?.getLength() - 1;
    if (len > limit) {
      message.warning(t('回复语样式字符超限'));
    }
    this.props.onChange?.(value, len);
  }

  private onChangeSelection(e: any) {
    this.props.onChangeSelection?.(e);
  }

  private onFocus(e: any) {
    this.props.onFocus?.(e);
  }

  private onBlur(e: any) {
    this.props.onBlur?.(e);
  }

  private reload() {
    const { initValue } = this.props;
    this.props.onChange?.(initValue);
  }
}

export default QuillRichText;
