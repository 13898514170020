import i18n from 'utils/i18n';
import React from 'react';
import classNames from 'classnames';

import { message } from 'antd';

import './index.scss';
const { t } = i18n;
type IProps = {
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  sibling?: React.ReactNode;
  children?: React.ReactNode;
  toastdisabled?: boolean;
  cancelabled?: boolean;
  onChange?: (e: React.MouseEvent | string) => void;
  // onClick?: React.MouseEventHandler;
};

const SelectItem = (props: IProps) => {
  const {
    className,
    selected,
    disabled = false,
    sibling,
    children,
    onChange,
    toastdisabled,
    cancelabled,
    ...others
  } = props;

  const handleClick = (e: React.MouseEvent) => {
    if (!disabled) {
      (!selected || cancelabled) && onChange?.(e);
    } else {
      if (!toastdisabled) message.info(t('请先点击编辑按钮进入编辑状态，再更改数智人形象设置'));
    }
  };

  return (
    <div className={classNames('select-item-wp', { selected }, className)} onClick={(e) => handleClick(e)} {...others}>
      <div className={classNames('select-item')}>{children}</div>
      {sibling}
    </div>
  );
};

export default React.memo(SelectItem);
