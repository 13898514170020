// 动作相关

// 动作类型
export enum ActionType {
  BOTQUESTION = 'botquestion',
  QUESTION = 'question',
  REPLY = 'reply',
  SCENE = 'scene',
  DOMAIN = 'domain',
  COMMAND = 'command',
}

// 动作单项
export interface Action {
  actionId: string;
  actionCode: string;
  actionName: string;
  previewUrl: string;
  dynamicUrl: string;
  duration: number;
  durBefore: number;
  durAfter: number;
}

export interface ActionGroup {
  actionGroupName: string;
  actions: Action[];
}

// 动作组单项
export interface ActionGroupSpread {
  type: 'code' | 'ssml';
  ssml: string;
  actions: Action[];
}

// 回复语单项
export interface ReplyItem {
  groupId: string;
  reply: string;
}

// 问题单项
export interface QuestionItem {
  groupId: string;
  question: string;
}

// 意图单项
export interface IntentItem {
  intentionCode: string;
  intentionName: string;
}

// 领域单项
export interface DomainItem {
  domainCode: string;
  domainName: string;
  intentions: IntentItem[];
}

// 动作列表
export interface ActionListItem {
  actionConfigId: number;
  question: string;
  reply: string;
  scene: string;
  domainName: string;
  intentionName: string;
  slot: string;
  emotion: string;
  actionGroupsSpread: string; // 动作组配置
  actionConfiguration: string; // 动作配置情况 如”2个动作组“ 或 ”动作标签“
  voiceConfigured: boolean; // 语音配置
  interactConfigured: boolean; // UI交互配置
  updateTime: string;
  putWay?: number; // 入库方式 1:手工添加 2:自动添加 3:话术同步
  totalCallCount?: number;
  renderUrl?: string;
  renderTotalCallCount?: number;
  replyType?: string; // 回复语类型
  cleanUp?: number;
  customScriptType?: string;
}

// 动作详情
export interface ActionDetail extends UIParams {
  actionConfigId?: number;
  type: string;
  emotion: string;
  groupId?: string;
  source?: string;
  // questionId: string;
  question?: string;
  reply?: string;
  scene?: string;
  domainCode?: string;
  domainName?: string;
  intentionCode?: string;
  intentionName?: string;
  slot?: string;
  actionConfigMode: ActionConfigMode;
  ssml?: string;
  actionGroupsSpread?: ActionGroupSpread[];
  cateBizId?: string;
  docBizId?: string;
  cloudAnswer?: string;
  cloudQuestion?: string;
  isChange?: number;
  // virtualmanResourceId?: number;
}

// 保存时的动作组单项 - 接口字段和动作组单项有出入
export type ActionGroupSpreadSave = Omit<ActionGroupSpread, 'actions'> & { actionCodes: string[] };

// 特殊消息类型
export type InteractType = 'OptionInfo' | 'Image' | 'Popup' | 'Video' | 'Voiceprint' | 'ImageOption' | 'Customize' | '';

// UI交互配置
export type UIParams = {
  interrupt: boolean;
  mute: boolean;
  specialReply?: boolean;
  interactType?: InteractType;
  interactContent?: string;
  showContent?: string;
  showTextLen?: number;
};

// 动作配置方式
export type ActionConfigMode = 'ssml' | 'code';

export type TtsWordDescribe = {
  word: string;
  timeStart: number;
  timeEnd: number;
  posStart: number;
  posEnd: number;
};

export interface ExpressionItem {
  virtualmanResourceId: number;
  previewStaticUrl: string;
  previewDynamicUrl: string;
  expressionName: string;
  expressionCode: string;
}

export interface ExpressionGroup {
  expressionGroupName: string;
  expression: ExpressionItem[];
}

export interface QaCateItem {
  canAdd: boolean;
  canDelete: boolean;
  canEdit: boolean;
  cateBizId: string;
  children: QaCateItem[];
  name: string;
  total: number;
  label?: string;
}
export interface DocItem {
  charSize: string;
  fileType: string;
  text: string;
  value: string;
}
export interface PageNotifyItem {
  allowClose: boolean;
  content: string;
  id: string;
  level: string;
  subject: string;
}
